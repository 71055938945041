import React, { Fragment } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& + &': {
      marginTop: rem(16),
    },
  },
  title: {
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  value: {
    color: theme.colors.charcoalGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    display: 'inline-block',
    verticalAlign: 'top',
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top',
      transform: `translateY(${rem(2)})`,
    },
    '& img': {
      width: rem(20),
      height: rem(20),
      marginLeft: rem(8),
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
}

const Item = ({
  classes,
  title,
  values,
  className,
}) => {
  const hasValues = values && values.length > 0
  return (
    <div className={classes.root}>
      <div className={classnames(classes.title, className)}>{title}</div>
      <div className={classes.value}>
        {hasValues && values.map(({ value, className, image }, index) => 
          <Fragment key={index}>
            {value
              ? <Fragment>
                  <span className={classnames(className)} key={index}>{value}</span>
                  {image && <img src={image} alt='' />}
                </Fragment>
              : null
            }
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default injectSheet(styles)(Item)