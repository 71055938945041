import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import injectSheet from 'react-jss'
import { Button, Input } from '@verdaccio/mt-uikit'
import theme, { rem } from './../../theme/base'
import { Overlay } from './../index'

const styles = {
  root: {
    background: theme.colors.white,
    width: '91.5vw',
    maxWidth: rem(378),
    borderRadius: rem(24),
    padding: [rem(32), rem(16), rem(16)],
    position: 'fixed',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    '& img': {
      width: rem(48),
      height: rem(48),
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .copy': {
      height: rem(56),
      marginTop: rem(24),
      borderRadius: rem(28),
      boxShadow: `0 ${rem(8)} ${rem(16)} 0 rgba(186, 207, 255, 0.23), 0 ${rem(4)} ${rem(8)} 0 rgba(86, 93, 107, 0.1)`,
      border: [rem(1), 'solid', theme.colors.blueyGrey],
      textAlign: 'center',
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    paddingBottom: rem(80),
    position: 'relative',
  },
  title: {
    marginTop: rem(16),
    fontSize: rem(22),
    lineHeight: rem(28),
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.charcoalGrey,
  },
  details: {
    marginTop: rem(16),
    marginLeft: rem(8),
    marginRight: rem(8),
    fontSize: rem(15),
    lineHeight: rem(20),
    letterSpacing: rem(0.09),
    textAlign: 'center',
    color: theme.colors.slateGreyTwo,
  },
  line: {
    '& + &': {
      marginTop: rem(24),
      paddingTop: rem(24),
      borderTop: [rem(1), 'solid', theme.colors.paleGrey],
    },
  },
  button: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  close: {
    background: `url(${require('./close.svg')}) no-repeat`,
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(16),
    right: rem(16),
    cursor: 'pointer',
  },
}

const Alert = ({
  classes,
  icon,
  title,
  details,
  label,
  copy,
  onClose,
  zIndex,
}) => {
  const [value, setValue] = useState(copy)
  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    setCopied(true)
    // alert('Copied to clipboard!')
  }
  
  return (
    <Overlay>
      <div className={classes.root} style={{ zIndex }}>
        <div className={classes.wrapper}>
          <img src={icon} alt='' />
          <div className={classes.title}>{title}</div>
          <div className={classes.details}>
            {details.map(line => <div key={line} className={classes.line}>{line}</div>)}
          </div>
          {copy &&
            <Input
              className='copy'
              value={copy}
              readOnly
            />
          }
          <CopyToClipboard text={value} onCopy={onCopy}>
            <Button 
              className={classes.button} 
              type='primary'
              size='large'
              block 
              // onClick={onClose}
            >
              {label}
            </Button>
          </CopyToClipboard>
        </div>
        <div className={classes.close} onClick={onClose} />
      </div>
    </Overlay>
  )
}

export default injectSheet(styles)(Alert)