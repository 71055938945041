import React, { Fragment, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import regexps from './../../utils/regexps'
import { compact } from 'lodash/array'
import { useActions } from './../../actions'
import DesktopWrapperHOC2 from './../DesktopWrapperHOC2'
import theme from './../../theme/base'
import { Wrapper, Logo, Greeting, Error, Form, Field, Submit, Action, ButtonGoogleLogin } from './Layout'
import { useTrackValues } from './../../lrtracker'

const Signup = () => {
  const location = useLocation()

  const {
    onLogin,
    onCreateUserByEmailPassword,
    onNewTask,
  } = useActions()

  useEffect(() => {
    document.body.style.background = theme.colors.blue

    return () => {
      document.body.style.background = ''
    }
  }, [])

  const [email, setEmail] = useState(location.state && location.state.email || '')
  const [password, setPassword] = useState('')
  const [submit, setSubmit] = useState(false)
  const [submitError, setSubmitError] = useState()

  const cleanedEmail = email.trim().toLowerCase()
  const cleanedPassword = password.trim()

  const validEmail = regexps.email(cleanedEmail)
  const validPassword = cleanedPassword.length > 0
  const valid = validEmail && validPassword

  const errorEmail = submit && !validEmail && 'Oops, check your email format.'
  const errorPassword = submit && !validPassword && 'Incorrect password'

  const disabled = submit && !valid

  const errors = []
  errors.push(submitError)
  errors.push(errorEmail)
  errors.push(errorPassword)
  const error = compact(errors).length > 0 ? compact(errors)[0] : false

  const toid = location.state && location.state.from && location.state.from.state && location.state.from.state.order && location.state.from.state.order.toid || null
  const trackValuesContenxt = { toid, stage: 'signup' }

  const nextLogin = auth => {
    onLogin(auth)
    const miniOrderFormData = location.state && location.state.from && location.state.from.state && location.state.from.state.order
    if (miniOrderFormData) {
      onNewTask({ order: miniOrderFormData })
    }
  }

  const onSubmit = () => {
    setSubmit(true)
    if (valid) {
      onCreateUserByEmailPassword(cleanedEmail, cleanedPassword, { context: trackValuesContenxt })
        .then(nextLogin)
        .catch(err => setSubmitError(err.details))
    }
  }

  useTrackValues(email, 'email', { context: { ...trackValuesContenxt, validEmail }, predicat: value => value.length > 0 })
  useTrackValues(validPassword, 'password', { context: { ...trackValuesContenxt, validPassword }, predicat: _ => password.length > 0 })

  return (
    <Fragment>
      <Wrapper>
        <Logo />
        <ButtonGoogleLogin
          onSuccess={nextLogin}
          onError={setSubmitError}
          context={trackValuesContenxt}
        />
        <Greeting>Or use your email to get started</Greeting>
        {error &&
          <Error>{error}</Error>
        }
        <Form>
          <Field
            type='email'
            placeholder='Enter your email'
            value={cleanedEmail}
            onChange={value => setEmail(value)}
            error={errorEmail}
          />
          <Field
            type='password'
            placeholder='Create your password'
            value={cleanedPassword}
            onChange={value => setPassword(value)}
            error={errorPassword}
          />
          <Submit onClick={onSubmit} disabled={disabled}>Sign up</Submit>
        </Form>
      </Wrapper>
      <Action
        text='Already have an account?'
        link='Log in'
        to='/login'
        state={{ email }}
      />
    </Fragment>
  )
}

export default DesktopWrapperHOC2(Signup)