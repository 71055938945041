import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import { Button, Icon } from '@verdaccio/mt-uikit'
import { Link } from 'react-router-dom'
import theme, { rem, content, desktop } from '../../../theme/base'
import DesktopWrapperHOC2 from './../../../components/DesktopWrapperHOC2'

const iconsPath = require.context('./')

const styles = {
  root: {
    background: theme.colors.white,
    paddingBottom: rem(24),
    position: 'relative',
    [desktop]: {
      borderRadius: rem(16),
    },
  },
  wrapper: {
    ...content,
    maxWidth: rem(375),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: rem(24),
    paddingLeft: rem(24),
    paddingRight: rem(24),
  },
  content: {
    ...content,
    marginTop: rem(24),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    '& button': {
      '&:hover': {
        color: theme.colors.white,
      },
    },
  },
  back: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(16),
    left: rem(16),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  icon: {
    backgroundImage: `url(${iconsPath(`./payment-failed-icon.svg`)})`,
    backgroundRepeat: 'no-repeat',
    //backgroundSize: 'cover',
    backgroundPosition: ['center', rem(72)],
    width: '100%',
    height: rem(250),
    paddingTop: rem(72),
  },
  title: {
    fontSize: rem(22),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
    textAlign: 'center',
  },
  text: {
    marginTop: rem(16),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGreyTwo,
    letterSpacing: rem(0.09),
    textAlign: 'center',
  },
  change: {
    marginTop: rem(24),
    textAlign: 'center',
    '& span': {
      color: theme.colors.blue,
      fontSize: rem(20),
      lineHeight: rem(25),
      letterSpacing: rem(0.38),
      fontWeight: 600,
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
}

const PaymentFailed = ({
  classes,
  retryPaymentUrl,
  backToCheckoutUrl,
  state,
}) => {

  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.white
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  return (
    <div className={classes.root}>
      <Link className={classes.back} to='/orders'>
        <Icon iconName='back' />
      </Link>
      <div className={classes.icon} />
      <div className={classes.wrapper}>
        <div className={classes.title}>Payment Failed</div>
        <div className={classes.text}>
          Please double-check your balance and credit card info or try using a different card to make the payment.
        </div>
      </div>
      <div className={classes.content}>
        <Button
          onClick={() => { window.location = retryPaymentUrl }}
          type='primary'
          size='large'
          block
        >
          Try again
        </Button>
        <div className={classes.change}>
          <span>
            <Link
              to={{
                pathname: backToCheckoutUrl,
                state
              }}
            > Back to Checkout </Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default DesktopWrapperHOC2(injectSheet(styles)(PaymentFailed))