import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'
import ScrollbarWrap from './../ScrollbarWrap'
import Item from './Item'
import RandomTutors from './RandomTutors'

const styles = {
  root: {
    position: 'absolute',
    // top: '100%',
    top: rem(71),
    left: rem(8),
    right: rem(8),
  },
  randomTutors: {
    paddingLeft: rem(96),
    paddingRight: rem(16),
    '& > div': {
      '&:first-child': {
        left: rem(16),
      },
    },
  },
  divider: {
    marginTop: rem(6),
    paddingLeft: rem(16),
    paddingRight: rem(16),
    fontSize: rem(11),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
  },
}

const List = ({
  classes,
  randomTutors,
  tutors,
  subject,
  onChangeRequestedTutorId,
  onHide,
  getHelpedText,
}) => {
  const tutorsBySubject = subject
    ? tutors.filter(({ subjects }) => subjects.find(item => item === subject))
    : []

  const TUTORS = [...new Set([...tutorsBySubject, ...tutors])]

  const onSelectTutor = (id) => {
    onChangeRequestedTutorId(id)
    onHide()
  }
  

  return (
    <div className={classes.root}>
      <ScrollbarWrap>
        <RandomTutors
          items={randomTutors}
          tutors={tutors}
          title='Any tutor'
          className={classes.randomTutors}
          onClick={() => onSelectTutor(null)}
          withHover
        />
        <div className={classes.divider}>Your past tutors</div>
        {TUTORS.map(({ id, name, meta, subjects }) =>
          <Item
            key={id}
            id={id}
            name={name}
            subtitle={getHelpedText(subjects, meta.helped)}
            onClick={() => onSelectTutor(id)}
          />
        )}
      </ScrollbarWrap>
    </div>
  )
}

export default injectSheet(styles)(List)