import React, { Fragment } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, RATES, RATE_VALUES } from './../../theme/base'
import { Icon } from '@verdaccio/mt-uikit'
import { CLAIMS, OTHER_CLAIM } from '../../config'

const styles = {
  rates: {
    width: rem(220),
    marginTop: rem(56),
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.colors.lightBlueGrey,
    position: 'relative',
    direction: 'rtl',
    '&.lowRate': {
      marginTop: rem(24),
    },
    '& label': {
      color: theme.colors.lightBlueGrey,
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer',
      position: 'relative',
      '-webkitTapHighlightColor': 'transparent',
      transition: 'color 300ms ease-in-out',
      '&:hover': {
        color: theme.colors.star,
        '& ~ label': {
          color: theme.colors.star,
        },
      },
    },
    '& svg': {
      width: rem(44),
      height: rem(44),
      display: 'block',
      fill: 'currentColor',
      transition: 'fill 300ms ease-in-out',
      pointerEvents: 'none',
    },
  },
  radio: {
    display: 'none',
    '&:checked': {
      '& ~ label': {
        color: theme.colors.star,
      },
    },
  },
  reason: {
    paddingTop: rem(8),
    paddingLeft: rem(16),
    paddingRight: rem(16),
    textAlign: 'center',
    position: 'relative',
  },
  item: {
    marginTop: rem(8),
    marginLeft: rem(8),
    marginRight: rem(8),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  checkbox: {
    display: 'none',
    '&:checked': {
      '& + label': {
        background: theme.colors.blue,
        borderColor: theme.colors.blue,
        color: theme.colors.white,
      },
    },
  },
  label: {
    height: rem(40),
    padding: [rem(8), rem(12)],
    borderRadius: rem(20),
    border: [rem(1), 'solid', theme.colors.lightBlueGrey],
    fontSize: rem(15),
    lineHeight: rem(24),
    fontWeight: 600,
    color: theme.colors.blue,
    cursor: 'pointer',
    transition: 'background 300ms ease-in-out, border 300ms ease-in-out, color 300ms ease-in-out',
  },
  textarea: {
    width: `calc(100% - ${rem(48)})`,
    height: rem(136),
    marginTop: rem(24),
    marginLeft: rem(24),
    marginRight: rem(24),
    border: [rem(1), 'solid', theme.colors.lightBlueGrey],
    padding: rem(16),
    lineHeight: rem(22),
    color: theme.colors.slateGrey,
    borderRadius: rem(16),
    resize: 'none',
    fontFamily: 'Arial',
  },
  title: {
    marginTop: rem(10),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    color: theme.colors.charcoalGrey,
    fontSize: rem(22),
    lineHeight: rem(28),
    fontWeight: 600,
    textAlign: 'center',
  },
  subtitle: {
    marginTop: rem(8),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    color: theme.colors.slateGreyTwo,
    fontSize: rem(15),
    lineHeight: rem(20),
    letterSpacing: rem(0.09),
    textAlign: 'center',
  },
}

const Rate = ({
  rate,
  claims,
  other,
  onChangeRate,
  onChangeClaims,
  onChangeOther,
  classes,
}) => {
  const lowRate = rate && rate < 4
  const highRate = rate && !lowRate

  return (
    <Fragment>
      <div className={classnames(classes.rates, { lowRate })}>
        {RATES.map(item => {
          const rateId = `tutor-rate-${item}`
          const checked = rate === item

          return (
            <Fragment key={rateId}>
              <input
                id={rateId}
                type='radio'
                value={item}
                className={classes.radio}
                checked={checked}
                onChange={e => onChangeRate(parseInt(e.target.value))}
              />
              <label htmlFor={rateId}>
                <Icon iconName='star2' />
              </label>
            </Fragment>
          )
        }
        )}
      </div>

      {lowRate &&
        <Fragment>
          <div className={classes.title}>{RATE_VALUES[rate - 1]}</div>
          <div className={classes.subtitle}>What went wrong?</div>
          <div className={classes.reason}>
            {CLAIMS.map(claim =>
              <div className={classes.item} key={claim}>
                <input
                  className={classes.checkbox}
                  id={claim}
                  type='checkbox'
                  value={claim}
                  checked={claims.indexOf(claim) > -1}
                  onChange={(e) => {
                    const { value } = e.target
                    const checked = claims.indexOf(claim) > -1
                    const result = checked
                      ? claims.filter(c => c !== value)
                      : [...claims, value]
                    onChangeClaims(result)
                  }}
                />
                <label className={classes.label} htmlFor={claim}>
                  {claim}
                </label>
              </div>
            )}
          </div>
        </Fragment>
      }

      {highRate &&
        <div className={classes.title}>{RATE_VALUES[rate - 1]}</div>
      }

      {lowRate && claims.indexOf(OTHER_CLAIM) > -1 &&
        <textarea
          className={classes.textarea}
          name='other'
          placeholder='Tell us what went wrong...'
          value={other}
          onChange={(e) => onChangeOther(e.target.value)}
        />
      }

    </Fragment>
  )
}

export default injectSheet(styles)(Rate)