import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    backgroundImage: `url(${require('./secure.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: rem('40 40'),
    backgroundPosition: 'right center',
    marginTop: rem(8),
    paddingRight: rem(70),
    position: 'relative',
  },
  title: {
    color: theme.colors.slateGrey,
    fontSize: rem(13),
    lineHeight: rem(20),
    fontWeight: 600,
    letterSpacing: rem(0.08),
  },
  text: {
    maxWidth: rem(310),
    marginTop: rem(4),
    color: theme.colors.slateGrey,
    fontSize: rem(13),
    lineHeight: rem(20),
    letterSpacing: rem(0.08),
  },
}

const Secure = ({ classes }) =>
  <div className={classes.root}>
    <div className={classes.title}>100% Secure Payment</div>
    <div className={classes.text}>
      Your tutor only gets paid when you are satisfied
      with the result of your tutoring session.
      If not, our platform will issue a full refund.
    </div>
  </div>

export default injectSheet(styles)(Secure)