import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, errors } from './../../theme/base'
import Header from './../Header'
import Footer from './../../components/Footer'

const iconsPath = require.context('./')

const styles = {
  root: {
    backgroundImage: `url(${iconsPath(`./not-found-icon.svg`)})`,
    backgroundColor: theme.colors.paleGrey,
    backgroundRepeat: 'no-repeat',
    //backgroundSize: 'cover',
    backgroundPosition: ['center', rem(74)],
    width: '100%',
    height: rem(300),
    paddingTop: rem(74),
  },
  title: {
    ...errors.title,
    marginTop: rem(16),
  },
  text: {
    ...errors.text,
    marginTop: rem(8),
  },
}

const NotFound = ({ classes }) => 
  <Fragment>
    <Header flat />
    <div className={classes.root} />
    <div className={classes.title}>404</div>
    <div className={classes.text}>
      Oops! The page you were looking for
      <br />
      doesn’t exist.
    </div>
    <Footer />
  </Fragment>

export default injectSheet(styles)(NotFound)