import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import theme, { rem, adaptive } from './../../theme/base'
import { hideScroll } from './../../../theme/base' 
import { PAGES } from './../../../config'

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20000,
    display: 'none',
    [adaptive]: {
      display: 'block',
    },
    '& button': {
      borderRadius: rem(16),
    },
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  wrapper: {
    background: theme.colors.blue,
    width: '83vw',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
  },
  head: {
    background: theme.colors.white,
    height: rem(178),
    padding: rem(16),
    borderRadius: [0, 0, rem(20), rem(20)],
    boxShadow: `0 ${rem(16)} ${rem(40)} 0 rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)`,
    position: 'relative',
  },
  close: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    left: rem(16),
    top: rem(16),
    cursor: 'pointer',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  logo: {
    width: rem(160),
    height: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'absolute',
    top: rem(15),
    left: '50%',
    transform: 'translateX(-50%)',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  actions: {
    position: 'absolute',
    left: rem(16),
    right: rem(16),
    bottom: rem(16),
  },
  login: {
    marginTop: rem(16),
    fontSize: rem(20),
    lineHeight: rem(24),
    textAlign: 'center',
    color: theme.colors.blue,
    fontWeight: 600,
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    cursor: 'pointer',
  },
  list: {
    padding: [rem(64), rem(24), 0],
  },
  item: {
    '& + &': {
      marginTop: rem(24),
    },
    '& a': {
      color: rem(18),
      fontSize: rem(17),
      lineHeight: rem(20),
      color: theme.colors.white,
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
    },
  },
  hideScroll,
}

const MobileMenu = ({
  classes,
  opened,
  onClose,
  history,
  showLogin,
}) => {
  useEffect(() => {
    if (opened) {
      document.getElementsByTagName('html')[0].classList.add(classes.hideScroll)
      document.getElementsByTagName('body')[0].classList.add(classes.hideScroll)
    } else {
      document.getElementsByTagName('html')[0].classList.remove(classes.hideScroll)
      document.getElementsByTagName('body')[0].classList.remove(classes.hideScroll)
    }
  }, [opened])

  if (!opened) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={classes.wrapper}>
        <div className={classes.head}>
          <div className={classes.close} onClick={onClose}>
            <img src={require('./close.svg')} alt='' />
          </div>
          <Link className={classes.logo} to='/'>
            <img src={require('../../../assets/images/logo.svg')} alt='' />
          </Link>
          {showLogin &&
            <div className={classes.actions}>
              <Button
                type='primary'
                size='large'
                block
                onClick={() => history.push('/signup')}
              >
                Sign up
              </Button>
              <div className={classes.login} onClick={() => history.push('/login')}>Login</div>
            </div>
          }
        </div>
        <div className={classes.list}>
          {PAGES.map(({ name, link }, index) =>
            <div className={classes.item} key={index}>
              <Link
                to={link}
                onClick={() => history.location.pathname === link ? onClose() : {}}
              >
                {name}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(injectSheet(styles)(MobileMenu))