import React from 'react'
import { useSelector } from 'react-redux'
import { TutorProfile } from './../../components/Tutor'
import { selectors } from '@verdaccio/mt-uikit'
import { useOrder, useTutor } from './../../hooks'

const TutorProfilePage = ({
  tutorId,
  orderId,
  ...rest
}) => {
  const tutor = useTutor(tutorId)
  const order = useOrder(orderId)
  const presence = useSelector(selectors.presenceSelector)
  return <TutorProfile
    tutor={tutor}
    order={order}
    presence={presence}
    {...rest}
  />
}

export default TutorProfilePage
