import React, { forwardRef } from 'react'
import injectSheet from 'react-jss'
import {
  Button,
  // Icon, 
  utils,
} from '@verdaccio/mt-uikit'
import theme, { rem, adaptive } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    background: theme.colors.white,
    paddingTop: rem(20),
    paddingBottom: rem(24),
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: rem(52),
    zIndex: 3,
    [adaptive]: {
      padding: rem(24),
      position: 'fixed',
    },
    '& button': {
      padding: [rem(8), rem(24)],
      lineHeight: rem(20),
      border: 'none',
      position: 'relative',
      '&.applepay': {
        background: theme.colors.black,
        '& svg': {
          width: rem(66),
          height: rem(29),
          marginRight: 0,
        },
      },
      '& > span': {
        display: 'block',
        position: 'relative',
        '&:only-child': {
          paddingTop: rem(4),
        },
        '&:not(:only-child)': {
          fontSize: rem(19),
          '&:nth-child(1)': {
            padding: [rem(12), rem(90), rem(8), 0],
            fontWeight: 'normal',
            textAlign: 'left',
            fontFamily: theme.fontFamilies.SFProRoundedRegular,
            '& svg': {
              width: rem(71),
              height: rem(20),
              marginTop: rem(8),
              marginRight: 0,
              display: 'block',
              fill: theme.colors.white,
            },
          },
          '&:nth-child(2)': {
            paddingLeft: rem(24),
            borderLeft: [rem(1), 'solid', `rgba(255, 255, 255, 0.2)`],
            textAlign: 'right',
            position: 'absolute',
            right: rem(24),
            top: '50%',
            transform: 'translateY(-50%)',
          },
          '& > span': {
            textAlign: 'left',
            display: 'block',
            whiteSpace: 'nowrap',
            '&:only-child': {
              padding: [8, 0],
            },
            '&:nth-child(1)': {
              marginTop: 2,
            },
            '&:nth-child(2)': {
              marginTop: 4,
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 'normal',
              fontFamily: theme.fontFamilies.SFProRoundedRegular,
              textDecoration: 'line-through',
              opacity: 0.5,
            },
          },
        },
      },
    },
    '& svg': {
      width: rem(20),
      height: rem(20),
      marginRight: rem(8),
      fill: theme.colors.white,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
}

const Submit = forwardRef(
  ({
    classes,
    text,
    price,
    total,
    onSubmit,
    disabled,
  }, ref) => {
    const hasBalance = price > total
    const calculateAndCheckout = total >= 0 && price >= 0
    return (
      <div className={classes.root} ref={ref}>
        <Button
          block
          size='large'
          disabled={disabled}
          onClick={() => onSubmit()}
        >
          {calculateAndCheckout 
            ? <>
                <span>{text}</span>
                <span>
                  {hasBalance && total > 0
                    ? <>
                        <span>{utils.printAmount(total)}</span>
                        <span>{utils.printAmount(price)}</span>
                      </>
                    : hasBalance
                      ? <span>{utils.printAmount(total)}</span>
                      : <span>{utils.printAmount(price)}</span>
                  }
                </span>
              </>
            : <span>{text}</span>
          }
        </Button>
        {/* 
          {!paymentMethod ? (
            <Button block size='large' onClick={onAddPaymentCard}>
              Add payment method
            </Button>
          ) : (
            <Button
              block
              size='large'
              onClick={onSubmit}
              className={classnames({ applepay })}
            >
              {mastercard &&
                <Fragment>
                  <span>Pay with · {card}</span>
                  <span>&nbsp;</span>
                  <span>{utils.printAmount(price)}</span>
                </Fragment>
              }
              {paypal &&
                <Fragment>
                  <span>
                    <Icon iconName='paypal' />
                  </span>
                  <span>&nbsp;</span>
                  <span>{utils.printAmount(price)}</span>
                </Fragment>
              }
              {applepay &&
                <Icon iconName='applepay' />
              }
            </Button>
          )} 
        */}
      </div>
    )
  }
)

export default injectSheet(styles)(Submit)