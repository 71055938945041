import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import injectSheet from 'react-jss'
import theme, { rem, content, adaptive, MODAL_WIDTH_DESKTOP } from './../../theme/base'
import { FAQ } from './../../config'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(80), 0],
    position: 'relative',
    [adaptive]: {
      padding: [rem(64), rem(8)],
    },
  },
  content,
  title: {
    fontFamily: theme.fontFamilies.SFProRoundedBlack,
    fontSize: rem(24),
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: rem(0.14),
    textAlign: 'center',
    color: theme.colors.charcoalGrey,
  },
  list: {
    marginTop: rem(40),
  },
  item: {
    textAlign: 'center',
    [adaptive]: {
      textAlign: 'left',
    },
    '& + &': {
      marginTop: rem(32),
    },
  },
  question: {
    paddingLeft: rem(32),
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontSize: rem(17),
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: rem(20),
    color: theme.colors.charcoalGrey,
    cursor: 'pointer',
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    '& img': {
      width: rem(16),
      height: rem(16),
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  answer: {
    maxWidth: MODAL_WIDTH_DESKTOP,
    paddingTop: rem(16),
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(15),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.47,
    letterSpacing: rem(0.09),
    color: theme.colors.slateGrey,
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      maxWidth: 'none',
      paddingLeft: rem(32),
    },
  },
}

const Faq = ({ classes }) => {
  const initialState = FAQ.map(item => false)
  const [collapse, setCollapse] = useState(initialState)
  const onOpen = (idx) => setCollapse(collapse.map((item, index) => {
    if (idx === index) {
      item = !item
    }
    return item
  }))

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.title}>Frequently Asked Questions</div>
        <ul className={classes.list}>
          {FAQ.map(({ title, content }, index) =>
            <li className={classes.item} onClick={() => onOpen(index)} key={title}>
              <div className={classes.question}>
                <img src={require('./ic-question.svg')} alt='' />
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </div>
              <Collapse isOpen={collapse[index]}>
                <div
                  className={classes.answer}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </Collapse>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default injectSheet(styles)(Faq)