import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from '@verdaccio/mt-uikit'
import { rem } from '../../../../theme/base'
import { useActions } from '../../../../actions'

const styles = {
  '@keyframes spin': {
    '0%': { 
      transform: 'rotate(0deg)',
    },
    '20%': { 
      transform: 'rotate(180deg)',
    },
    '100%': { 
      transform: 'rotate(180deg)',
    },
  },
  root: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(19),
    right: rem(16),
    cursor: 'pointer',
    animation: 'spin 2s linear infinite',
    '& div, svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const Settings = ({ 
  classes,
  // prevUrl,
  onClose, 
}) => {
  const actions = useActions()
  const onClick = () => {
    actions.onSettings()
    onClose()
  }
  return (
    <div className={classes.root} onClick={onClick}>
      <Icon iconName='settings' />
    </div>
  )
}
      
export default injectSheet(styles)(Settings)