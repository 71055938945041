import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content } from './../../theme/base'
import { useActions } from './../../actions'
import { useEvents } from './../../hooks'
import { utils } from '@verdaccio/mt-uikit'
import WaitTextBlock from './WaitTextBlock'

const styles = {
  root: {
    paddingLeft: rem(8),
    paddingRight: rem(8),
    textAlign: 'center',
    position: 'relative',
  },
  content,
  time: {
    marginTop: rem(24),
    fontSize: rem(11),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
  },
  image: {
    width: rem(236),
    height: rem(108),
    margin: [rem(24), 'auto', 0],
  },
  title: {
    marginTop: rem(24),
    color: theme.colors.charcoalGrey,
    fontSize: rem(21),
    lineHeight: rem(28),
    fontWeight: 600,
    textAlign: 'center',
  },
}

const WaitTutor = ({ classes, order }) => {
  const actions = useActions()
  const eventOrderPaid = useEvents(order.id).find(({ event }) => event === 'order_paid')
  const items = [
    {
      icon: 'notification',
      type: 'img',
      text: () => <>It may take up to a few hours. You will be notified when the tutor is found.</>,
    },
    { 
      icon: 'flag',
      text: () => <>Make sure you send all instructions and files for your task. You can send it directly to the chat. The tutor will see it when connected.</>, 
    },
    {
      icon: 'question',
      text: () => <>Still have questions? <span onClick={actions.onFaq}>check out the FAQ.</span></>,
    },
  ]
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {eventOrderPaid &&
          <div className={classes.time}>{utils.printDateTime(eventOrderPaid.time)}</div>
        }
        <img className={classes.image} src={require('./chat-empty-bg.svg')} alt='' />
        <div className={classes.title}>Looking for the tutor</div>
        <WaitTextBlock items={items} />
      </div>
    </div>
  )
}

export default injectSheet(styles)(WaitTutor)