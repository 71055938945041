import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { content, rem, desktop, MODAL_WIDTH_DESKTOP } from './../../theme/base'
import { Card, filters } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    ...content,
    paddingTop: rem(90),
    paddingBottom: rem(129),
    position: 'relative',
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: rem(32),
    },
    '& .text': {
      paddingRight: 0,
    },
  },
}

const Tutor = ({
  tutor,
  presence,
  textClassName,
  onSelect,
  onRequest,
}) => {
  const idPrefix = 'dots'
  const dotsId = `${idPrefix}-${tutor.id}`
  const _onSelect = (e) => {
    if (!new RegExp(idPrefix).test(e.target.id)) {
      onSelect(tutor.id)
    }
  }
  return (
    <Card key={tutor.id} image='big' onClick={_onSelect}>
      <Card.Avatar user={tutor} presence={presence} />
      <Card.Title>{tutor.name}</Card.Title>
      <Card.Text className={textClassName}>
        {tutor.meta.subject}
      </Card.Text>
      <Card.Rate value={tutor.rate}>
        <Card.RateText value={`Helped ${tutor.meta.helped === 1 ? 'once' : `${tutor.meta.helped} times`}`} />
      </Card.Rate>
      <Card.Dots id={dotsId} onClick={() => onRequest(tutor.id)} />
    </Card>
  )
}

const Tutors = ({
  classes,
  tutors,
  presence,
  onSelect,
  onRequest,
}) => {
  const groups = [
    ['Favorites', filters.tutors.favorite(tutors, presence)],
    ['Your Past tutors', filters.tutors.past(tutors, presence)],
  ]
    .filter(group => group[1].length > 0)

  return <div className={classes.root}>
    {groups
      .map(([name, members], index) =>
        <Fragment key={name}>
          <Card.Divider>{name}</Card.Divider>
          {members.map(tutor =>
            <Tutor
              key={tutor.id}
              tutor={tutor}
              presence={presence}
              textClassName='text'
              onSelect={onSelect}
              onRequest={onRequest}
            />
          )}
          {index < groups.length - 1 &&
            <Card.Divider>&nbsp;</Card.Divider>
          }
        </Fragment>
      )}
  </div>
}

export default injectSheet(styles)(Tutors)
