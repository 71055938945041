import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(4),
    fontSize: rem(15),
    lineHeight: rem(24),
    letterSpacing: rem(0.09),
    color: theme.colors.slateGrey,
    textAlign: 'center',
  },
}

const Subject = ({ classes, children }) => 
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Subject)