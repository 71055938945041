import React, { Fragment, useState } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'
import { Datepicker, Preset, Presets, Reset, Timepicker } from './Layout'
import { DEADLINE_PRESETS, checkRestriction, getDeadlineInHours } from '../../nerdytutors'

const styles = {
  date: {
    width: `calc(50% - ${rem(4)})`,
    height: rem(40),
    padding: 0,
    left: '-200%',
    top: '-200%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'absolute',
    '&.custom': {
      left: 'auto',
      top: 'auto',
      position: 'relative',
    },
  },
  action: {
    width: '100%',
    height: 'inherit',
    position: 'relative',
  },
  helper: {
    background: theme.colors.white,
    fontSize: rem(17),
    lineHeight: rem(40),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
    '&.active': {
      borderColor: theme.colors.blue,
      color: theme.colors.blue,
      fontWeight: 600,
    },
    '&.error': {
      borderColor: theme.colors.brightOrangeTwo,
      color: theme.colors.brightOrangeTwo,
    }
  },
  time: {
    width: `calc(50% - ${rem(4)})`,
    height: rem(40),
    marginLeft: rem(8),
    left: '-200%',
    top: '-200%',
    position: 'absolute',
    display: 'block',
    '&.custom': {
      left: 'auto',
      top: 'auto',
      display: 'inline-block',
      verticalAlign: 'top',
      position: 'relative',
    },
  },
}

const SelectDeadline = ({
  deadline,
  minDeadline,
  size,
  deadlineset,
  onChange,
  classes,
}) => {
  const [custom, setCustom] = useState(!!deadline)
  const [showTimepicker, setShowTimepicker] = useState(false)
  const [showDatepicker, setShowDatepicker] = useState(false)

  const onShowDatepicker = () => setShowDatepicker(true)
  const onHideDatepicker = () => setShowDatepicker(false)
  const onShowTimepicker = () => setShowTimepicker(true)
  const onHideTimepicker = () => setShowTimepicker(false)
  const onReset = () => { onChange(null); setCustom(false); onHideDatepicker(); onHideTimepicker(); }
  const onCustom = () => {
    setCustom(true);
    if (!deadline) onChange(minDeadline)
    onShowDatepicker()
  }

  const onChangeDate = (value) => onChange(getDeadlineInHours(value) || minDeadline)

  const minDate = moment().startOf('hour').add(minDeadline + 1, 'h')
  const date = deadline
    ? moment().startOf('hour').add(deadline + 1, 'h')
    : minDate

  const restriction = checkRestriction(size, deadline)
  const dateError = restriction && (restriction - deadline > 24)
  const timeError = restriction

  return (
    <Presets
      type='deadline'
      custom={custom}
      customText='Choose other due date'
      onCustom={onCustom}
    >
      {!custom &&
        <Fragment>
          {deadlineset.map((item, index) => {
            const active = item === deadline
            const disabled = checkRestriction(size, item)
            const error = active && disabled
            return (
              <Preset
                key={index}
                active={active}
                disabled={disabled}
                error={error}
                text={DEADLINE_PRESETS[index]}
                onClick={() => !disabled && !error && onChange(item)}
              />
            )
          })}
        </Fragment>
      }
      <div className={classnames(classes.date, { custom })}>
        <div className={classes.action} onClick={onShowDatepicker}>
          <div className={classnames(classes.helper, { 'active': showDatepicker, 'error': dateError })}>
            {`Due ${moment(date).format('MMMM D')}`}
          </div>
        </div>
        {showDatepicker &&
          <Datepicker
            date={date.toDate()}
            minDate={minDate.toDate()}
            onChange={onChangeDate}
            onClose={onHideDatepicker}
          />
        }
      </div>
      <div className={classnames(classes.time, { custom })}>
        <div className={classes.action} onClick={() => onShowTimepicker()}>
          <div className={classnames(classes.helper, {
            'active': showTimepicker,
            'error': timeError,
          })}>
            {date.format('h A')}
          </div>
        </div>
        {showTimepicker &&
          <Timepicker
            date={date.toDate()}
            minDate={minDate.toDate()}
            onChange={onChangeDate}
            onClose={onHideTimepicker}
          />
        }
      </div>
      {custom &&
        <Reset onClick={onReset} />
      }
    </Presets>
  )
}

export default injectSheet(styles)(SelectDeadline)