import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import injectSheet from 'react-jss'
import theme, { rem, desktop, MODAL_WIDTH_DESKTOP } from './../../../../theme/base'
import { useOutsideClick } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    background: theme.colors.white,
    width: '100vw',
    borderRadius: rem(16),
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    boxShadow: `0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06)`,
    transform: `translateY(${rem(8)})`,
    whiteSpace: 'normal',
    zIndex: 4,
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
      top: 'auto',
      left: '50%',
      right: 'auto',
      bottom: `calc(10vh - ${rem(28)})`,
      transform: 'translateX(-50%)',
    },
    '& .react-datepicker': {
      width: '100%',
      borderRadius: rem(16),
      border: 'none',
    },
    '& .react-datepicker__month-container': {
      width: '100%',
      paddingBottom: rem(24),
    },
    '& .react-datepicker__day-names, .react-datepicker__week': {
      padding: [rem(16), rem(12), 0],
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .react-datepicker__day, .react-datepicker__time-name': {
      width: rem(40),
      height: rem(40),
      margin: 0,
      padding: [rem(9), 0, rem(11)],
      color: theme.colors.charcoalGrey,
      fontSize: rem(15),
      lineHeight: rem(20),
      textAlign: 'center',
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer',
      '&:hover': {
        // TODO: add '#D6E3FF' to theme.colors
        background: '#D6E3FF',
      },
      '& + &': {
        marginLeft: rem(8),
      },
    },
    '& .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today': {
      // TODO: add '#BAC7E2' to theme.colors
      border: [rem(1), 'solid', '#BAC7E2'],
      fontWeight: 'normal',
    },
    '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range': {
      background: theme.colors.blue,
      color: theme.colors.white,
      fontWeight: 'normal',
      '&:hover': {
        background: `${theme.colors.blue} !important`,
        color: `${theme.colors.white} !important`,
        fontWeight: 'normal',
      },
    },
    '& .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled': {
      // TODO: add 'rgba(186, 199, 226, 0.5)' to theme.colors
      color: `rgba(186, 199, 226, 0.5)`,
      '&:hover': {
        background: 'none !important',
        cursor: 'default',
      },
    },
    '& .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover': {
      opacity: 0.5,
      cursor: 'default',
    },
    '& .react-datepicker__header': {
      background: 'none',
      padding: 0,
      border: 'none',
      borderRadius: [rem(16), rem(16), 0, 0],
    },
    '& .react-datepicker__month': {
      margin: 0,
    },
    '& .react-datepicker__current-month': {
      padding: [rem(17), rem(90), rem(15)],
      color: theme.colors.charcoalGrey,
      fontSize: rem(17),
      lineHeight: rem(22),
      textAlign: 'center',
      borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    },
    '& .react-datepicker__day-name': {
      width: rem(40),
      height: rem(40),
      margin: 0,
      padding: [rem(9), 0, rem(11)],
      color: theme.colors.blueyGrey,
      fontSize: rem(15),
      lineHeight: rem(20),
      textAlign: 'center',
      '& + &': {
        marginLeft: rem(8),
      },
    },
    '& .react-datepicker__navigation': {
      width: rem(32),
      height: rem(32),
      backgroundRepeat: 'no-repeat',
      backgroundSize: [rem(16), rem(16)],
      backgroundPosition: 'center center',
      border: 'none',
      position: 'absolute',
      top: rem(12),
      right: 0,
    },
    '& .react-datepicker__navigation--previous': {
      backgroundImage: `url(${require('./arrow-left.svg')})`,
      left: rem(12),
      right: 'auto',
    },
    '& .react-datepicker__navigation--next': {
      backgroundImage: `url(${require('./arrow-right.svg')})`,
      left: rem(48),
      right: 'auto',
    },
  },
  set: {
    position: 'absolute',
    top: rem(17),
    right: rem(24),
    cursor: 'pointer',
    color: theme.colors.blue,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontWeight: 600,
  },
}

const Datepicker = ({
  classes,
  date,
  minDate,
  onChange,
  onClose,
}) => {
  const outsideClickRef = useRef(null)
  useOutsideClick(outsideClickRef, onClose)
  return (
    <div className={classes.root} ref={outsideClickRef}>
      <DatePicker
        selected={date}
        onChange={onChange}
        minDate={minDate}
        showPopperArrow={false}
        showDisabledMonthNavigation
        inline
      >
        <div className={classes.set} onClick={onClose}>Set</div>
      </DatePicker>
    </div>
  )
}

export default injectSheet(styles)(Datepicker)