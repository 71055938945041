import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { createChat, selectors, utils } from '@verdaccio/mt-uikit'
import { useSelector, useDispatch } from 'react-redux'
import { rem, desktop, media } from './../../../theme/base'
import { useActions } from './../../../actions'

import Icon from './../layout/Icon'
import Title from './../layout/Title'
import styles from './styles'

const Chat = createChat(null, { useActions, useSelector, useDispatch })

const { flex, ...wrapperStyles } = styles
export default injectSheet({
  ...wrapperStyles,
  flex: {
    ...flex,
    [desktop]: {
      minHeight: rem(588),
      height: rem(588),
    },
  },
  chat: {
    background: '#F6F7FB',
    width: rem(352),
    overflow: 'hidden',
    borderRadius: [0, rem(16), rem(16), 0]
  }
})(({
  className,
  title,
  banner,
  children,
  classes,
  onBack,
  onClose,
  onSupport,
  context,
}) => {
  const { supportNode } = useSelector(selectors.authSelector)
  const [showChat, setShowChat] = useState(false)
  const isDesktop = !utils.useMobile(media.desktop)

  const _onSupport = isDesktop
    ? () => setShowChat(!showChat)
    : onSupport

  return <div className={classnames(classes.flex)}>
    <div className={classes.root}>
      <div className={classes.header}>
        <Title>{title}</Title>
        {onBack
          ? <Icon.Back onClick={onBack} />
          : <Icon.Close onClick={onClose} />
        }
        {showChat && isDesktop
          ? <Icon.CloseChat onClick={_onSupport} />
          : <Icon.Support onClick={_onSupport} banner={banner} context={context} />
        }
      </div>
      <div className={classes.wrapper}>
        <div className={classnames(classes.content, className)}>
          {children}
        </div>
      </div>
    </div>
    {showChat && isDesktop &&
      <div className={classes.chat}>
        <Chat node={supportNode} />
      </div>
    }
  </div>
})