import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { TAB_CHAT, TAB_TIMELINE } from './tabs'
import { rem } from '../../theme/base'

import Orders from './../../containers/Orders'
import Header from './../Header'
import Footer from './../../components/Footer'
import ChatNavBarDesktop from '../../components/ChatNavBarDesktop'

const styles = {
  root: {
    maxHeight: '100%',
    position: 'absolute',
    top: rem(56),
    bottom: 0,
    left: 0,
    right: 0,
  },
  wrapper: {
    maxHeight: '100%',
    position: 'fixed',
    top: rem(112),
    bottom: 0,
    left: rem(360),
    right: 0,
    overflowY: 'auto',
    zIndex: 3,
  },
}

const OrderChatMobile = ({
  classes,
  order,
  onMore,
  onOrderDetails,
  tabs,
  tab,
  nodeName,
  renderChat,
  renderTimeline
}) => {
  return <Fragment>
    <Header active='orders' />
    <Orders desktop active={nodeName} />
    <ChatNavBarDesktop
      onMore={onMore}
      onOrderDetails={onOrderDetails}
      order={order}
      tabs={tabs}
    />
    {tab === TAB_CHAT &&
      <div className={classes.wrapper}>
        {renderChat()}
      </div>
    }
    {tab === TAB_TIMELINE &&
      <div className={classes.wrapper}>
        {renderTimeline()}
      </div>
    }
    <Footer active='orders' />
  </Fragment>
}

export default injectSheet(styles)(OrderChatMobile)
