import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../theme/base'
import { Icon } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    background: theme.colors.white,
    maxWidth: rem(343),
    margin: [rem(24), 'auto', 0, 'auto'],
    padding: [rem(24), rem(16)],
    borderRadius: rem(16),
    boxShadow: [0, rem(4), rem(8), 'rgba(86, 93, 107, 0.1)'],
  },
  item: {
    paddingLeft: rem(32),
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    textAlign: 'left',
    position: 'relative',
    '& + &': {
      marginTop: rem(16),
    },
    '& span': {
      // TODO: add '#3D77FF' to theme.colors
      color: '#3D77FF',
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
      fontWeight: 600,
      cursor: 'pointer',
    },
    '& svg, img': {
      width: rem(16),
      height: rem(16),
      display: 'block',
      position: 'absolute',
      top: rem(1),
      left: 0,
    },
  },
}

const WaitTextBlock = ({
  classes,
  items,
  onFaq,
}) =>
  <div className={classes.root}>
    {items.map(({ icon, type, text }, index) =>
      <div className={classes.item} key={index}>
        {type === 'img'
          ? <img src={require(`./${icon}.png`)} alt='' />
          : <Icon iconName={icon} />
        }
        {text()}
      </div>
    )}
  </div>

export default injectSheet(styles)(WaitTextBlock)