import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../theme/base'

const styles = {
  root: {
    backgroundImage: `url(${require('./support_empty.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: [rem(375), rem(380)],
    backgroundPosition: `center ${rem(-47)}`,
    // TODO: add '#F6F7FB' to theme.colors
    backgroundColor: '#F6F7FB',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: [rem(248), rem(24), 0]
  },
  title: {
    fontSize: rem(21),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    textAlign: 'center',
  },
  text: {
    marginTop: rem(8),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.charcoalGrey,
    textAlign: 'center',
  },
}

const Empty = ({ 
  classes, 
  children, 
}) =>
  <div className={classes.root}>
    <div className={classes.title}>Live Support</div>
    <div className={classes.text}>Feel free to ask any questions. We will answer you within 5 minutes, usually faster.</div>
  </div>

export default injectSheet(styles)(Empty)
