
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '@verdaccio/mt-uikit'

export default () => {
  const chatsNotifications = useSelector(selectors.notificationSelectors.chats)
  const supportNotifications = useSelector(selectors.notificationSelectors.support)
  const hasUnreadMessage = (
    Object.keys(chatsNotifications).filter(key => chatsNotifications[key].nmessages > 0).length > 0 ||
    supportNotifications.nmessages > 0
  )

  useEffect(() => {
    const savedTitle = document.title
    const favicon = document.getElementById('favicon')
    const savedFavicon = favicon.href
    if (hasUnreadMessage) {
      document.title = 'You have a new message'
      favicon.href = '/favicon_new.ico'
    }
    return () => {
      document.title = savedTitle
      favicon.href = savedFavicon
    }
  }, [hasUnreadMessage])

  return null
}