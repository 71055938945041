import { useEffect } from 'react'
import { withRouter, matchPath } from 'react-router'
import { isMobile } from './../utils'

const ScrollToTopOnMount = ({
  children, 
  history, 
  skipScrollRoutes,
}) => {
  
  const match = skipScrollRoutes.find(path => matchPath(window.location.pathname, {
    path,
    exact: true,
    strict: true,
  }))

  const skipScroll = match && !isMobile

  useEffect(() => {
    if (typeof window === 'undefined' || skipScroll) {
      return
    }
    window.scrollTo(0, 0)
  }, [history.location.pathname])

  return children
}

export default withRouter(ScrollToTopOnMount)