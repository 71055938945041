import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    padding: [rem(12), 0],
    '& + &': {
      borderTop: [rem(1), 'solid', theme.colors.paleGrey],
    },
  },
}

const Block = ({ 
  classes,
  className,
  children,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Block)