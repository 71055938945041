import React, { Fragment } from 'react'
import { Icon, Button, utils } from '@verdaccio/mt-uikit'
import injectSheet from 'react-jss'
import theme, { rem, desktop, MODAL_WIDTH_DESKTOP } from './../../theme/base'
import Info from './Info'
import Header from './Header'

const styles = {
  root: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  body: {
    paddingTop: rem(16),
    paddingBottom: rem(16),
    maxHeight: `calc(100% - ${rem(89)})`,
    overflowY: 'auto',
    position: 'relative',
  },
  block: {
    marginTop: rem(24),
    marginBottom: rem(8),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    color: theme.colors.charcoalGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
  },
  timezone: {
    marginTop: rem(12),
    padding: [0, rem(24)],
    position: 'relative',
    '& svg': {
      width: rem(16),
      height: rem(16),
      margin: [rem(1), rem(4), 0, 0],
      fill: '#2F333D',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      color: theme.colors.charcoalGrey,
      fontSize: rem(15),
      lineHeight: rem(20),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  subjects: {
    paddingLeft: rem(24),
    paddingRight: rem(24),
    position: 'relative',
  },
  subject: {
    background: theme.colors.paleGrey,
    minWidth: rem(32),
    height: rem(32),
    marginTop: rem(8),
    marginRight: rem(8),
    padding: rem('5 8'),
    borderRadius: rem(16),
    fontSize: rem(15),
    lineHeight: rem(22),
    letterSpacing: rem(0.09),
    color: theme.colors.slateGrey,
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  about: {
    paddingLeft: rem(24),
    paddingRight: rem(24),
    fontSize: rem(15),
    lineHeight: rem(24),
    letterSpacing: rem(0.09),
    color: theme.colors.charcoalGrey,
    [desktop]: {
      maxHeight: rem(400),
      paddingBottom: rem(3),
      overflowY: 'auto',
    },
  },
  buttons: {
    marginTop: rem(16),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& button': {
      minWidth: 0,
      width: `calc(50% - ${rem(4)})`,
      display: 'inline-block',
      verticalAlign: 'top',
      '&:only-child': {
        width: '100%',
      },
      '& svg': {
        width: rem(12),
        height: rem(12),
        marginTop: rem(3),
        marginRight: rem(4),
        display: 'inline-block',
        verticalAlign: 'top',
      },
    },
  },
}

const TutorProfile = ({
  classes,
  tutor,
  order,
  presence,
  onClose,
  onMore,
  onTutorFav,
  onPromptTutorReject,
  onRequestTutor,
}) => {
  const favorite =
    presence &&
    presence.favorite &&
    tutor &&
    tutor.id &&
    presence.favorite.includes(tutor.id)

  const block =
    presence &&
    presence.block &&
    tutor &&
    tutor.id &&
    presence.block.includes(tutor.id)

  return (
    <div className={classes.root}>
      <Header {...tutor} onClose={onClose} onMore={onMore} />
      <div className={classes.body}>
        <Info {...tutor} />
        <div className={classes.buttons}>
          {order
            ? <Button
              type='outline'
              disabled={order.meta.availableRejectTutorInTime <= 0}
              onClick={onPromptTutorReject}
            >Reject tutor</Button>
            : <Button
              onClick={() => { onClose(); onRequestTutor() }}
            >Request new task</Button>
          }
          {!block &&
            <Button
              onClick={() => onTutorFav(tutor, !favorite)}
              type={order && !favorite ? 'primary' : 'outline'}
            >
              <Icon iconName={order && !favorite ? 'heart' : 'heart-blue'} />
              {favorite ? 'Remove' : 'Add to favorites'}
            </Button>
          }
        </div>
        {tutor && tutor.timezone &&
          <Fragment>
            <div className={classes.block}>Tutors time</div>
            <div className={classes.timezone}>
              <Icon iconName={utils.isUserDaylightHours(tutor.timezone) ? 'sun2' : 'moon2'} />
              <span>{utils.printTimezoneDateTime(tutor.timezone)}</span>
            </div>
          </Fragment>
        }
        <div className={classes.block}>Subjects</div>
        <div className={classes.subjects}>
          {
            tutor && 
            tutor.subjects &&
            tutor.subjects.length > 0 &&
            tutor.subjects.map(subject =>
              <div key={subject} className={classes.subject}>{subject}</div>
            )
          }
        </div>
        <div className={classes.block}>About</div>
        {tutor &&
          <div className={classes.about}>{tutor.about}</div>
        }
      </div>
    </div>
  )
}

export default injectSheet(styles)(TutorProfile)
