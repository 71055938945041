import React, { Fragment } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import injectSheet from 'react-jss'
import theme, { content, rem, desktop, adaptive } from './../../theme/base'
import { Card, filters } from '@verdaccio/mt-uikit'
import { useHistory } from 'react-router-dom'
import { useActions } from './../../actions'
import { selectors } from '@verdaccio/mt-uikit'
import { useSelector } from 'react-redux'
import { ordersSelector } from './../../nerdytutors'
import { merge } from 'lodash/object'
import Empty from './Empty'

const styles = {
  root: {
    ...content,
    paddingTop: rem(90),
    paddingBottom: rem(129),
    position: 'relative',
    [adaptive]: {
      '&.empty': {
        paddingTop: rem(74),
      },
    },
    [desktop]: {
      width: rem(360),
      minHeight: '100vh',
      padding: [rem(32 + 56), rem(16), rem(32), rem(16)],
      // TODO: add 'rgba(186, 199, 226, 0.5)' to theme.colors
      borderRight: [rem(1), 'solid', 'rgba(186, 199, 226, 0.5)'],
      '&.empty': {
        marginTop: 0,
        transform: `translateY(${rem(56)})`,
        '&:before': {
          transform: `translateY(${rem(-56)})`,
        },
        '&:after': {
          paddingTop: rem(24),
        },
      },
      '&.desktop': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
      '&:before': {
        content: '""',
        backgroundImage: `url(${require('./select.svg')})`,
        backgroundPosition: 'top center',
        backgroundSize: '100% 100%',
        width: `calc(100vw - ${rem(400)})`,
        height: rem(64),
        position: 'fixed',
        left: rem(380),
        top: '50%',
        transform: `translateY(${rem(-24)})`,
        display: 'block',
      },
      '&:after': {
        content: '"Select a chat to start messaging"',
        width: `calc(100vw - ${rem(400)})`,
        position: 'fixed',
        left: rem(380),
        right: 0,
        top: '50%',
        paddingTop: rem(56),
        textAlign: 'center',
        fontSize: rem(17),
        lineHeight: rem(24),
        color: theme.colors.blueyGrey,
        display: 'block',
      },
    },
  },
  item: {
    [desktop]: {
      '&:hover': {
        // TODO: add '#F6F7FB' to theme.colors
        background: '#F6F7FB',
      },
    },
  },
}

export const options = {
  sameDay: 'h:mm A',
  // nextDay: 'ddd',
  // nextWeek: 'MMM D',
  lastDay: 'ddd',
  lastWeek: 'ddd',
  sameElse: 'MMM D'
}

const Unread = ({ nmessages }) => nmessages > 0
  ? <Card.Counter>{nmessages}</Card.Counter>
  : null

const Time = ({ lmessages }) => lmessages
  ? <Card.Time>{moment.unix(lmessages[1]).calendar(null, options)}</Card.Time>
  : null

const draftPic = require('./draft.svg')
const OrderDraft = ({ order, onClick, onDelete, active, className }) =>
  <Card swipe image='small' onClick={onClick} onDelete={onDelete} active={active} className={className}>
    <Card.Image src={draftPic} />
    <Card.Title>{order.title}</Card.Title>
    <Card.Text>{order.subject} • Due {moment(order.deadline).format('MMM D')}</Card.Text>
    <Card.Label>Draft</Card.Label>
    <Card.Link>Continue creating task</Card.Link>
  </Card>

const lookingPic = require('./process.svg')
const loader = require('./loader.svg')
const OrderLooking = ({ order, lmessages, nmessages, onClick, onDelete, active, className }) =>
  <Card image='small' onClick={onClick} onDelete={onDelete} active={active} className={className}>
    <Card.Image src={lookingPic} />
    <Card.Image src={loader} />
    <Card.Title>{order.title}</Card.Title>
    <Card.Text>{order.subject} • Due {moment(order.deadline).format('MMM D')}</Card.Text>
    <Time lmessages={lmessages} />
    <Card.Text bold>Looking for a tutor...</Card.Text>
    <Unread nmessages={nmessages} />
  </Card>

const OrderInwork = ({ order, presence, lmessages, nmessages, onClick, onDelete, active, className }) =>
  <Card image='small' onClick={onClick} onDelete={onDelete} active={active} className={className}>
    <Card.Avatar user={order.tutor} presence={presence} />
    <Card.Title>{order.title}</Card.Title>
    <Card.Text>{order.subject} • Due {moment(order.deadline).format('MMM D')}</Card.Text>
    <Time lmessages={lmessages} />
    {lmessages && lmessages[0] && <Card.Text bold sliced>{lmessages[0]}</Card.Text>}
    <Unread nmessages={nmessages} />
  </Card>

const OrderCompleted = ({ order, presence, lmessages, nmessages, onClick, onDelete, active, className }) =>
  <Card image='small' onClick={onClick} onDelete={onDelete} active={active} className={className}>
    <Card.Avatar user={order.tutor} presence={presence} />
    <Card.Title>{order.title}</Card.Title>
    <Card.Text>{order.subject} • Due {moment(order.deadline).format('MMM D')}</Card.Text>
    <Time lmessages={lmessages} />
    <Card.Text bold>Rate your experience</Card.Text>
    <Unread nmessages={nmessages} />
  </Card>

const OrderRated = ({ order, presence, lmessages, nmessages, onClick, onDelete, active, className }) =>
  <Card image='small' onClick={onClick} onDelete={onDelete} active={active} className={className}>
    <Card.Avatar user={order.tutor} presence={presence} />
    <Card.Title>{order.title}</Card.Title>
    <Card.Text>{order.subject} • Due {moment(order.deadline).format('MMM D')}</Card.Text>
    <Time lmessages={lmessages} />
    <Card.Rate value={order.rate.rate}>
      <Card.RateText value={order.rate.rate} />
    </Card.Rate>
    <Unread nmessages={nmessages} />
  </Card>

const refundPic = require('./refund.svg')
const OrderRefunded = ({ order, lmessages, nmessages, onClick, onDelete, active, className }) =>
  <Card image='small' onClick={onClick} onDelete={onDelete} active={active} className={className}>
    <Card.Image src={refundPic} />
    <Card.Title>{order.title}</Card.Title>
    <Card.Text>{order.subject} • Due {moment(order.deadline).format('MMM D')}</Card.Text>
    <Time lmessages={lmessages} />
    <Card.Status>refunded</Card.Status>
    <Unread nmessages={nmessages} />
  </Card>

const CMAP = {
  'draft': OrderDraft,
  'looking': OrderLooking,
  'inwork': OrderInwork,
  'completed': OrderCompleted,
  'accepted': OrderCompleted,
  'rated': OrderRated,
  'refunded': OrderRefunded,
}

const MAP_EVENT_MESSAGE = {
  'tutor_found': 'Tutor connected',
  'order_ready': 'Your request is ready!',
  'request_revision': 'Revision requested',
  'revision_ready': 'Your revision is ready!',
  'rate_order': 'Rate your experience',
  'extra_paid': 'Request updated',
}

const countNewNotifications = (notifications, orderId) => {
  const newOrderNotifications = notifications[orderId].nmessages
  // const newTimelineNotifications = notifications[getOrderLogNodeName(orderId)].nmessages
  // return newOrderNotifications + newTimelineNotifications
  return newOrderNotifications
}

const getLastMessage = (notifications, orderId, cid) => {

  if (!notifications[orderId].meta.lastItem) {
    return null
  }

  const { lastItem } = notifications[orderId].meta
  switch (true) {
    case lastItem.message !== undefined:
      return [`${cid === lastItem.from ? 'You: ' : ''}${lastItem.message}`, lastItem.time]
    case lastItem.event !== undefined:
      return [MAP_EVENT_MESSAGE[lastItem.event] || 'Unknown event', lastItem.time]
    case lastItem.files !== undefined:
      return [lastItem.files.length === 1
        ? `${cid === lastItem.from ? 'You: ' : ''}Sent 1 attachment`
        : `${cid === lastItem.from ? 'You: ' : ''}Sent ${lastItem.files.length} attachments`,
      lastItem.time]
    default:
      return ['Unknown event', lastItem.time]
  }
}

const getOrderCard = order => {
  return order.rate ? CMAP['rated'] : CMAP[order.status]
}

const Orders = ({
  classes,
  desktop,
  active,
}) => {
  const history = useHistory()
  const actions = useActions()
  const orders = useSelector(ordersSelector)
  const presence = useSelector(selectors.presenceSelector)
  const auth = useSelector(selectors.authSelector)
  const chatsNotifications = useSelector(selectors.notificationSelectors.chats)
  const logsNotifications = useSelector(selectors.notificationSelectors.logs)
  const notifications = merge({}, chatsNotifications, logsNotifications)

  const onSelect = order => {
    if (order.status === 'draft') {
      actions.onNewTask({ order, orderState: 'draft' })
    } else {
      history.push(`/${order.id}/chat`)
    }
  }
  const onDelete = order => actions.onPromptDeleteOrder(order.id)

  const groups = [
    ['Active', filters.orders.active(orders)],
    ['Completed', filters.orders.completed(orders)],
  ]
    .filter(group => group[1].length > 0)

  const empty = orders.length === 0

  return (
    <div className={classnames(classes.root, { desktop, empty })}>
      {empty
        ? <Empty />
        : <Fragment>
          {groups.map(([name, members], index) =>
            <Fragment key={name}>
              <Card.Divider>{name}</Card.Divider>
              {members.map(order =>
                React.createElement(getOrderCard(order), {
                  key: order.id,
                  order,
                  presence,
                  nmessages: countNewNotifications(notifications, order.id),
                  lmessages: getLastMessage(notifications, order.id, auth.cid),
                  onClick: () => onSelect(order),
                  onDelete: () => onDelete(order),
                  active: active === order.id,
                  className: classes.item,
                })
              )}
              {index < groups.length - 1 &&
                <Card.Divider>&nbsp;</Card.Divider>
              }
            </Fragment>
          )}
        </Fragment>
      }
    </div>
  )
}


export default injectSheet(styles)(Orders)