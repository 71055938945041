import React from 'react'
import ViewOrderDetails from './../components/ViewOrderDetails'
import { useOrder } from './../hooks'

export default ({
  orderId,
  onPromptBuyExtraHours,
  ...rest
}) => {
  const order = useOrder(orderId)
  return <ViewOrderDetails
    {...order}
    {...rest}
    onPromptBuyExtraHours={order.meta.availableBuyExtraHours ? () => onPromptBuyExtraHours(order) : undefined}
  />
}