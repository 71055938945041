import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectors } from '@verdaccio/mt-uikit'
import { ordersSelector, tutorsSelector } from './nerdytutors'
import { useLocation } from 'react-router-dom'
import { getUser, getAnyUserInfo, createSmstoolReminder } from './api'

export const useOrder = orderId =>
  useSelector(ordersSelector)
    .find(order => order.id === orderId)

export const useTutors = () => useSelector(tutorsSelector)

export const useTutor = tutorId => useTutors().find(tutor => tutor.id === tutorId)

export const useEvents = orderId => {
  const selector = selectors.createOrderLogSelector(orderId)
  const { items } = useSelector(selector)
  return items
}

export const usePresence = () => useSelector(selectors.presenceSelector)

export function useUserProfile() {
  const dispatch = useDispatch()
  const status = useSelector(selectors.xmppSelector)
  const auth = useSelector(selectors.authSelector)
  const { location } = useLocation()
  useEffect(() => {
    if (status === 'CONNECTED') {
      getUser(auth.xToken)
        .then(user => {
          dispatch({ type: 'PROFILE_UPDATE', payload: user.profile })
          dispatch({ type: 'SET_PRESENCE', payload: { status: 'favorite', cids: user.profile.favTutors } })
          dispatch({ type: 'SET_PRESENCE', payload: { status: 'block', cids: user.profile.blockTutors } })
        })
        .catch(_ => { dispatch({ type: 'DISCONNECT' }) })
    }
  }, [status, location])
}

export function useSmstoolRemider(remider) {
  const { cid } = useSelector(selectors.authSelector)
  useEffect(() => {
    const t = setTimeout(() => {
      createSmstoolReminder(cid, remider)
    }, 7000)
    return () => clearTimeout(t)
  }, [remider])
}

const USE_API_CALLS = {
  getAnyUserInfo
}

export function useApi(apiCallName) {
  const auth = useSelector(selectors.authSelector)
  return USE_API_CALLS[apiCallName](auth.xToken)
}
