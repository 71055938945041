import React from 'react'
import { Report } from '@verdaccio/mt-uikit'
import { REPORT } from '../../config'
import { useTutor } from '../../hooks'

const ReportTutor = ({
  tutorId,
  onSubmit,
  ...otherProps
}) => {
  const tutor = useTutor(tutorId)
  return (
    <Report
      report={REPORT}
      onSubmit={report => onSubmit(report, tutor)}
      {...otherProps}
    />
  )
}

export default ReportTutor