import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { modals, reducers, getXmppConnectMiddleware, audioNotification } from '@verdaccio/mt-uikit'
import { XMPP_CONFIG, S3 } from './config'
import { onXmppConnect } from './nerdytutors'
import { save, load } from 'redux-localstorage-simple'

const composeEnhancers =
  process.env.ENV_NAME === undefined &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const configReducer = (state = { s3config: S3 }) => state

const reducer = combineReducers({
  config: configReducer,
  nodes: reducers.nodes,
  xmpp: reducers.xmpp,
  auth: reducers.auth,
  presence: reducers.presence,
  modals: modals.reducer,
})

const getCidFromItem = item => item.tutor && item.tutor.id

const xmppConnectMiddleware = getXmppConnectMiddleware(XMPP_CONFIG, {
  onConnect: onXmppConnect,
  onNewMessage: audioNotification,
  getCidFromItem
})

const storeSaveConf = {
  states: ['auth', 'nodes', 'presence'],
  namespace: 'xmpp',
  debounce: 500,
}

const store = createStore(
  reducer,
  load(storeSaveConf),
  composeEnhancers(applyMiddleware(
    xmppConnectMiddleware,
    save(storeSaveConf)
  ))
)

export default store
