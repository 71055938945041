import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const getPositionStyle = {
  'right': (triggerRect, popupRect, offsetX, offsetY) => ({
    top: window.scrollY + triggerRect.top + offsetY,
    left: window.scrollX + triggerRect.left + triggerRect.width + offsetX
  }),
  'bottom': (triggerRect, popupRect, offsetX, offsetY) => ({
    top: window.scrollY + triggerRect.top + triggerRect.height + offsetY,
    left: window.scrollX + triggerRect.width + triggerRect.left - popupRect.width + offsetX
  }),
}

const setPosition = (nodeRef, triggerRef, popupRef, layout, offsetX, offsetY) => {
  if (triggerRef.current && popupRef.current) {
    const { top, left } = getPositionStyle[layout](
      triggerRef.current.getBoundingClientRect(),
      popupRef.current.getBoundingClientRect(),
      offsetX,
      offsetY
    )
    nodeRef.current.setAttribute('style', `position: absolute; top: ${top}px; left: ${left}px; z-index: 999999;`)
  }
}

const PopupWrapper = ({
  trigger,
  content,
  layout,
  offsetX = 0,
  offsetY = 0
}) => {
  const nodeRef = useRef(document.createElement('div'))
  const triggerRef = useRef()
  const popupRef = useRef()

  useEffect(() => {
    document.body.insertBefore(nodeRef.current, document.getElementById('root'))
    window.addEventListener('resize', () => setPosition(nodeRef, triggerRef, popupRef, layout, offsetX, offsetY))
    window.addEventListener('scroll', () => setPosition(nodeRef, triggerRef, popupRef, layout, offsetX, offsetY))
    setPosition(nodeRef, triggerRef, popupRef, layout, offsetX, offsetY)
    return () => { document.body.removeChild(nodeRef.current) }
  }, [content, layout])

  return <>
    {React.cloneElement(trigger, { innnerRef: node => { triggerRef.current = node } })}
    {content && createPortal(React.cloneElement(content, { innnerRef: node => { popupRef.current = node } }), nodeRef.current)}
  </>
}

export default PopupWrapper

