import getTracker from '@verdaccio/lrtrack-js'
import { useEffect } from 'react'

const tracker = getTracker({
  trackerId: ENV.LR_TRACK_ID,
  baseUrl: ENV.LR_TRACK_URL,
})

const PAYLOAD_VERSION = 'v1'

export const useTrackValues = (value, name, { context = null, predicat = null }) => {
  useEffect(() => {
    let t
    if (predicat && predicat(value) || !predicat) {
      t = setTimeout(() => {
        tracker.track('trackValues', {
          location: window.location.href,
          v: PAYLOAD_VERSION,
          _context: (context || {}),
          _values: {
            [name]: value
          }
        })
      }, 1000)
    }
    return () => clearTimeout(t)
  }, [value])
}

export const useTrackClicks = (onClick, name, { context = null, predicat = null }) => () => {
  if (predicat && predicat(value) || !predicat) {
    tracker.track('trackClicks', {
      location: window.location.href,
      v: PAYLOAD_VERSION,
      _context: (context || {}),
      _values: {
        target: name
      }
    })
  }

  onClick()
}

export default tracker
