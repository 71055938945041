import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop, MODAL_WIDTH_DESKTOP } from './../../theme/base'
import { Layout, Attachment } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  content: {
    marginTop: rem(10),
    bottom: '0 !important',
    overflowY: 'hidden',
    [desktop]: {
      marginTop: 0,
    },
  },
  title: {
    padding: [rem(22), rem(56)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: rem(0.11),
    textAlign: 'center',
  },
  details: {
    maxHeight: `calc(100% - ${rem(150)})`,
    padding: [rem(24), rem(16), rem(8)],
    overflowY: 'auto',
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(17),
    lineHeight: rem(24),
    letterSpacing: rem(0.1),
    color: theme.colors.charcoalGrey,
    whiteSpace: 'pre-wrap',
  },
  attachments: {
    marginTop: rem(24),
    padding: [0, rem(16), rem(10)],
  }
}

const ViewDetails = ({
  details,
  files,
  header,
  onClose,
  classes,
}) =>
  <div className={classes.root}>
    <Layout.Header onClose={onClose}>
      <div className={classes.title}>{header}</div>
    </Layout.Header>
    <Layout.Content className={classes.content}>
      <div className={classes.details}>{details}</div>
      <div className={classes.attachments}>
        {files.map(file =>
          <Attachment
            key={file.name}
            {...file}
          />
        )}
      </div>
    </Layout.Content>
  </div>

export default injectSheet(styles)(ViewDetails)
