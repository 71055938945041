import React, { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem, content, desktop } from './../../theme/base'
import { Button, InputPhone, selectors } from '@verdaccio/mt-uikit'

import { useActions } from './../../actions'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DesktopWrapperHOC2 from './../../components/DesktopWrapperHOC2'
import { useUserProfile } from './../../hooks'

const styles = {
  root: {
    background: theme.colors.white,
    ...content,
    paddingTop: rem(20),
    paddingBottom: rem(20),
    borderRadius: rem(16),
    position: 'relative',
    [desktop]: {
      transform: `translateY(${rem(-44)})`,
    },
    '& input': {
      height: rem(56),
      paddingRight: rem(48),
      paddingTop: rem(15),
      paddingBottom: rem(15),
      // TODO: add '#BAC7E2' to theme.colors
      border: [rem(1), 'solid', '#BAC7E2'],
      borderRadius: rem(28),
      textAlign: 'center',
      fontSize: rem(20),
      lineHeight: rem(24),
      color: theme.colors.blueyGrey,
      letterSpacing: rem(0.12),
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
      fontWeight: 'normal',
      color: theme.colors.charcoalGrey,
      boxShadow: 'none',
      '&.empty': {
        paddingRight: rem(24),
      },
    },
  },
  wrapper: {
    ...content,
    maxWidth: rem(375),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: rem(8),
    paddingRight: rem(8),
  },
  form: {
    marginTop: rem(24),
  },
  image: {
    width: '100%',
    maxWidth: rem(230),
    height: 'auto',
    margin: [0, 'auto'],
    display: 'block',
  },
  title: {
    fontSize: rem(22),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
    textAlign: 'center',
  },
  text: {
    minHeight: rem(60),
    marginTop: rem(16),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGreyTwo,
    letterSpacing: rem(0.09),
    textAlign: 'center',
    '& span': {
      color: theme.colors.blue,
    },
    '&.error': {
      color: theme.colors.brightOrange,
      cursor: 'pointer'
    }
  },
  button: {
    marginTop: rem(16),
  },
  field: {
    position: 'relative',
  },
  clear: {
    width: rem(24),
    height: rem(24),
    padding: rem(4),
    position: 'absolute',
    right: rem(16),
    top: rem(16),
    cursor: 'pointer',
    '& svg': {
      width: rem(16),
      height: rem(16),
      display: 'block',
    },
  },
}

const AskPhonePage = ({ classes }) => {
  const actions = useActions()
  const history = useHistory()
  const { profile } = useSelector(selectors.authSelector)

  const [phone, setPhone] = useState({ value: profile.phone, valid: false })
  const [error, setError] = useState()

  useUserProfile()

  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.white

    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  const onSubmit = () => {
    if (phone.valid) {
      actions.onUpdateProfile({ ...profile, phone: phone.value })
        .then(() => {
          history.push('/orders')
        })
        .catch(err => setError(err.details))
    }
  }

  return (
    <div className={classes.root}>
      <img className={classes.image} src={require('./ask-phone-icon.svg')} alt='' />
      <div className={classes.wrapper}>
        <div className={classes.title}>Please, leave your phone number</div>
        {!error
          ? <div className={classes.text}>We need it to text you when the tutor is found, send progress updates and notify about new messages. It's free.</div>
          : <div
            className={classnames(classes.text, 'error')}
            onClick={() => actions.onSupport()}
          >
            This phone number is already in use. If you have some problems, please contact our<br />
            <span>Support team</span>
          </div>
        }
      </div>
      <div className={classes.form}>
        <div className={classes.field}>
          <InputPhone
            id='phone'
            name='phone'
            country='us'
            placeholder='+1'
            inputProps={{
              autoFocus: true,
            }}
            value={phone.value}
            onChange={value => setPhone(value)}
          />
        </div>
        <div className={classes.button}>
          <Button
            type='primary'
            size='large'
            disabled={!phone.valid}
            onClick={onSubmit}
            block
          >
            Add phone number
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DesktopWrapperHOC2(injectSheet(styles)(AskPhonePage))