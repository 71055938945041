import React from 'react'
import classnames from 'classnames'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'

const styles = {
  root: {
    width: '17.1vw',
    height: '17.1vw',
    maxWidth: rem(64),
    maxHeight: rem(64),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '13%',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      background: `url(${require('./btn-next.svg')})`,
      backgroundSize: 'cover',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 1,
      transition: 'opacity 500ms ease-in-out',
      zIndex: 1,
    },
    '&:after': {
      content: '""',
      background: `url(${require('./btn-refresh.svg')})`,
      backgroundSize: 'cover',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0,
      transition: 'opacity 500ms ease-in-out',
      zIndex: 1,
    },
    '&.lastSlide': {
      '&:before': {
        opacity: 0,
      },
      '&:after': {
        opacity: 1,
      },
    },
    '& .CircularProgressbar': {
      width: '13.9vw',
      height: '13.9vw',
      maxWidth: rem(52),
      maxHeight: rem(52),
      marginTop: rem(2),
      verticalAlign: 'middle',
      pointerEvents: 'none',
      position: 'relative',
      zIndex: 2,
      '& .CircularProgressbar-path': {
        stroke: theme.colors.blue,
        strokeLinecap: 'round',
        transition: 'stroke-dashoffset 500ms ease',
      },
      '& .CircularProgressbar-trail': {
        stroke: '#d6d6d6',
        strokeLinecap: 'round',
      },
      '& .CircularProgressbar-text': {
        fill: theme.colors.blue,
        fontSize: rem(20),
        dominantBaseline: 'middle',
        textAnchor: 'middle',
      },
      '& .CircularProgressbar-background': {
        fill: '#d6d6d6',
      },
    },
  },
}

const ProgressBar = ({ 
  classes,
  speed,
  easing,
  currentSlide, 
  slideCount, 
  onClick,
}) => {
  const formattedCurrentSlide = currentSlide + 1
  const percent = formattedCurrentSlide / slideCount * 100
  const lastSlide = formattedCurrentSlide === slideCount

  return (
    <div className={classnames(classes.root, { lastSlide })} onClick={onClick}>
      <CircularProgressbar
        value={percent}
        // text={`${percent}%`}
        strokeWidth={6}
        styles={buildStyles({
          trailColor: 'transparent',
          pathTransition:
            percent === 0 ? 'none' : `stroke-dashoffset ${speed}ms ${easing}`
        })}
      />
    </div>
  )
}

export default injectSheet(styles)(ProgressBar)