import React from 'react'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import theme, { rem, content, media, desktop, adaptive, centered, MODAL_WIDTH_DESKTOP } from './../../theme/base'

const styles = {
  root: {
    [desktop]: {
      background: theme.colors.blue,
      width: '100%',
      minHeight: '100vh',
    },
  },
  wrapper: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
      marginTop: rem(88),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  content: {
    ...content,
    [adaptive]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [desktop]: {
      width: rem(media.desktop),
      minHeight: 'inherit',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  centered: {
    [desktop]: {
      ...centered,
      minHeight: 'inherit',
    }
  },
  logo: {
    display: 'none',
    [desktop]: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: rem(16),
      textAlign: 'center',
      display: 'block',
    },
    '& a': {
      width: rem(160),
      height: rem(28),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const DesktopWrapperHOC2 = (Component) => injectSheet(styles)(
  ({
    classes,
    ...props
  }) =>
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <Link to='/'>
            <img src={require('./logo.svg')} alt='' />
          </Link>
        </div>
        <div className={classes.centered}>
          <div className={classes.wrapper}>
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
)

export default DesktopWrapperHOC2