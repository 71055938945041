import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { rem } from './../../../../theme/base'
import { Custom } from '../index'
import { isMobile } from './../../../../utils'

const styles = {
  root: {
    marginTop: rem(16),
    position: 'relative',
  },
  list: {
    width: `calc(100% + ${rem(48)})`,
    marginLeft: rem(-24),
    paddingBottom: rem(8),
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: isMobile ? 'nowrap' : 'normal',
    position: 'relative',
    overflowX: 'auto',
    '&.custom': {
      overflowX: 'visible',
    },
  },
}

const Presets = ({
  classes,
  type,
  custom,
  customText,
  children,
  onCustom,
}) =>
  <div className={classes.root}>
    <div className={classnames(classes.list, { custom })}>{children}</div>
    {!custom &&
      <Custom
        type={type}
        customText={customText}
        onClick={onCustom}
      />
    }
  </div>

export default injectSheet(styles)(Presets)