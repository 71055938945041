

import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { v4 as uuidv4 } from 'uuid'
import { useLocation, useHistory } from 'react-router-dom'
import { Input, Button } from '@verdaccio/mt-uikit'
import { SlideUpDropDownAnimation } from '../../../components/Animation'
import SubjectsList from '../../../components/SubjectsList'
import { MAX_TITLE_LENGTH, SUBJECTS, TOP_SUBJECTS } from '../../../config'
import theme, { rem, adaptive } from '../../theme/base'
//import { ORDER } from '../../../nerdytutors'
import { useTrackValues } from '../../../lrtracker'

const styles = {
  form: {
    background: theme.colors.white,
    width: rem(360),
    margin: [rem(32), 'auto', 0],
    paddingBottom: rem(16),
    borderRadius: rem(24),
    boxShadow: `0 ${rem(16)} ${rem(40)} 0 rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)`,
    [adaptive]: {
      width: '100%',
      boxShadow: 'none',
    },
    '& button': {
      backgroundImage: `url(${require('./discount.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: [rem(52), rem(28)],
      backgroundPosition: `calc(100% - ${rem(16)}) center`,
      borderRadius: rem(16),
    },
  },
  submit: {
    paddingLeft: rem(16),
    paddingRight: rem(16),
  },
}

const ALL_SUBJECTS = SUBJECTS.concat(TOP_SUBJECTS)

const getLocationOrder = (
  location,
  defaultOrder = {
    title: '',
    subject: '',
    toid: uuidv4()
  }
) => (
  location.state &&
  location.state.from &&
  location.state.from.state &&
  location.state.from.state.order &&
  location.state.from.state.order
  || { ...defaultOrder }
)

const MiniOrderForm = ({
  classes,
  className,
  buttonText = 'Get a tutor',
  placeholders,
}) => {
  const location = useLocation()

  const toid = useState(getLocationOrder(location).toid)[0]
  const [title, setTitle] = useState(getLocationOrder(location).title)
  const [subject, setSubject] = useState(getLocationOrder(location).subject)
  const [submittedForm, setSubmittedForm] = useState(false)
  const [showSubjects, setShowSubjects] = useState(false)

  const validTitle = !!title.trim().length > 0
  const validSubject = !!subject.trim() && ALL_SUBJECTS.indexOf(subject) > -1
  const validForm = validTitle && validSubject

  const history = useHistory()
/*  const onSubmit = () => {
    if (validForm) {
      history.push({
        pathname: '/order/new', state: {
          order: {
            toid,
            title,
            subject,
          },
          stage: ORDER.STAGES.NEW.DETAILS
        }
      })
    }
  }*/

  const trackValuesContenxt = { toid, stage: 'landing' }
  useTrackValues(title, 'title', { context: trackValuesContenxt, predicat: value => value.length > 0 })
  useTrackValues(subject, 'subject', { context: trackValuesContenxt, predicat: value => value.length > 0 })

  return (
    <div className={classnames(classes.form, className)}>
      <Input
        placeholder={placeholders[0]}
        value={title}
        onChange={value => setTitle(value)}
        autoFocus
        status={`${title.length}/${MAX_TITLE_LENGTH}`}
        maxLength={MAX_TITLE_LENGTH}
        error={submittedForm && !validTitle}
      />
      <Input
        placeholder={placeholders[1]}
        value={subject}
        onChange={value => setSubject(value)}
        onFocus={() => setShowSubjects(true)}
        onClickOutside={() => setShowSubjects(false)}
        icon='search'
        error={submittedForm && !validSubject}
      >
        {showSubjects && (
          <SlideUpDropDownAnimation>
            <SubjectsList
              search={subject}
              onClick={value => {
                setSubject(value)
                setShowSubjects(false)
              }}
            />
          </SlideUpDropDownAnimation>
        )}
      </Input>
      <div className={classes.submit}>
        <Button
          type='primary'
          size='large'
          block
          disabled={!validForm && submittedForm}
          // onClick={() => validForm && onSubmit() || setSubmittedForm(true)}
          onClick={() => window.location.href = 'https://nerdy-tutors.com/signup'}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default injectSheet(styles)(MiniOrderForm)
