import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(24),
    fontFamily: theme.fontFamilies.SFProRoundedBlack,
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 'bold',
    letterSpacing: rem(0.11),
    textAlign: 'center',
    color: theme.colors.charcoalGrey,
    '&:first-child': {
      marginTop: 0,
    },
  },
}

const Title = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>
        

export default injectSheet(styles)(Title)