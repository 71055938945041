import React from 'react'
import { Prompt } from '@verdaccio/mt-uikit'
import { useTutor } from '../../hooks'

const BlockTutor = ({
  tutorId,
  onCancel,
  onClose,
  onAccept,
  ...otherProps
}) => {
  const tutor = useTutor(tutorId)
  const _onAccept = () => { onAccept(tutor); onClose() }
  const _onClose = () => { onCancel && onCancel(); onClose() }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='block' />
      <Prompt.Title>Block Tutor</Prompt.Title>
      <Prompt.Details details={[
        'If you block this tutor she will not be allowed to accept any of your future requests.',
        'Are you sure you want to block the tutor.',
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='alert' onClick={_onAccept}>Block</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default BlockTutor