import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'
import { utils } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    // TODO: add '#ebeffa' to theme.colors
    background: '#ebeffa',
    minHeight: rem(64),
    marginTop: rem(12),
    padding: [rem(8), rem(80)],
    borderRadius: rem(20),
    textAlign: 'center',
    position: 'relative',
    '&:before, &:after': {
      content: "''",
      display: 'block',
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: [rem(64), rem(64)],
      width: rem(64),
      height: rem(64),
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&:before': {
      backgroundImage: `url(${require(`./bonus-left.svg`)})`,
      left: rem(8),
    },
    '&:after': {
      backgroundImage: `url(${require(`./bonus-right.svg`)})`,
      right: rem(8),
    },
  },
  title: {
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(13),
    lineHeight: rem(20),
    // TODO: add '#697896' to theme.colors
    color: '#697896',
  },
  value: {
    marginTop: rem(2),
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontSize: rem(21),
    lineHeight: rem(28),
    // TODO: add '#697896' to theme.colors
    // color: '#697896',
    color: theme.colors.charcoalGrey,
  },
}

const Bonus = ({ classes, balance }) => 
  <div className={classes.root}>
    <div className={classes.title}>Your bonus balance:</div>
    <div className={classes.value}>{utils.printAmount(balance)}</div>
    {/* <div className={classes.title}>Time limited 11d</div> */}
  </div>

export default injectSheet(styles)(Bonus)