import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(12),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    textAlign: 'center',
    '& span': {
      fontWeight: 600,
    }
  },
}

const Subtitle = ({
  classes,
  children,
}) => <div className={classes.root} dangerouslySetInnerHTML={{ __html: children }} />

export default injectSheet(styles)(Subtitle)