import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, desktop, desktop1280 } from './../../theme/base'
import { Icon, utils } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    display: 'none',
    [desktop]: {
      height: rem(56),
      padding: [rem(8), rem(16)],
      // TODO: add 'rgba(186, 199, 226, 0.5)' to theme.colors
      borderBottom: [rem(1), 'solid', 'rgba(186, 199, 226, 0.5)'],
      position: 'fixed',
      top: rem(56),
      left: rem(360),
      right: 0,
      zIndex: 3,
      display: 'block',
    },
  },
  tabs: {
    background: theme.colors.paleGrey,
    width: rem(208),
    height: rem(40),
    marginRight: rem(24),
    borderRadius: rem(20),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  item: {
    width: '50%',
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& div': {
      background: theme.colors.paleGrey,
      width: '100%',
      color: theme.colors.charcoalGrey,
      fontSize: rem(17),
      fontFamily: theme.fontFamilies.SFProRoundedSemibold,
      lineHeight: rem(22),
      padding: [rem(11), 0, rem(7)],
      transition: 'background 300ms ease-in-out, color 80ms ease-in-out',
      borderRadius: rem(20),
      display: 'block',
      position: 'relative',
      cursor: 'pointer',
    },
    '&.active': {
      '& div': {
        background: theme.colors.blue,
        color: theme.colors.white,
        cursor: 'default',
      },
    },
    '&.updated': {
      '& div': {
        '&:before': {
          content: "''",
          background: theme.colors.blue,
          width: rem(6),
          height: rem(6),
          borderRadius: '50%',
          position: 'absolute',
          left: rem(10),
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
    },
  },
  more: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    right: rem(16),
    top: rem(14),
    cursor: 'pointer',
    '& svg': {
      fill: theme.colors.paleGrey,
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&:hover': {
        // TODO: add '#BDD0FC' to theme.colors
        fill: '#BDD0FC',
      },
    },
  },
  deadline: {
    marginTop: rem(12),
    display: 'inline-block',
    verticalAlign: 'top',
    '& svg': {
      width: rem(16),
      height: rem(16),
      marginRight: rem(4),
      marginTop: rem(1),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  number: {
    marginTop: rem(12),
    marginLeft: rem(24),
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    display: 'none',
    [desktop1280]: {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  action: {
    fontSize: rem(15),
    lineHeight: rem(20),
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontWeight: 600,
    color: theme.colors.blue,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'absolute',
    top: rem(20),
    right: rem(60),
  },
  text: {
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGreyTwo,
    display: 'inline-block',
    verticalAlign: 'top',
  },
}

const ChatNavbarDesktop = ({
  order,
  tabs,
  onMore,
  onOrderDetails,
  classes,
}) => {
  return (
    <Fragment>
      <div className={classnames(classes.root)}>
        <div className={classes.tabs}>
          {tabs.map(({ name, key, active, notify, onClick }) =>
            <div
              className={classnames(classes.item, {
                'active': active,
                'updated': !active && notify,
              })}
              key={key}
            >
              <div onClick={active ? null : onClick}>{name}</div>
            </div>
          )}
        </div>
        <div className={classes.deadline}>
          <Icon iconName='timer' />
          <div className={classes.text}>
            <strong>{utils.sizeToHumans(order.size)}</strong> · Due {utils.printDateTime(order.deadline)}
          </div>
        </div>
        <div className={classes.number}>#{order.number}</div>
        <div className={classes.action} onClick={onOrderDetails}>Show details</div>
        <div className={classes.more} onClick={onMore}>
          <Icon iconName='more-round' />
        </div>
      </div>
    </Fragment>
  )
}

export default withRouter(injectSheet(styles)(ChatNavbarDesktop))