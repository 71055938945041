import React from 'react'
import injectSheet from 'react-jss'
import { createChat, Layout, selectors } from '@verdaccio/mt-uikit'
import theme, { rem, desktop, MODAL_WIDTH_DESKTOP } from './../../theme/base'
import { useSelector, useDispatch } from 'react-redux'
import { useActions } from './../../actions'
import Empty from './Empty'

const styles = {
  root: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  title: {
    padding: [rem(17), rem(56)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: rem(0.11),
    textAlign: 'center',
  },
  header: {
    // TODO: add '#F6F7FB' to theme.colors
    backgroundColor: '#F6F7FB',
  },
  container: {
    // TODO: add '#F6F7FB' to theme.colors
    backgroundColor: '#F6F7FB',
  },
}

const SupportChat_ = ({ classes, onClose, children }) =>
  <div className={classes.root}>
    <Layout.Header className={classes.header} onClose={onClose}>
      <div className={classes.title}>Support chat</div>
    </Layout.Header>
    <Layout.Container className={classes.container}>
      {children}
    </Layout.Container>
  </div>

const SupportChat = createChat(injectSheet(styles)(SupportChat_), { useActions, useSelector, useDispatch })

export default props => {
  const { supportNode } = useSelector(selectors.authSelector)
  const { nitems } = useSelector(selectors.nodesSelector)[supportNode]
  const defaultScreen = nitems === 0 ? <Empty /> : null
  return (
    <SupportChat
      node={supportNode}
      defaultScreen={defaultScreen}
      {...props}
    />
  )
}
