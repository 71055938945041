import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../../theme/base'

const styles = {
  root: {
    width: rem(40),
    height: rem(40),
    padding: [rem(11), 0, rem(9)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(13),
    lineHeight: rem(20),
    textAlign: 'center',
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '&.active': {
      background: theme.colors.blue,
      color: theme.colors.white,
      '&:hover': {
        background: theme.colors.blue,
      },
    },
    '&.disabled': {
      background: 'none',
      color: theme.colors.blueyGrey,
      opacity: 0.5,
      cursor: 'default',
      '&:hover': {
        background: 'none',
      },
    },
    '&:hover':{
      // TODO: add '#D6E3FF' to theme.colors
      background: '#D6E3FF',
    },
  },
}

const Item = ({ 
  classes,
  value,
  active,
  disabled,
  onClick,
}) => 
  <div className={classnames(classes.root, { active, disabled })} onClick={() => !disabled && onClick()}>
    {value}
  </div>

export default injectSheet(styles)(Item)