import React, { Fragment } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useActions } from './../../actions'
import { useSelector } from 'react-redux'
import { tutorsSelector } from './../../nerdytutors'
import theme from './../../theme/base'
import Header from './../Header'
import Footer from './../../components/Footer'
import Tutors from './../../components/Tutors'
import Empty from './Empty'
import { selectors } from '@verdaccio/mt-uikit'
import { useTutors, useUserProfile } from './../../hooks'

const styles = {
  empty: {
    background: theme.colors.paleGrey,
    borderRadius: 0,
  },
}

const TutorsPage = ({ classes }) => {
  const actions = useActions()
  const tutors = useTutors()
  const presence = useSelector(selectors.presenceSelector)
  const tutorsLength = tutors && tutors.length > 0
  useUserProfile()
  return (
    <Fragment>
      <Header className={classnames({ [classes.empty]: !tutorsLength })} active='tutors' />
      {tutorsLength
        ? <Tutors
          tutors={tutors}
          presence={presence}
          onSelect={tutorId => actions.onTutorProfile(tutorId)}
          onRequest={tutorId => actions.onTutorContext(tutorId)}
        />
        : <Empty />
      }
      <Footer active='tutors' />
    </Fragment>
  )
}

export default injectSheet(styles)(TutorsPage)