import React from 'react'
import { Prompt } from '@verdaccio/mt-uikit'
import { useOrder } from '../../hooks'

const DeleteOrder = ({
  orderId,
  onCancel,
  onClose,
  onAccept,
  ...otherProps
}) => {
  const order = useOrder(orderId)
  const _onAccept = () => { onAccept(order); onClose() }
  const _onClose = () => { onCancel && onCancel(); onClose() }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='delete' />
      <Prompt.Title>Delete task</Prompt.Title>
      <Prompt.Details details={[
        `Are you sure you want to delete <br/> <strong>${order.title}</strong>?`
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='alert' onClick={_onAccept}>Delete</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default DeleteOrder