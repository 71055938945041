import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'
import { Input, InputPhone, Icon } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    marginTop: rem(12),
    paddingRight: rem(24),
    paddingBottom: rem(7),
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
    '&.name': {
      width: `calc(100% - ${rem(83)})`,
      marginLeft: rem(19),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '&.phone': {
      marginTop: rem(24),
    },
    '& > svg': {
      width: rem(16),
      height: rem(16),
      position: 'absolute',
      right: 0,
      top: rem(22),
      fill: theme.colors.blueyGrey,
    },
    '& input, input.form-control[disabled]': {
      padding: 0,
      fontSize: rem(17),
      lineHeight: rem(28),
      '&:-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&:-ms-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-text-fill-color': {
        color: theme.colors.blueyGrey,
      },
    },
  },
  input: {
    '&:disabled': {
      '-webkit-text-fill-color': theme.colors.charcoalGrey,
      opacity: 1,   
    },
  },
  clear: {
    width: rem(16),
    height: rem(16),
    position: 'absolute',
    right: 0,
    top: rem(22),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  label: {
    fontSize: rem(13),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
    opacity: 0,
    '&.show': {
      opacity: 1,
    },
  },
  avatar: {
    background: theme.colors.lightBlueGrey50,
    width: rem(64),
    height: rem(64),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '& .icon': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      bottom: rem(-6),
      right: 0,
      cursor: 'pointer',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const Field = ({
  classes,
  name,
  value,
  type,
  placeholder,
  disabled,
  onChange,
  onClear,
  onScroll,
}) => {
  const [focus, setFocus] = useState(false)

  const onFocus = () => {
    setFocus(true)
    onScroll && onScroll()
  }

  // TO DO: сделать обработчик blur так, чтобы сначала срабатывал click
  const onBlur = () => setTimeout(() => setFocus(false), 150)

  const inputProps = {
    id: name,
    type,
    name,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    disabled,
  }

  return (
    <div className={classnames(classes.root, name)}>
      <label className={classnames(classes.label, { 'show': value })} htmlFor={name}>
        {placeholder}
      </label>
      {type === 'tel'
        ? <InputPhone {...inputProps} />
        : <Input {...inputProps} className={classes.input} />
      }
      {value && !disabled && focus &&
        <div className={classes.clear} onClick={onClear}>
          <Icon iconName='close-gray' />
        </div>
      }
      {disabled &&
        <Icon iconName='lock2' />
      }
    </div>
  )
}

export default injectSheet(styles)(Field)