import BlockTutor from './BlockTutor'
import BuyExtraHours from './BuyExtraHours'
import CloseAndCreateDraft from './CloseAndCreateDraft'
import CloseAndUpdateDraft from './CloseAndUpdateDraft'
import DeleteOrder from './DeleteOrder'
// import DeletePaymentMethod from './DeletePaymentMethod'
import DraftSaved from './DraftSaved'
import OtherPhoneNumber from './OtherPhoneNumber'
import RejectTutor from './RejectTutor'
import ReportTutor from './ReportTutor'
import TaskDeleted from './TaskDeleted'

export default {
  BlockTutor,
  BuyExtraHours,
  CloseAndCreateDraft,
  CloseAndUpdateDraft,
  DeleteOrder,
  // DeletePaymentMethod,
  DraftSaved,
  OtherPhoneNumber,
  RejectTutor,
  ReportTutor,
  TaskDeleted,
}