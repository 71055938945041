
import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

import Icon from './../layout/Icon'
import Title from './../layout/Title'
import styles from './styles'

export default injectSheet(styles)(({
  className,
  title,
  children,
  classes,
  onBack,
  onClose
}) => <div className={classes.flex}>
    <div className={classes.root}>
      <div className={classes.header}>
        <Title>{title}</Title>
        {onBack
          ? <Icon.Back onClick={onBack} />
          : <Icon.Close onClick={onClose} />
        }
      </div>
      <div className={classes.wrapper}>
        <div className={classnames(classes.content, className)}>
          {children}
        </div>
      </div>
    </div>
  </div>
)