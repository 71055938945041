export default {
  white: '#ffffff',
  white50: 'rgba(255, 255, 255, 0.5)',
  black: '#000000',
  black60: 'rgba(0, 0, 0, 0.6)',
  blue: '#3c78ff',
  blue50: 'rgba(60, 120, 255, 0.5)',
  mediumBlue: '#3060cc',
  lightBlueGrey: '#bcc7e0',
  lightBlueGrey50: 'rgba(188, 199, 224, 0.5)',
  // TODO: replace all 'blueyGrey' to 'blueGrey'
  blueyGrey: '#939fb8',
  charcoalGrey: '#2f333d',  
  transparent: 'transparent',
  slateGrey10: 'rgba(86, 92, 107, 0.1)',
  brightOrange: '#fa6400',
  paleGrey: '#ebeef5',
  slateGrey: '#565d6b',
  slateGreyTwo: '#5d667a',
  brightOrangeTwo: '#ff5700',
  lightPeriwinkle: '#c4d6ff',
  online: '#7ed321',
  star: '#f5a623',
  vermillion: '#e02020',
  vermillionTwo: '#a31717',
}
