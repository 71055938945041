import React from 'react'
import injectSheet from 'react-jss'
import { Icon, utils } from '@verdaccio/mt-uikit'
import theme, { rem } from '../../theme/base'
import Title from './Title'
import Subtitle from './Subtitle'
import { usePresence } from './../../hooks'


const styles = {
  root: {
    background: theme.colors.white,
    height: rem(54),
    padding: [rem(6), rem(16), rem(6), rem(56)],
    position: 'relative',
    cursor: 'pointer',
    transition: 'background 300ms ease',
    '&:hover': {
      background: theme.colors.lightBlueGrey50,
    }
  },
  avatar: {
    width: rem(32),
    height: rem(32),
    borderRadius: '50%',
    display: 'block',
    position: 'absolute',
    left: rem(16),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  favorite: {
    width: rem(16),
    height: rem(17),
    display: 'block',
    position: 'absolute',
    right: rem(16),
    top: '50%',
    transform: 'translateY(-50%)',
  },
}

const Item = ({
  classes,
  id,
  name,
  subtitle,
  onClick,
}) => {
  const { favorite } = usePresence()
  return (
    <div className={classes.root} onClick={onClick}>
      <img className={classes.avatar} src={utils.getUserAvatarUrl(id)} />
      <Title>{name}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {favorite.indexOf(id) > -1 &&
        <Icon className={classes.favorite} iconName='favorite' />
      }
    </div>
  )
}

export default injectSheet(styles)(Item)