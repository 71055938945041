import React from 'react'
import injectSheet from 'react-jss'
import { Input } from '@verdaccio/mt-uikit'
import theme, { rem } from '../../../theme/base'

const styles = {
  root: {
    '& + &': {
      marginTop: `${rem(8)} !important`,
      borderTop: 'none !important',
    },
    '& input': {
      paddingTop: rem(15),
      paddingBottom: rem(15),
      // TODO: add '#BAC7E2' to theme.colors
      border: [rem(1), 'solid', '#BAC7E2'],
      borderRadius: rem(28),
      textAlign: 'center',
      '&:-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-moz-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&:-ms-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-input-placeholder': {
        color: theme.colors.blueyGrey,
      },
      '&::-webkit-text-fill-color': {
        color: theme.colors.blueyGrey,
      },
      '&.error': {
        borderColor: theme.colors.brightOrange,
        color: 'inherit',
        '&:-moz-placeholder': {
          color: theme.colors.blueyGrey,
        },
        '&::-moz-placeholder': {
          color: theme.colors.blueyGrey,
        },
        '&:-ms-input-placeholder': {
          color: theme.colors.blueyGrey,
        },
        '&::-webkit-input-placeholder': {
          color: theme.colors.blueyGrey,
        },
        '&::-webkit-text-fill-color': {
          color: theme.colors.blueyGrey,
        },
      },
    },
  },
}

const Field = ({ 
  classes, 
  ...otherProps
}) => 
  <Input wrapperClassname={classes.root} {...otherProps} />
        
export default injectSheet(styles)(Field)