import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import theme, { rem } from '../../../../theme/base'
import { PAGES } from '../../../../config'

const styles = {
  root: {
    margin: [rem(55), rem(32), 0, rem(56)],
    paddingTop: rem(32),
    // TODO: add 'rgba(214, 227, 255, 0.3)' to theme.colors
    borderTop: [rem(1), 'solid', 'rgba(214, 227, 255, 0.3)'],
    position: 'relative',
    '&.noAuth': {
      margin: 0,
      padding: [rem(64), rem(24), 0, rem(24)],
      borderTop: 'none',
    },
  },
  item: {
    position: 'relative',
    '& + &': {
      marginTop: rem(24),
    },
  },
  link: {
    color: theme.colors.white,
    fontSize: rem(17),
    lineHeight: rem(20),
    '&:hover': {
      color: theme.colors.white,
    },
  },
}

const Pages = ({ classes, isAuth, onClose }) => 
  <div className={classnames(classes.root, { 'noAuth': !isAuth })}>
    {PAGES.map(({ name, link }, index) =>
      <div className={classes.item} onClick={onClose} key={index}>
        <Link className={classes.link} to={link}>{name}</Link>
      </div>
    )}
  </div>

export default injectSheet(styles)(Pages)