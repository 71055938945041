import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Icon } from '@verdaccio/mt-uikit'
import theme, { rem, desktop } from './../../../../theme/base'

const styles = {
  root: {
    width: rem(68),
    height: rem(40),
    // TODO: add '#bac7e2' to theme.colors
    border: [rem(2), 'solid', '#bac7e2'],
    borderRadius: rem(28),
    color: theme.colors.blue,
    fontSize: rem(13),
    lineHeight: rem(38),
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    [desktop]: {
      '&:hover': {
        // TODO: add '#dde3f1' to theme.colors
        backgroundColor: '#dde3f1',
        // TODO: add '#bac7e2' to theme.colors
        borderColor: '#bac7e2',
      },
    },
    '&:active': {
      background: theme.colors.blue,
      color: theme.colors.white,
      borderColor: theme.colors.blue,
    },
    '&.active': {
      background: theme.colors.blue,
      color: theme.colors.white,
      borderColor: theme.colors.blue,
    },
    '&.custom': {
      width: 'auto',
      paddingLeft: rem(16),
      paddingRight: rem(42),
    },
    '&.disabled': {
      // TODO: add '#dde3f1' to theme.colors
      backgroundColor: '#dde3f1',
      borderColor: '#dde3f1',
      color: theme.colors.blueyGrey,
      cursor: 'default',
    },
    '&.error': {
      backgroundColor: theme.colors.brightOrangeTwo,
      borderColor: theme.colors.brightOrangeTwo,
      color: theme.colors.white,
      cursor: 'default',
    },
    '& + &': {
      marginLeft: rem(4),
    },
  },
  reset: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    top: rem(4),
    right: rem(4),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}


const Preset = ({
  classes,
  active,
  disabled,
  error,
  text,
  onClick,
  onReset,
}) =>
  <div className={classnames(classes.root, { active, disabled, error, 'custom': onReset })} onClick={onClick}>
    {text}
    {onReset &&
      <div className={classes.reset} onClick={onReset}>
        <Icon iconName='close-transparent' />
      </div>
    }
  </div>

export default injectSheet(styles)(Preset)