import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from '@verdaccio/mt-uikit'
import theme, { rem } from './../../../theme/base'
import SupportIcon from './../../SupportIcon'

const getIconStyle = ({
  width = rem(28),
  height = rem(28),
  position = 'left',
  svgStyle = {}
}) => ({
  root: {
    width,
    height,
    position: 'absolute',
    cursor: 'pointer',
    ...{
      left: {
        top: '50%',
        transform: 'translateY(-50%)',
        left: rem(16),
      },
      right: {
        top: '50%',
        transform: 'translateY(-50%)',
        right: rem(16),
      }
    }[position]
  },
  icon: {
    width: 'inherit',
    height: 'inherit',
    display: 'block',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      ...svgStyle
    },
  }
})

const getIcon = (iconName, iconTheme) => injectSheet(getIconStyle(iconTheme))(
  ({ classes, onClick }) =>
    <div className={classes.root}>
      <div className={classes.icon} onClick={onClick}>
        <Icon iconName={iconName} />
      </div>
    </div>
)

const Back = getIcon('arrow-back', { svgStyle: { fill: theme.colors.blue } })

const Close = getIcon('close', {
  svgStyle: {
    fill: theme.colors.paleGrey,
    '&:hover': {
      fill: '#BDD0FC',
    },
  }
})

const CloseChat = getIcon('chat-form-back-icon', {
  position: 'right',
  svgStyle: { fill: theme.colors.blue }
})

const Support = injectSheet(getIconStyle({
  width: rem(36),
  height: rem(36),
  position: 'right',
}))(
  ({ classes, ...otherProps }) => <SupportIcon
    className={classes.root}
    bubbleLayout={{
      layout: 'right',
      offsetX: 34,
      offsetY: -8,
    }}
    bannerLayout={{
      layout: 'right',
      offsetX: 34,
      offsetY: -8,
    }}
    {...otherProps}
  />
)

export default {
  Back,
  Close,
  Support,
  CloseChat
}
