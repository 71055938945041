import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, adaptive } from '../../theme/base'
import { Icon, utils } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(16), rem(16), rem(16)],
    borderRadius: [0, 0, rem(24), rem(24)],
    boxShadow: `0 ${rem(16)} ${rem(40)} 0 rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)`,
    textAlign: 'center',
    position: 'fixed',
    top: rem(56),
    left: 0,
    right: 0,
    zIndex: 2,
    '&:before': {
      [adaptive]: {
        content: '""',
        background: theme.colors.white,
        height: rem(56),
        display: 'block',
        position: 'absolute',
        top: rem(-56),
        left: 0,
        right: 0,
      },
    },
  },
  title: {
    fontSize: rem(17),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
  },
  subject: {
    fontSize: rem(15),
    lineHeight: rem(24),
    color: theme.colors.slateGrey,
    letterSpacing: rem(0.09),
  },
  deadline: {
    marginTop: rem(12),
    borderTop: [rem(1), 'solid', theme.colors.paleGrey],
    paddingTop: rem(12),
    '& svg': {
      width: rem(16),
      height: rem(16),
      marginRight: rem(4),
      marginTop: rem(1),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  text: {
    fontSize: rem(12),
    lineHeight: rem(22),
    letterSpacing: rem(0.08),
    color: theme.colors.slateGreyTwo,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  action: {
    marginTop: rem(7),
    fontSize: rem(15),
    lineHeight: rem(20),
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontWeight: 600,
    color: theme.colors.blue,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
  },
}

const Info = ({
  title,
  subject,
  size,
  number,
  deadline,
  classes,
  onOrderDetails,
}) =>
  <Fragment>
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subject}>{subject} • {number}</div>
      <div className={classes.deadline}>
        <Icon iconName='timer' />
        <div className={classes.text}>
          <strong>{utils.sizeToHumans(size)} •</strong> Due {utils.printDateTime(deadline)}
        </div>
      </div>
      <div className={classes.action} onClick={onOrderDetails}>Task details</div>
    </div>
  </Fragment>

export default injectSheet(styles)(Info)