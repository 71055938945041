import colors from './colors'

const mainFontSize = 16

const fontFamilies = {
  SFProRoundedBlack: 'sf_pro_roundedblack',
  SFProRoundedRegular: 'sf_pro_roundedregular',
  SFProRoundedSemibold: 'sf_pro_roundedsemibold'
}

export const rel = (value, rel, unit = '') => `${value / rel}${unit}`
export const rem = (value) => {
  if (typeof value === 'string') {
    return value.split(' ').map(v => rel(v, mainFontSize, 'rem')).join(' ')
  }
  return rel(value, mainFontSize, 'rem')
}

export const media = {
  mobile: 320,
  desktop: 1024,
}

export const adaptive = `@media (min-width: ${media.mobile}px) and (max-width: ${media.desktop - 1}px)`
export const desktop = `@media (min-width: ${media.desktop}px)`

export const content = {
  width: '1024px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '40px',
  paddingRight: '40px',
  position: 'relative',
  [adaptive]: {
    width: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}

export const ui = {
  title: {
    fontFamily: fontFamilies.SFProRoundedBlack,
    fontSize: rem(32),
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: rem(0.19),
    textAlign: 'center',
    color: colors.charcoalGrey,
    [adaptive]: {
      fontSize: rem(24),
      lineHeight: rem(32),
    },
  },
  subtitle: {
    fontSize: rem(20),
    lineHeight: rem(32),
    letterSpacing: rem(0.12),
    color: colors.charcoalGrey,
    fontFamily: fontFamilies.SFProRoundedRegular,
    fontWeight: 'bold',
    [adaptive]: {
      fontSize: rem(18),
      lineHeight: rem(28),
    },
  },
  text: {
    fontSize: rem(20),
    lineHeight: rem(32),
    letterSpacing: rem(0.12),
    color: colors.slateGrey,
    fontFamily: fontFamilies.SFProRoundedRegular,
    [adaptive]: {
      fontSize: rem(18),
      lineHeight: rem(28),
    },
  },
  p: {
    fontSize: rem(16),
    lineHeight: rem(24),
    color: colors.slateGrey,
    letterSpacing: rem(0.1),
    textAlign: 'left',
    [adaptive]: {
      marginTop: rem(16),
      // fontSize: rem(14),
      // lineHeight: rem(20),
      textAlign: 'center',
    },
    '& + &': {
      marginTop: rem(16),
    },
  },
  list: {
    marginTop: rem(32),
    [adaptive]: {
      marginTop: rem(24),
    },
  },
  item: {
    paddingLeft: rem(20),
    fontSize: rem(20),
    lineHeight: rem(24),
    color: colors.slateGrey,
    letterSpacing: rem(0.12),
    fontFamily: fontFamilies.SFProRoundedRegular,
    position: 'relative',
    [adaptive]: {
      fontSize: rem(18),
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: rem(9),
      background: colors.slateGrey,
      width: rem(6),
      height: rem(6),
      borderRadius: rem(6),
    },
    '& + &': {
      marginTop: rem(32),
      [adaptive]: {
        marginTop: rem(24),
      },
    },
  },
  blockquote: {
    marginTop: rem(64),
    position: 'relative',
    [adaptive]: {
      marginTop: rem(40),
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: rem(-12),
      bottom: 0,
      background: colors.blue,
      width: rem(4),
      borderRadius: rem(2),
    },
    '& > *': {
      '&:first-child': {
        paddingTop: 0,
        [adaptive]: {
          paddingTop: 0,
        },
      },
    },
  },
}

export const MODAL_WIDTH_DESKTOP = rem(464)

export default {
  fontFamilies,
  colors
}