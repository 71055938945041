import React from 'react'
import { ContextMenu, selectors } from '@verdaccio/mt-uikit'
import { useSelector } from 'react-redux'
import {
  getOrderContext,
  getTutorContext,
} from './../nerdytutors'
import { useActions } from './../actions'

import {
  useOrder,
  useTutor,
} from './../hooks'

export const TutorContextMenu = ({
  tutorId,
  orderId,
  ...rest
}) => {
  const actions = useActions()
  const tutor = useTutor(tutorId)
  const order = useOrder(orderId)
  const presence = useSelector(selectors.presenceSelector)
  return <ContextMenu
    actions={getTutorContext(tutor, order, presence, actions)}
    {...rest}
  />
}

export const OrderContextMenu = ({
  orderId,
  ...rest
}) => {
  const actions = useActions()
  const order = useOrder(orderId)
  return <ContextMenu
    actions={getOrderContext(order, actions)}
    {...rest}
  />
}
