import React, { useRef } from 'react'
import injectSheet from 'react-jss'
import { Scrollbars } from 'react-custom-scrollbars'
import theme, { rem } from './../../theme/base'
import { getScrollbarWidth } from './../../utils'

const styles = {
  track: {
    background: '#eff1f6',
    width: rem(3),
    borderRadius: rem(2),
    position: 'absolute',
    top: rem(8),
    bottom: rem(8),
    right: rem(10),
  },
  thumb: {
    background: '#a4b0b9',
    width: rem(3),
    borderRadius: rem(2),
  },
}


const ScrollbarWrap = ({
  // classes,
  children, 
  className = 'scrollbar', 
  style = { 
    background: theme.colors.white,
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    borderRadius: rem(8),
    boxShadow: `
      0 ${rem(8)} ${rem(40)} 0 rgba(0, 53, 136, 0.15),
      0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)
    `,
    WebkitOverflowScrolling: 'touch',
    zIndex: 3, 
  }, 
  maxHeight, 
  autoHide,
}) => {
  const scrollBarRef = useRef()
  
  const onUpdate = (values) => {
    const ref = scrollBarRef
    const scrollBarWidth = getScrollbarWidth()
    ref.current.view.style.marginRight = rem(-scrollBarWidth)
    ref.current.view.style.marginBottom = rem(-scrollBarWidth)
    ref.current.view.style.minHeight = rem(scrollBarWidth)
  }

  // const renderTrack = () => <div className={classes.track} />
  // const renderThumb = () => <div className={classes.thumb} />

  return (
    <Scrollbars
      ref={scrollBarRef}
      className={className}
      style={style}
      autoHide={autoHide}
      autoHeight
      autoHeightMax={maxHeight || 216}
      onUpdate={onUpdate}
      // renderThumbVertical={renderThumb}
      // renderTrackVertical={renderTrack}
      // onScrollFrame={handleScrollFrame}
      hideTracksWhenNotNeeded
    >
      {children}
    </Scrollbars>
  )
}

export default injectSheet(styles)(ScrollbarWrap)