import React from 'react'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import { Avatar, Icon, utils, selectors } from '@verdaccio/mt-uikit'
import theme, { rem, modal, ui } from './../../theme/base'

const styles = {
  root: {
    paddingTop: rem(21),
    paddingLeft: rem(104),
    paddingRight: rem(104),
    height: rem(89),
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
  },
  close: {
    ...modal.button,
    position: 'absolute',
    top: rem(20),
    right: rem(20),
  },
  more: {
    ...modal.button,
    position: 'absolute',
    top: rem(20),
    right: rem(64),
  },
  name: {
    ...ui.title,
    marginBottom: rem(6),
  },
  text: ui.text,
  avatar: {
    position: 'absolute',
    top: rem(12),
    left: rem(24),
  },
}

const Header = ({
  id,
  name,
  meta,
  onClose,
  onMore,
  classes,
}) => {
  const presence = useSelector(selectors.presenceSelector)
  const favorite = presence && presence.favorite && presence.favorite.includes(id)
  const online = presence && presence.online && presence.online.includes(id)
  const block = presence && presence.block && presence.block.includes(id)

  if (!id) {
    onClose()
  }

  return <div className={classes.root}>
    <div className={classes.avatar}>
      <Avatar
        big
        src={utils.getUserAvatarUrl(id)}
        favorite={favorite}
        online={online}
        block={block}
      />
    </div>
    <div className={classes.name}>{name}</div>
    {meta &&
      <div className={classes.text}>{meta.subject}</div>
    }
    <div className={classes.close} onClick={onClose}>
      <Icon iconName='close' />
    </div>
    {onMore &&
      <div className={classes.more} onClick={onMore}>
        <Icon iconName='more-round' />
      </div>
    }

  </div>
}

export default injectSheet(styles)(Header)
