import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, adaptive } from './../../theme/base'
import { Icon } from '@verdaccio/mt-uikit'
import Info from './Info'

const styles = {
  root: {
    background: theme.colors.white,
    width: '100%',
    height: rem(56),
    borderRadius: [0, 0, rem(24), rem(24)],
    padding: [rem(8), rem(16)],
    // display: flex,
    // flex: 0 0 auto,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    '&.simple': {
      borderRadius: 0,
      paddingBottom: 0,
      [adaptive]: {
        padding: [rem(8), rem(16)],
        borderRadius: [0, 0, rem(24), rem(24)],
        boxShadow: `0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15), 0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06)`,
      },
    },
  },
  tabs: {
    background: theme.colors.paleGrey,
    width: rem(208),
    height: rem(40),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: rem(20),
  },
  item: {
    width: '50%',
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& div': {
      background: theme.colors.paleGrey,
      width: '100%',
      color: theme.colors.charcoalGrey,
      fontSize: rem(17),
      fontFamily: theme.fontFamilies.SFProRoundedSemibold,
      lineHeight: rem(22),
      padding: [rem(11), 0, rem(7)],
      transition: 'background 300ms ease-in-out, color 80ms ease-in-out',
      borderRadius: rem(20),
      display: 'block',
      position: 'relative',
      cursor: 'pointer',
    },
    '&.active': {
      '& div': {
        background: theme.colors.blue,
        color: theme.colors.white,
        cursor: 'default',
      },
    },
    '&.updated': {
      '& div': {
        '&:before': {
          content: "''",
          background: theme.colors.blue,
          width: rem(6),
          height: rem(6),
          borderRadius: '50%',
          position: 'absolute',
          left: rem(10),
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
    },
  },
  back: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    left: rem(16),
    top: rem(14),
    cursor: 'pointer',
    '& a': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      fill: theme.colors.charcoalGrey,
    },
  },
  more: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    right: rem(16),
    top: rem(14),
    cursor: 'pointer',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      fill: theme.colors.charcoalGrey,
    },
  },
}

const ChatNavbar = ({
  order,
  tabs,
  info,
  onMore,
  onOrderDetails,
  simple,
  style,
  classes,
}) =>
  <Fragment>
    <div className={classnames(classes.root, { simple })} style={style}>
      <div className={classes.back}>
        <Link to='/orders'>
          <Icon iconName='back' />
        </Link>
      </div>
      <div className={classes.tabs}>
        {tabs.map(({ name, key, active, notify, onClick }) =>
          <div
            className={classnames(classes.item, {
              'active': active,
              'updated': !active && notify,
            })}
            key={key}
          >
            <div onClick={active ? null : onClick}>{name}</div>
          </div>
        )}
      </div>
      <div className={classes.more} onClick={onMore}>
        <Icon iconName='more' />
      </div>
    </div>
    {info &&
      <Info
        {...order}
        onOrderDetails={onOrderDetails}
      />
    }
  </Fragment>

export default withRouter(injectSheet(styles)(ChatNavbar))