import React, { useState, useRef } from 'react'
import injectSheet from 'react-jss'
import { Button, Icon, selectors, Message } from '@verdaccio/mt-uikit'
import theme, { rem, desktop } from './../../theme/base'
import ContentWrapper from './../../components/ContentWrapper'
import DesktopWrapperHOC from './../../components/DesktopWrapperHOC'
import Field from './Field'
import { useActions } from './../../actions'
import { useSelector } from 'react-redux'
import regexps from './../../utils/regexps'
import getProfileAvatarByEmail from '../../utils/getProfileAvatarByEmail'
import { isAndroid } from './../../utils'
import { useUserProfile } from './../../hooks'

const styles = {
  content: {
    padding: [rem(24), rem(24), 0, rem(24)],
  },
  avatar: {
    background: theme.colors.lightBlueGrey50,
    width: rem(64),
    height: rem(64),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    cursor: 'pointer',
    '& .icon': {
      width: rem(24),
      height: rem(24),
      position: 'absolute',
      bottom: rem(-6),
      right: 0,
      cursor: 'pointer',
    },
    '& svg, & img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  submit: {
    background: theme.colors.white,
    padding: rem(24),
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    [desktop]: {
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: [0, 0, rem(16), rem(16)],
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      position: 'relative'
    },
  },
}

const SHOW_LABEL_TIME = 800

const SettingsPage = ({
  classes,
  onClose,
}) => {
  const actions = useActions()
  const { profile } = useSelector(selectors.authSelector)

  const [name, setName] = useState(profile.name)
  const [email, setEmail] = useState(profile.email)
  const [showLabel, setShowLabel] = useState(false)

  useUserProfile()

  const ref = useRef()

  const onShowLabel = () => setShowLabel(true)
  const onHideLabel = () => setShowLabel(false)

  const onSubmit = () => {
    actions.onUpdateProfile({ ...profile, name, email })
    onShowLabel()
    setTimeout(onHideLabel, SHOW_LABEL_TIME)
  }
  const onScroll = () => window.scrollTo({
    top: ref && ref.current ? ref.current.getBoundingClientRect().top : 0,
    behavior: 'smooth',
  })

  const validEmail = regexps.email(email.toLowerCase())

  const disabled = !validEmail

  return <ContentWrapper
    title='Profile Settings'
    onClose={onClose}
    className={classes.content}
  >
    <div className={classes.avatar}>
      <img src={getProfileAvatarByEmail(profile.email)} alt='' />
      <div className='icon'>
        <Icon iconName='add-photo' />
      </div>
    </div>

    <Field
      name='name'
      type='text'
      value={name}
      placeholder='Your name'
      onChange={value => setName(value)}
      onClear={_ => setName('')}
    />

    <Field
      name='phone'
      type='tel'
      value={profile.phone}
      placeholder='Phone number'
      disabled
    />

    <div ref={ref}>
      <Field
        name='email'
        type='email'
        value={email}
        placeholder='Email address'
        onChange={value => setEmail(value)}
        onClear={_ => setEmail('')}
        onScroll={_ => isAndroid ? onScroll() : () => { }}
      />
    </div>

    <div className={classes.submit}>
      <Button
        block
        size='large'
        onClick={onSubmit}
        disabled={disabled}
      >
        Save
      </Button>
    </div>
    {showLabel &&
      <Message.Success timeout={SHOW_LABEL_TIME}>Profile updated</Message.Success>
    }
  </ContentWrapper>
}

export default DesktopWrapperHOC(injectSheet(styles)(SettingsPage))