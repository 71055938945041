import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { rem } from './../../../../theme/base'

const styles = {
  root: {
    position: 'relative',
    '& + &': {
      marginTop: rem(30),
    },
  },
}

const Block = ({
  classes,
  className,
  children,
}) => <div className={classnames(classes.root, className)}>{children}</div>

export default injectSheet(styles)(Block)