import React, { } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import { Icon, Button, selectors } from '@verdaccio/mt-uikit'
import theme, { rem, content, desktop } from './../../theme/base'
import { useActions } from '../../actions'
import SupportIcon from '../SupportIcon'

const styles = {
  root: {
    ...content,
    background: theme.colors.white,
    height: rem(66),
    padding: [rem(16), 0],
    borderRadius: [0, 0, rem(24), rem(24)],
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 4,
    [desktop]: {
      height: rem(56),
      paddingTop: rem(8),
      paddingBottom: rem(8),
      borderRadius: 0,
      boxShadow: `inset 0 ${rem(-1)} 0 0 ${theme.colors.paleGrey}`,
    },
    '&.flat': {
      background: theme.colors.paleGrey,
      borderRadius: 0,
    },
    '&.onlyLogo': {
      textAlign: 'center',
    },
    '&.onlyDesktop': {
      display: 'none',
      [desktop]: {
        display: 'block',
      },
    },
  },
  content,
  hamburger: {
    width: rem(28),
    height: rem(28),
    marginRight: rem(16),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    [desktop]: {
      marginTop: rem(6),
      marginRight: rem(8),
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      '&.hamburger-mobile': {
        [desktop]: {
          display: 'none',
        },
      },
      '&.hamburger-desktop': {
        display: 'none',
        [desktop]: {
          display: 'block',
        },
      },
    },
  },
  logo: {
    backgroundImage: `url(${require('./../../assets/images/logo.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    width: rem(160),
    height: rem(24),
    marginTop: rem(2),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    [desktop]: {
      backgroundImage: `url(${require('./../../assets/images/logo-min.svg')})`,
      width: rem(24),
      height: rem(24),
      marginTop: rem(8),
    },
  },
  add: {
    display: 'none',
    [desktop]: {
      top: rem(8),
      right: rem(16),
      position: 'absolute',
      display: 'block',
    },
    '& a': {
      lineHeight: rem(38),
    },
  },
  button: {
    width: rem(144),
    '&:hover': {
      color: theme.colors.white,
    },
  },
  items: {
    display: 'none',
    [desktop]: {
      marginLeft: rem(80),
      marginTop: rem(10),
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  item: {
    fontSize: rem(12),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    fontWeight: 300,
    textTransform: 'uppercase',
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: rem(40),
    },
    '&.active': {
      color: theme.colors.charcoalGrey,
      fontWeight: 600,
      borderBottom: [rem(2), 'solid', theme.colors.blue],
    },
  },
  support: {
    [desktop]: {
      top: rem(10),
      right: rem(176),
    },
  }
}

const Header = ({
  active,
  flat,
  onlyLogo,
  onlyDesktop,
  className,
  classes,
  onMenu,
  onSupport,
}) => {
  const actions = useActions()
  const auth = useSelector(selectors.authSelector)
  const isAuth = !!(auth && auth.cid)
  return <header
    className={classnames(classes.root, className, { flat, onlyLogo, onlyDesktop })}
  >
    {!onlyLogo &&
      <div className={classes.hamburger} onClick={onMenu}>
        <Icon className='hamburger-mobile' iconName='hamburger' />
        <Icon className='hamburger-desktop' iconName='hamburger-desktop' />
      </div>
    }
    <Link className={classes.logo} to='/' />
    {isAuth &&
      <div className={classes.items}>
        <Link className={classnames(classes.item, { 'active': active === 'orders' })} to='/orders'>My tasks</Link>
        <Link className={classnames(classes.item, { 'active': active === 'tutors' })} to='/tutors'>My tutors</Link>
      </div>
    }
    {!onlyLogo && isAuth &&
      <>
        <SupportIcon
          className={classes.support}
          onClick={onSupport}
          bubbleLayout={{
            layout: 'bottom',
            offsetY: 16
          }}
          context={{ stage: 'header' }}
        />
        <div className={classes.add}>
          <Button
            className={classes.button}
            type='primary'
            size='medium'
            onClick={() => actions.onNewTask()}
          >
            New task
        </Button>
        </div>
      </>
    }
  </header>
}

export default injectSheet(styles)(Header)