import React, { Fragment, useState } from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem } from './../../../theme/base'
import { Preset, Presets, Reset } from './../Layout'
import { isMobile, isAndroid } from './../../../utils'
import DesktopDropdown from './DesktopDropdown'
import MobileDropdown from './MobileDropdown'
import { utils } from '@verdaccio/mt-uikit'


const styles = {
  helper: {
    height: rem(40),
    padding: [rem(8), 0, rem(10)],
    fontSize: rem(17),
    lineHeight: rem(22),
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    cursor: 'pointer',
    '&.active': {
      color: theme.colors.blue,
      borderColor: theme.colors.blue,
      fontWeight: 600,
    },
  }
}

const PRESETS_SIZE = 6

const Dropdown = isMobile && !isAndroid ? MobileDropdown : DesktopDropdown

const SelectSize = ({
  size,
  items,
  onChange,
  classes,
  resettosize = null,
}) => {
  const [showPresets, setShowPresets] = useState(true)
  const [showSelect, setShowSelect] = useState(false)
  const onReset = () => { setShowPresets(true); setShowSelect(false); onChange(resettosize) }
  const onShowSelect = () => setShowSelect(true)
  const onCustom = () => { setShowPresets(false); onShowSelect() }
  const selectProps = {
    items,
    defaultvalue: size,
    onChange: value => { onChange(value); setShowSelect(false) },
    onBlur: size ? () => setShowSelect(false) : () => onReset(),
  }
  return (
    <Presets
      type='size'
      customText='I need more hours'
      custom={!showPresets || size < 1}
      onCustom={onCustom}
    >
      {(size >= 1 && size < PRESETS_SIZE && showPresets) || !size
        ? items.slice(1, PRESETS_SIZE).map(({ key, value, text }) =>
          <Preset key={key} value={value} text={text} active={value === size} onClick={() => onChange(value)} />)
        : <Fragment>
          <div className={classnames(classes.helper, { active: showSelect })} onClick={onShowSelect}>
            {size ? utils.sizeToHumans(size) : ''}
          </div>
          <Reset onClick={onReset} />
        </Fragment>
      }
      {showSelect &&
        <Dropdown {...selectProps} />
      }
    </Presets>
  )
}

export default injectSheet(styles)(SelectSize)
