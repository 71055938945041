import React from 'react'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../theme/base'

const styles = {
  root: {
    marginTop: rem(24),
    paddingBottom: rem(16),
    fontFamily: theme.fontFamilies.SFProRoundedBlack,
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(22),
    textAlign: 'center',
  },
}

const Greeting = ({ 
  classes, 
  children, 
}) => 
  <div className={classes.root}>{children}</div>
        
export default injectSheet(styles)(Greeting)