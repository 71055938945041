import React, { useState, useEffect, Fragment } from 'react'
import injectSheet from 'react-jss'
import { useLocation } from 'react-router-dom'
import regexps from './../../utils/regexps'
import { compact } from 'lodash/array'
import { useActions } from './../../actions'
import DesktopWrapperHOC2 from './../DesktopWrapperHOC2'
import theme, { rem } from './../../theme/base'
import { Wrapper, Logo, Greeting, Error, Form, Field, Submit, StateLink, Action, ButtonGoogleLogin } from './Layout'

const styles = {
  forgot: {
    marginTop: rem(16),
    color: theme.colors.slateGrey,
    textAlign: 'center',
    '& a': {
      color: theme.colors.blue,
    },
  },
}

const Login = ({ classes }) => {
  const location = useLocation()

  const {
    onLogin,
    onLoginUserByEmailPassword,
    onNewTask,
  } = useActions()

  useEffect(() => {
    document.body.style.background = theme.colors.blue

    return () => {
      document.body.style.background = ''
    }
  }, [])

  const [email, setEmail] = useState(location.state && location.state.email || '')
  const [password, setPassword] = useState('')
  const [submit, setSubmit] = useState(false)
  const [submitError, setSubmitError] = useState()

  const cleanedEmail = email.trim().toLowerCase()
  const cleanedPassword = password.trim()

  const validEmail = regexps.email(cleanedEmail)
  const validPassword = cleanedPassword.length > 0
  const valid = validEmail && validPassword

  const errorEmail = submit && !validEmail && 'Oops, check your email format.'
  const errorPassword = submit && !validPassword && 'Incorrect password'

  const disabled = submit && !valid

  const errors = []
  errors.push(submitError)
  errors.push(errorEmail)
  errors.push(errorPassword)
  const error = compact(errors).length > 0 ? compact(errors)[0] : false

  const nextLogin = auth => {
    onLogin(auth)
    const miniOrderFormData = location.state && location.state.from && location.state.from.state && location.state.from.state.order
    if (miniOrderFormData) {
      onNewTask({ order: miniOrderFormData })
    }
  }

  const onSubmit = () => {
    setSubmit(true)
    if (valid) {
      onLoginUserByEmailPassword(cleanedEmail, cleanedPassword)
        .then(nextLogin)
        .catch(err => setSubmitError(err.details))
    }
  }

  return (
    <Fragment>
      <Wrapper>
        <Logo />
        <ButtonGoogleLogin onSuccess={nextLogin} onError={setSubmitError} />
        <Greeting>Or use your email to log in</Greeting>
        {error &&
          <Error>{error}</Error>
        }
        <Form>
          <Field
            type='email'
            placeholder='Enter your email'
            value={cleanedEmail}
            onChange={value => setEmail(value)}
            error={errorEmail}
            onKeyUp={e => e.keyCode === 13 && valid && onSubmit()}
          />
          <Field
            type='password'
            placeholder='Enter your password'
            value={cleanedPassword}
            onChange={value => setPassword(value)}
            error={errorPassword}
            onKeyUp={e => e.keyCode === 13 && valid && onSubmit()}
          />
          <Submit onClick={onSubmit} disabled={disabled}>Log in</Submit>
          <div className={classes.forgot}>
            Forgot password? {' '}
            <StateLink to='/restore' state={{ email }}>
              Restore
            </StateLink>
          </div>
        </Form>
      </Wrapper>
      <Action
        text='Do not have an account?'
        link='Sign up'
        to='/signup'
        state={{ email }}
      />
    </Fragment>
  )
}

export default DesktopWrapperHOC2(injectSheet(styles)(Login))