const SEO = {
    "/": [
        "none (main page)",
        "Nerdy Tutors | On-Demand Tutoring Platform",
        "1000s of tutors are always at your fingertips. Chat directly to tutors in our messenger.",
        "online tutoring, tutoring help, homework tutoring, english tutor, best tutoring website, online tutor, writing tutor, tutor site, tutoring service, math tutor, accounting tutor",
    ],
    "/tutoring-help-1": [
        "Google Search - tutoring",
        "Nerdy Tutors | On-Demand Tutoring Platform",
        "1000s of tutors are always at your fingertips. Chat directly to tutors in our messenger.",
        "online tutoring, tutoring help, homework tutoring, english tutor, best tutoring website, online tutor, writing tutor, tutor site, tutoring service, math tutor, accounting tutor",
    ],
    "/homework-help-1": [
        "Google Search - homework help",
        "Nerdy Tutors | On-Demand Homework Tutoring Platform",
        "1000s of tutors are always at your fingertips. Chat directly to tutors in our messenger.",
        "homework tutor, do my homework, homework helping, homework help, do my online homework, homework tutoring help, doing homework, help with homework, college homework help",
    ],
    "/homework-help-2": [
        "Google Search - essay+paper",
        "Nerdy Tutors | On-Demand Study Help and Tutoring Platform",
        "1000s of tutors are always at your fingertips. Chat directly to tutors in our messenger.",
        "paper, essay, write papers, essays, research paper, paper tutor, essay tutor, essays help, writing my paper, tutoring help, my essay, paper for me, college paper, essays writing help, write essays, students paper, help with essays, do my paper, online essay help, writing research paper, my research paper, english essay, essay for you, write a paper, write your essay, college writing, essay writing tutor, online paper tutoring",
    ],
    "/early-access": [
        "none (early access page)",
        "Nerdy Tutors | On-Demand Tutoring Platform",
        "1000s of tutors are always at your fingertips. Chat directly to tutors in our messenger.",
        "online tutoring, tutoring help, homework tutoring, english tutor, best tutoring website, online tutor, writing tutor, tutor site, tutoring service, math tutor, accounting tutor",
    ]
}

export default () => {
    const [_, title, description, keywords] = SEO[window.location.pathname] || SEO["/"]
    return { title, description, keywords }
}