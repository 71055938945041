import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop, MODAL_WIDTH_DESKTOP } from './../../theme/base'
import { utils, Layout, Attachment, Button, Icon } from '@verdaccio/mt-uikit'

const styles = {
  root: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  content: {
    top: `${rem(79)} !important`,
    bottom: '0 !important',
    overflowY: 'hidden',
    [desktop]: {
      top: 'auto !important',
    },
  },
  header: {
    padding: [rem(16), rem(56)],
    textAlign: 'center',
    position: 'relative',
  },
  title: {
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(22),
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  subject: {
    marginTop: rem(4),
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  details: {
    maxHeight: `calc(100% - ${rem(150)})`,
    padding: [rem(24), rem(16), rem(8)],
    overflowY: 'auto',
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(17),
    lineHeight: rem(24),
    letterSpacing: rem(0.1),
    color: theme.colors.charcoalGrey,
    whiteSpace: 'pre-wrap',
  },
  attachments: {
    marginTop: rem(24),
    padding: [0, rem(16), rem(10)],
  },
  buy: {
    marginTop: rem(16),
    marginBottom: rem(24),
    textAlign: 'center',
    position: 'relative',
    '& svg': {
      fill: theme.colors.blue,
      width: rem(16),
      height: rem(16),
      marginRight: rem(8),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& span': {
      marginTop: rem(1),
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  info: {
    padding: [rem(16), rem(16), 0],
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 500,
    textAlign: 'center',
    '& span': {
      fontWeight: 600,
    },
  },
}

const ViewDetails = ({
  size,
  deadline,
  details,
  subject,
  title,
  files,
  onClose,
  onPromptBuyExtraHours,
  classes,
}) =>
  <div className={classes.root}>
    <Layout.Header onClose={onClose}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subject}>{subject}</div>
      </div>
    </Layout.Header>
    <Layout.Content className={classes.content}>
      <div className={classes.info}>
        <span>{utils.sizeToHumans(size)}</span> · Due {utils.printDateTime(deadline)}
        {onPromptBuyExtraHours && (
          <Button
            block
            type='outline'
            className={classes.buy}
            onClick={() => {
              onPromptBuyExtraHours();
              onClose()
            }}
          >
            <Icon iconName='time' />
            <span>Buy extra hours</span>
          </Button>
        )}
      </div>

      <div className={classes.details}>{details}</div>
      <div className={classes.attachments}>
        {files.map(file =>
          <Attachment
            key={file.name}
            {...file}
          />
        )}
      </div>
    </Layout.Content>
  </div>

export default injectSheet(styles)(ViewDetails)
