import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from '@verdaccio/mt-uikit'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(40),
    marginLeft: rem(24),
    // marginRight: rem(24),
  },
  item: {
    position: 'relative',
    '& + &': {
      marginTop: rem(32),
    },
  },
  helper: {
    paddingLeft: rem(32),
    color: theme.colors.white,
    cursor: 'pointer',
    fontSize: rem(17),
    lineHeight: rem(20),
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    display: 'inline-block',
    verticalAlign: 'top',
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& svg': {
      width: rem(16),
      height: rem(16),
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'block',
    },
  },
  icon: {
    width: rem(16),
    height: rem(12),
    border: [rem(2), 'solid', theme.colors.white],
    borderRadius: rem(2),
    position: 'absolute',
    left: 0,
    top: rem(3),
    '&:after': {
      content: "''",
      position: 'absolute',
      top: rem(2),
      left: 0,
      right: 0,
      height: rem(2),
      display: 'block',
      background: theme.colors.white,
    },
  },
}

const Items = ({
  classes,
  items,
}) =>
  <div className={classes.root}>
    {/* <div className={classes.item}>
      <Link onClick={onClose} to={{
        pathname: '/payment-methods',
        state: { prevUrl }
      }}>
        <span className='icon' />
        <span>Payment methods</span>
      </Link>
    </div> */}
    {items.map(({ name, icon, onClick }, index) =>
      <div className={classes.item} key={index}>
        <div className={classes.helper} onClick={onClick}>
          <Icon iconName={icon} />
          <span>{name}</span>
        </div>
      </div>
    )}
  </div>

export default injectSheet(styles)(Items)