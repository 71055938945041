import React from 'react'
import { Prompt } from '@verdaccio/mt-uikit'
import { useOrder } from '../../hooks'

const RejectTutor = ({
  orderId,
  onCancel,
  onClose,
  onAccept,
  ...otherProps
}) => {
  const order = useOrder(orderId)
  const _onAccept = () => { onAccept(order); onClose() }
  const _onClose = () => { onCancel && onCancel(); onClose() }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='reject' />
      <Prompt.Title>Reject Tutor</Prompt.Title>
      <Prompt.Details details={[
        'If you reject this tutor, we will start searching for a new one. It may take up to a few hours to find a new one.',
        'Are you sure you want to reject this tutor?',
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='primary' onClick={_onAccept}>Reject</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default RejectTutor