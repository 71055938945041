import React, { Fragment, useEffect } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, errors } from './../../theme/base'
import Header from './../Header'
import Footer from './../../components/Footer'

const iconsPath = require.context('./')

const styles = {
  root: {
    backgroundImage: `url(${iconsPath(`./maintenance-icon.svg`)})`,
    backgroundColor: '#fcfdfd',
    backgroundRepeat: 'no-repeat',
    //backgroundSize: cover,
    backgroundPosition: ['center', rem(74)],
    width: '100%',
    height: rem(300),
    paddingTop: rem(74),
  },
  title: {
    ...errors.title,
    paddingTop: rem(16),
  },
  text: {
    ...errors.text,
    paddingTop: rem(8),
  },
  header: {
    background: '#fcfdfd',
  },
}

const MaintenancePage = ({ classes }) => {
  useEffect(() => {
    document.body.style.backgroundImage = `radial-gradient(circle at 50% 0, #fcfdfd, ${theme.colors.white})`

    return () => {
      document.body.style.backgroundImage = ''
    }
  }, [])

  return (
    <Fragment>
      <Header className={classes.header} />
      <div className={classes.root} />
      <div className={classes.title}>Ooops</div>
      <div className={classes.text}>
        Hang in there, we're down for scheduled
        <br />
        maintenance right now. Be back soon!
      </div>
      <Footer />
    </Fragment>
  )
}

export default injectSheet(styles)(MaintenancePage)