import React from 'react'
import injectSheet from 'react-jss'
import { utils } from '@verdaccio/mt-uikit'
import theme, { rem, adaptive, desktop, media } from './../../../theme/base'
import DesktopWrapperHOC from './.././../DesktopWrapperHOC'
import { Block, Details, Item, Secure, Subject, Title, Wrapper } from './Layout'
import { Banner, Submit, SaveAndClose } from '../Layout'

const styles = {
  root: {
    paddingTop: rem(24),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    paddingBottom: 0,
    [desktop]: {
      minHeight: rem(529),
    },
    [adaptive]: {
      paddingBottom: rem(290),
    },
  },
  bonusBlock: {
    padding: [rem(12), 0],
  },
  bonusValue: {
    color: '#489900',
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
  },
  totalItem: {
    color: theme.colors.charcoalGrey,
    fontSize: rem(17),
    lineHeight: rem(28),
  },
  totalValue: {
    fontSize: rem(17),
    lineHeight: rem(28),
    fontWeight: 600,
  },
  oldPrice: {
    marginRight: rem(16),
    fontSize: rem(17),
    lineHeight: rem(28),
    color: theme.colors.blueyGrey,
    textDecoration: 'line-through',
  },
  banner: {
    marginTop: rem(24),
  },
}

const OrderCheckout = ({
  classes,
  title,
  subject,
  deadline,
  size,
  price,
  total,
  balance,
  requestedTutor,
  banner,
  onSubmit,
  onOpenFullDetails,
  onSupport,
  onSaveOrderForce,
}) => {
  const isMobile = utils.useMobile(media.desktop)
  const hasBalance = balance > 0
  const useBalance = Math.min(balance, price)
  const blocks = [
    {
      show: true,
      items: [
        {
          title: 'Tutor will work on your task for:',
          values: [{ value: utils.sizeToHumans(size) }],
        },
        {
          title: 'Done by:',
          values: [{ value: utils.printDueDate(deadline) }],
        },
      ],
    },
    {
      show: requestedTutor,
      items: [
        {
          title: 'Requested tutor:',
          values: [
            {
              value: requestedTutor && requestedTutor.name,
              image: requestedTutor && utils.getUserAvatarUrl(requestedTutor.id),
            }
          ],
        },
      ],
    },
    {
      show: hasBalance,
      items: [
        {
          title: 'Bonus used:',
          values: [{ value: `-${utils.printAmount(useBalance)}`, className: classes.bonusValue }],
        }
      ]
    },
    {
      show: true,
      items: [
        {
          title: 'Total:',
          values: [
            { value: hasBalance && utils.printAmount(price), className: classes.oldPrice },
            { value: utils.printAmount(total), className: classes.totalValue },
          ],
          className: classes.totalItem,
        },
      ]
    }
  ]

  return <div className={classes.root}>
    <Title>{title}</Title>
    <Subject>{subject}</Subject>
    <Details onClick={onOpenFullDetails} />
    <Wrapper>
      {blocks.map(({ show, items }, index) =>
        show
          ? <Block key={index}>
            {items.map((item, idx) =>
              <Item {...item} key={idx} />
            )}
          </Block>
          : null
      )}
      <Secure />
      {isMobile &&
        <Banner
          className={classes.banner}
          title={banner.title}
          description={banner.text}
          image={require('./banner.svg')}
          onSupport={onSupport}
        />
      }
    </Wrapper>
    <Submit
      text='Secure payment'
      price={price}
      total={total}
      onSubmit={onSubmit}
    />
    <SaveAndClose onClick={onSaveOrderForce} />
  </div>
}

export default DesktopWrapperHOC(injectSheet(styles)(OrderCheckout))