import React, { Fragment, useRef } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button, selectors, useOutsideClick } from '@verdaccio/mt-uikit'
import theme, { rem, desktop } from './../../theme/base'
import {
  Avatar, 
  // Banner, 
  Bonus,
  Close,
  Email,
  Items,
  Logout,
  Name,
  Pages,
  Phone,
  Settings,
  Wrapper,
} from './Layout'

const styles = {
  root: {
    background: theme.colors.blue,
    width: '83%',
    paddingBottom: rem(24),
    position: 'fixed',
    top: 0,
    // left: '-83%',
    bottom: 0,
    // zIndex: 2000,
    transition: 'left 300ms ease-in-out',
    overflowY: 'auto',
    [desktop]: {
      width: rem(360),
    },
    '&.opened': {
      left: 0,
    },
  },
  logo: {
    width: rem(160),
    height: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'absolute',
    top: rem(15),
    left: '50%',
    transform: 'translateX(-50%)',
    '& img': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  actions: {
    position: 'absolute',
    left: rem(16),
    right: rem(16),
    bottom: rem(16),
    '& button': {
      borderRadius: rem(16),
    },
  },
  login: {
    marginTop: rem(16),
    fontSize: rem(20),
    lineHeight: rem(24),
    textAlign: 'center',
    color: theme.colors.blue,
    fontWeight: 600,
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    cursor: 'pointer',
  },
}

const Menu = ({
  prevUrl,
  zIndex,
  classes,
  opened,
  onFaq,
  onSupport,
  onClose,
  onLogout,
}) => {
  const history = useHistory()
  const ref = useRef()
  const { cid, profile } = useSelector(selectors.authSelector)
  const isAuth = !!cid
  const _onLogout = () => { onClose(); onLogout() }
  const _onFaq = () => { onClose(); onFaq() }
  const _onSupport = () => { onClose(); onSupport() }
  const onSignUp = () => { onClose(); history.push('/signup') }
  const onLogin = () => { onClose(); history.push('/login') }

  const name = isAuth
    ? profile && profile.name
      ? profile.name
      : 'My profile'
    : ''
  const phone = (profile && profile.phone) || ''
  const email = (profile && profile.email) || ''
  const balance = (profile && profile.balance) || 0
  const items = [
    { name: 'Frequently Asked Questions', icon: 'menu-faq', onClick: _onFaq },
    { name: 'Contact Support', icon: 'menu-support', onClick: _onSupport },
  ]

  useOutsideClick(ref, onClose)

  return (
    <div className={classnames(classes.root, { opened })} style={{ zIndex }} ref={ref}>
      <Wrapper isAuth={isAuth}>
        {isAuth 
          ? <Avatar email={email} />
          : <Fragment>
              <Link className={classes.logo} to='/'>
                <img src={require('../../assets/images/logo.svg')} alt='' />
              </Link>
              <div className={classes.actions}>
                <Button
                  type='primary'
                  size='large'
                  block
                  onClick={onSignUp}
                >
                  Sign up
                </Button>
                <div className={classes.login} onClick={onLogin}>Login</div>
              </div>
            </Fragment>
        }
        {name && <Name>{name}</Name>}
        {phone && <Phone>{phone}</Phone>}
        {email && <Email>{email}</Email>}
        {balance > 0 && <Bonus balance={balance} />}
        <Close onClose={onClose} />
        {isAuth && <Settings prevUrl={prevUrl} onClose={onClose} />}
      </Wrapper>
      {isAuth && <Items items={items} />}
      <Pages isAuth={isAuth} onClose={onClose} />
      {isAuth && <Logout onLogout={_onLogout} />}
    </div>
  )
}

export default injectSheet(styles)(Menu)