import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from './../../../../theme/base'

const styles = {
  root: {
    // TODO: add color '#EBEFFA' to theme.colors
    backgroundColor: '#EBEFFA',
    padding: [rem(12), rem(90), rem(12), rem(12)],
    borderRadius: rem(16),
    position: 'relative',
    [desktop]: {
      paddingLeft: rem(36),
    },
  },
  image: {
    display: 'block',
    // backgroundImage: `url(${require('./banner.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: [rem(64), rem(64)],
    width: rem(64),
    height: rem(64),
    position: 'absolute',
    top: rem(12),
    right: rem(16),
  },
  title: {
    color: theme.colors.slateGrey,
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
  },
  description: {
    marginTop: rem(3),
    color: theme.colors.slateGrey,
    fontSize: rem(13),
    lineHeight: rem(20),
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
  },
  support: {
    marginTop: rem(4),
    '& span': {
      // TODO: add '#3D77FF' to theme.colors
      color: '#3D77FF',
      fontSize: rem(15),
      lineHeight: rem(20),
      fontWeight: 600,
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
      cursor: 'pointer',
    },
  },
}

const Banner = ({
  classes,
  className,
  title,
  description,
  image,
  onSupport,
}) =>
  <div className={classnames(classes.root, className)}>
    <img className={classes.image} src={image} alt='' />
    {title &&
      <div className={classes.title}>{title}</div>
    }
    {description &&
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    }
    {onSupport &&
      <div className={classes.support}>
        <span onClick={onSupport}>Contact support</span>
      </div>
    }
  </div>

export default injectSheet(styles)(Banner)