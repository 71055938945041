import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { rem } from './../../theme/base'
import ScrollbarWrap from './../ScrollbarWrap'
import Block from './Block'
import Label from './Label'
import List from './List'
import { 
  MIN_CHARS_TO_AVAILABLE_SEARCH, 
  TOP_SUBJECTS, 
  SUBJECTS, 
} from '../../config'

const styles = {
  root: {
    position: 'absolute',
    left: rem(8),
    right: rem(8),
    bottom: 0,
    zIndex: 100,
  },
}

const SubjectsList = ({ 
  classes, 
  className, 
  search, 
  onClick, 
}) => {
  const allSubjects = [...TOP_SUBJECTS, ...SUBJECTS]

  const findSubjects = allSubjects.filter(subject => 
    search.trim().split(' ').find(item => 
      subject.toLowerCase().includes(item.toLowerCase())
    )
  )

  const isShowDropdown =
    (findSubjects.length > 0 && search.length >= MIN_CHARS_TO_AVAILABLE_SEARCH) || 
    !search.length

  if (!isShowDropdown) {
    return null
  }

  return (
    <div className={classes.root}>
      <ScrollbarWrap>
        {search.length
          ? <List 
              className={className}
              search={search}
              items={findSubjects}
              onClick={onClick}
            />
          : <Fragment>
              <Block>
                <Label>Top subjects</Label>
                <List 
                  className={className}
                  search={search}
                  items={TOP_SUBJECTS}
                  onClick={onClick}
                />
              </Block>
              <Block>
                <Label>100+ subjects</Label>
                <List 
                  className={className}
                  search={search}
                  items={SUBJECTS}
                  onClick={onClick}
                />
              </Block>
            </Fragment>
        }
      </ScrollbarWrap>
    </div>
  )
}

export default injectSheet(styles)(SubjectsList)