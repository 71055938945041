import React, { Fragment, useEffect } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content, errors } from './../../theme/base'
import Header from './../../components/Header'


const iconsPath = require.context('./')

const styles = {
  content: {
    ...content,
    maxWidth: rem(375),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: rem(24),
    paddingRight: rem(24),
  },
  root: {
    backgroundImage: `url(${iconsPath(`./no-connection-icon.svg`)})`,
    backgroundColor: theme.colors.paleGrey,
    backgroundRepeat: 'no-repeat',
    //backgroundSize: 'cover',
    backgroundPosition: ['center', rem(72)],
    width: '100%',
    height: rem(306),
    paddingTop: rem(72),
  },
  status: {
    backgroundImage: `url(${iconsPath(`./icon-process.svg`)})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: rem(56),
    height: rem(56),
    margin: [rem(10), 'auto', 0],
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${iconsPath(`./loader.svg`)})`,
      backgroundSize: `${rem(26)} ${rem(26)}`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      width: rem(26),
      height: rem(26),
      position: 'absolute',
      left: rem(15),
      top: rem(15),
    },
  },
  text: {
    marginTop: rem(16),
    fontSize: rem(19),
    lineHeight: rem(24),
    color: theme.colors.charcoalGrey,
    letterSpacing: rem(0.11),
    textAlign: 'center',
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontWeight: 600,
  },
}

const NoConnection2 = ({ classes }) => {
  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.paleGrey

    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  return (
    <Fragment>
      <Header flat onlyLogo />
      <div className={classes.root} />
      <div className={classes.content}>
        <div className={classes.status}/>
        <div className={classes.text}>Connecting to server…</div>
      </div>
    </Fragment>
  )
}

export default injectSheet(styles)(NoConnection2)