import React from 'react'
import injectSheet from 'react-jss'
import { rem, content, desktop, adaptive, centered } from './../../theme/base'

const styles = {
  root: {
    [desktop]: {
      minHeight: rem(200),
    },
  },
  wrapper: {
    [desktop]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  content: {
    ...content,
    paddingLeft: 0,
    paddingRight: 0,
    [adaptive]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [desktop]: {
      minHeight: 'inherit',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  centered: {
    [desktop]: {
      ...centered,
      minHeight: 'inherit',
    },
  },
}

const DesktopWrapperHOC = (Component) => injectSheet(styles)(
  ({
    classes,
    ...props
  }) => {
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.centered}>
            <div className={classes.wrapper}>
              <Component {...props} />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default DesktopWrapperHOC