import React from 'react'
import injectSheet from 'react-jss'
import { InputPhone } from '@verdaccio/mt-uikit'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(15),
    lineHeight: rem(22),
    color: theme.colors.black,
    textAlign: 'center',
    '& input.form-control[disabled]': {
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
      color: theme.colors.black,
      fontSize: rem(15),
      lineHeight: rem(22),
      textAlign: 'center',
    },
  },
}

const Phone = ({ classes, children }) =>
  <div className={classes.root}>
    <InputPhone
      name='phone'
      type='tel'
      value={children}
      disabled
    />
  </div>

export default injectSheet(styles)(Phone)