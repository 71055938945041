import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(16),
    '& > div': {
      fontSize: rem(15),
      lineHeight: rem(20),
      color: theme.colors.blue,
      fontFamily: theme.fontFamilies.SFProRoundedSemibold,
      display: 'inline-block',
      verticalAlign: 'top',
      cursor: 'pointer',
    },
  },
}

const Reset = ({
  classes,
  onClick,
}) =>
  <div className={classes.root}>
    <div onClick={onClick}>Clear</div>
  </div>

export default injectSheet(styles)(Reset)