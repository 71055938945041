import React, { useState } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop, adaptive } from './../../../theme/base'
import { SlideUpDropDownAnimation } from './../../../components/Animation'
import SubjectsList from './../../../components/SubjectsList'
import { Button, Input } from '@verdaccio/mt-uikit'
import TutorsList from './../../TutorsList'
import { MAX_TITLE_LENGTH, SUBJECTS, TOP_SUBJECTS } from './../../../config'
import DesktopWrapperHOC from './.././../DesktopWrapperHOC'

const styles = {
  root: {
    minHeight: rem(529),
    display: 'flex',
    flexDirection: 'column',
  },
  tutors: {
    flexGrow: 1,
  },
  submit: {
    background: theme.colors.white,
    padding: rem(24),
    marginBottom: 'auto',
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    [desktop]: {
      background: 'transparent',
    },
    [adaptive]: {
      marginTop: 0,
      padding: rem(24),
      position: 'fixed',
    },
  },
}

const validateSubject = (subject) => {
  const ALL_SUBJECTS = SUBJECTS.concat(TOP_SUBJECTS)
  return ALL_SUBJECTS.indexOf(subject) > -1
}

const OrderType = ({
  classes,
  title,
  subject,
  tutors,
  requestedTutor,
  onChangeTitle,
  onChangeSubject,
  onChangeRequestedTutorId,
  onSubmit
}) => {
  const [validTitle, setValidTitle] = useState(!!title)
  const [validSubject, setValidSubject] = useState(validateSubject(subject))
  const [submittedForm, setSubmittedForm] = useState(false)
  const [showSubjects, setShowSubjects] = useState(false)
  const validForm = validTitle && validSubject
  return <div className={classes.root}>
    <Input
      placeholder='Name your task'
      value={title}
      onChange={(value) => {
        setValidTitle(!!value.trim())
        onChangeTitle(value)
      }}
      autoFocus
      status={`${title.length}/${MAX_TITLE_LENGTH}`}
      maxLength={MAX_TITLE_LENGTH}
      error={submittedForm && !validTitle}
    />
    <Input
      placeholder='Select your subject name'
      value={subject}
      onChange={(value) => {
        setValidSubject(!!value.trim() && validateSubject(value))
        onChangeSubject(value)
      }}
      onFocus={() => setShowSubjects(true)}
      onClickOutside={() => setShowSubjects(false)}
      icon='search'
      error={submittedForm && !validSubject}
    >
      {showSubjects &&
        <SlideUpDropDownAnimation>
          <SubjectsList
            search={subject}
            onClick={value => {
              setValidSubject(!!value.trim())
              onChangeSubject(value)
              setShowSubjects(false)
            }}
          />
        </SlideUpDropDownAnimation>
      }
    </Input>
    <div className={classes.tutors}>
      <TutorsList
        subject={subject}
        tutors={tutors}
        requestedTutor={requestedTutor}
        onChangeRequestedTutorId={onChangeRequestedTutorId}
      />
    </div>
    <div className={classes.submit}>
      <Button
        block
        size='large'
        disabled={!validForm && submittedForm}
        onClick={() => validForm && onSubmit() || setSubmittedForm(true)}
      >
        Connect a tutor
      </Button>
    </div>
  </div>
}

export default DesktopWrapperHOC(injectSheet(styles)(OrderType))