import random from 'fast-random'

const roundUpToNearest = (value, step) => value + ((step - (value % step)) % step)

const getTimestampRoundedToNHours = (n) => {
  const utcTimestamp = new Date().getTime()
  const nHoursMillis = n * 3600000
  const roundedToNHours = roundUpToNearest(utcTimestamp, nHoursMillis)
  return roundedToNHours
}

const getStringHash = (s) => {
  const str = `${s || ''}`
  return str.split('').reduce((a, b) => {
    const res = ((a << 5) - a) + b.charCodeAt(0)
    return res & res
  }, 0)
}

const getGeekCount = (from, to, subject) => {
  const threeHourValue = getTimestampRoundedToNHours(3)

  const r = random(threeHourValue + getStringHash(subject))
  return from + r.nextInt() % (to - from + 1)
}

const getGeekNumber = subject => (!subject ? getGeekCount(49, 120) : getGeekCount(49, 120, subject))

const getStudentNumber = () => {
  const sevenDaysValue = getTimestampRoundedToNHours(168)
  const from = 150
  const to = 310
  const r = random(sevenDaysValue)
  return from + r.nextInt() % (to - from + 1)
}

export { getGeekNumber, getStudentNumber }