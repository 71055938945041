import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../../theme/base'

export default injectSheet({
  root: {
    padding: [rem(17), rem(56)],
    color: theme.colors.charcoalGrey,
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: rem(0.11),
    textAlign: 'center',
  }
})(({
  classes,
  children
}) =>
  <div className={classes.root}>
    {children}
  </div>
)
