import React from 'react'
import injectSheet from 'react-jss'
import { utils } from '@verdaccio/mt-uikit'
import { rem, adaptive, desktop, media } from './../../../theme/base'
import DesktopWrapperHOC from './../../DesktopWrapperHOC'
import { Banner, Block, Title, Subtitle, Message, Submit, SaveAndClose } from './../Layout'
import SelectDeadline from '../SelectDeadline'
import SelectSize from './../SelectSize'
import { DEADLINE_SET, checkRestriction } from '../../../nerdytutors'

const styles = {
  root: {
    paddingTop: rem(24),
    paddingBottom: 0,
    [desktop]: {
      minHeight: rem(529),
    },
    [adaptive]: {
      paddingBottom: rem(290),
    },
  },
  content: {
    paddingLeft: rem(24),
    paddingRight: rem(24),
    position: 'relative',
  },
  deadline: {
    [adaptive]: {
      marginBottom: rem(24),
    },
  },
}

const OrderCalculate = ({
  classes,
  deadline,
  minDeadline,
  deadlineset,
  onChangeDeadline,
  size,
  sizeset,
  sizedefaultto,
  onChangeSize,
  total,
  price,
  banner,
  onSubmit,
  onSupport,
  onSaveOrderForce,
}) => {
  const restriction = checkRestriction(size, deadline)
  const isMobile = utils.useMobile(media.desktop)
  return <>
    <div className={classes.root}>
      <div className={classes.content}>
        <Block>
          <Title>How big is your task?</Title>
          <Subtitle>
            {`e.g. it takes <span>${utils.sizeToHumans(size)}</span> 
              to provide <span>${utils.sizeToWords(size)}</span>
              of written notes`}
          </Subtitle>
          <SelectSize
            size={size}
            items={sizeset}
            sizedefaultto={sizedefaultto}
            onChange={onChangeSize}
            resettosize={1}
          />
        </Block>
        <Block className={classes.deadline}>
          <Title>By when do you need your task?</Title>
          {restriction && deadline
            ? <Message>Please, give us more time to provide <span>{utils.sizeToHumans(size)}</span> of help</Message>
            : <Subtitle>
              {deadline !== null
                ? DEADLINE_SET.indexOf(deadline) > -1
                  ? `Your task will be completed by <span>${utils.printDueDateCustom(deadline)}</span>`
                  : `Your task will be completed on time!`
                : `Due date when your task has to be successfully completed`
              }
            </Subtitle>
          }
          <SelectDeadline
            deadline={deadline}
            minDeadline={minDeadline}
            size={size}
            deadlineset={deadlineset}
            onChange={onChangeDeadline}
          />
        </Block>
        {isMobile &&
          <Banner
            title={banner.title}
            description={banner.text}
            image={require('./banner.svg')}
            onSupport={onSupport}
          />
        }
        {price && !restriction &&
          <Submit
            text='Go to checkout'
            total={total}
            price={price}
            onSubmit={onSubmit}
          />
        }
      </div>
      <SaveAndClose onClick={onSaveOrderForce} />
    </div>
  </>
}

export default DesktopWrapperHOC(injectSheet(styles)(OrderCalculate))