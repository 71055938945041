import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Slider from 'react-slick'
import theme, { rem, content, ui, adaptive, MODAL_WIDTH_DESKTOP } from '../../theme/base'
import ProgressBar from './ProgressBar'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(64), 0, rem(16)],
    textAlign: 'center',
    position: 'relative',
    overflowX: 'hidden',
    [adaptive]: {
      paddingTop: rem(24),
      paddingLeft: rem(8),
      paddingRight: rem(8),
    },
  },
  content,
  title: {
    ...ui.title,
    marginTop: rem(80),
    [adaptive]: {
      ...ui.title[adaptive],
      marginTop: rem(64),
    },
  },
  subtitle: {
    ...ui.subtitle,
    marginTop: rem(12),
  },
  text: {
    ...ui.text,
    marginTop: rem(16),
    '&.custom': {
      marginTop: rem(8),
    },
  },
  bar: {
    background: theme.colors.paleGrey,
    maxWidth: MODAL_WIDTH_DESKTOP,
    height: rem(8),
    margin: [rem(8), 'auto', 0],
    borderRadius: rem(4),
    position: 'relative',
    '&:after': {
      content: '""',
      width: '83%',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      display: 'block',
      backgroundImage: `linear-gradient(to left, ${theme.colors.blue}, #6fb0ff 88%)`,
      borderRadius: rem(4),
    },
  },
  random: {
    width: rem(88),
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    '& > div': {
      width: rem(40),
      height: rem(40),
      display: 'inline-block',
      verticalAlign: 'top',
      '&:nth-child(1)': {
        width: rem(32),
        height: rem(32),
        position: 'absolute',
        left: 0,
        bottom: rem(3),
        zIndex: 1,
      },
      '&:nth-child(2)': {
        position: 'relative',
        zIndex: 2,
        '&.offline': {
          '&:after': {
            display: 'none',
          },
        },
        '&:after': {
          content: '""',
          width: rem(12),
          height: rem(12),
          border: [rem(1), 'solid', theme.colors.white],
          borderRadius: '50%',
          background: '#6dd400',
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
      '&:nth-child(3)': {
        width: rem(32),
        height: rem(32),
        position: 'absolute',
        right: 0,
        bottom: rem(3),
        zIndex: 1,
      },
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: '50%',
      display: 'block',
    },
  },
  slider: {
    minHeight: rem(507),
    margin: [rem(40), 'auto', 0],
    position: 'relative',
    [adaptive]: {
      marginTop: 0,
    },
    '& > img': {
      width: '100%',
      maxWidth: rem(369),
      height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
    '& .slick-slider': {
      paddingBottom: rem(48),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .slick-track': {
      '&:before': {
        content: '""',
        display: 'table',
      },
      '&:after': {
        content: '""',
        display: 'table',
        clear: 'both',
      },
    },
    '& .slick-slide': {
      float: 'left',
      position: 'relative',
      '& img': {
        width: '86.4vw',
        maxWidth: rem(324),
        height: 'auto',
        display: 'block',
      },
    },
    '& .slick-list': {
      width: '100%',
      height: 'auto',
    },
  },
}

const SLIDES = [
  'slide-01', 
  'slide-02', 
  'slide-03', 
  'slide-04', 
  'slide-05',
]

const animationProps = {
  speed: 500,
  easing: 'ease-in-out',
}

const OPTIONS = {
  ...animationProps,
  centerPadding: 0,
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true,
  prevArrow: <div />,
  nextArrow: <ProgressBar {...animationProps} />,
}

const Next = ({
  subtitle, 
  text, 
  offline,
  classes, 
}) =>
  <div className={classes.root}> 
    <div className={classes.content}>
      <div className={classes.random}>
        <div>
          <img src={require('./user-1@3x.png')} alt='' />  
        </div>
        <div className={classnames({ offline })}>
          <img src={require('./user-2@3x.png')} alt='' />  
        </div>
        <div>
          <img src={require('./user-3@3x.png')} alt='' />  
        </div>
      </div>
      {subtitle &&
        <div className={classes.subtitle}>{subtitle}</div>
      }
      <div 
        className={classnames(classes.text, { 
          'custom': subtitle, 
        })} 
        dangerouslySetInnerHTML={{__html: text}} 
      />
      {subtitle &&
        <div className={classes.bar} />
      }
      <div className={classes.title}>Everyone gets stuck once in a while, what's next?</div>
      <div className={classes.text}>Here is how Collaborative Tech can get you back on track</div>
      <div className={classes.slider}>
        <img src={require('./phone-frame.svg')} alt='' />
        <Slider {...OPTIONS}>
          {SLIDES.map((item, index) =>
            <img
              key={`next-slider-item-${index}`}
              src={require(`./${item}@1x.png`)}
              srcSet={`
                ${require(`./${item}@3x.png`)} 3x, 
                ${require(`./${item}@2x.png`)} 2x, 
                ${require(`./${item}@1x.png`)} 1x
              `}
              alt=''
            />
          )}
        </Slider>
      </div>
    </div>
  </div>

export default injectSheet(styles)(Next)