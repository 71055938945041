import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { Button, utils } from '@verdaccio/mt-uikit'
import theme, { rem, desktop } from './../../../theme/base'
import DesktopWrapperHOC from './../../DesktopWrapperHOC'
import { Block, Title } from './../Layout'
import SelectSize from './../SelectSize'
import { getPrice } from './../../../nerdytutors'

const styles = {
  root: {
    padding: rem(24),
    position: 'relative',
  },
  submit: {
    marginTop: rem(16),
    background: theme.colors.white,
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: rem(24),
    zIndex: 2,
    [desktop]: {
      borderRadius: [0, 0, rem(16), rem(16)],
    },
    '& button': {
      paddingRight: rem(112),
      textAlign: 'left',
      position: 'relative',
    },
    '& span': {
      '&:first-child': {
        padding: [0, rem(24)],
        fontWeight: 400,
        fontFamily: theme.fontFamilies.SFProRoundedRegular,
      },
      '&:last-child': {
        display: 'block',
        position: 'absolute',
        top: rem(8),
        bottom: rem(8),
        right: 0,
        width: rem(112),
        textAlign: 'center',
        lineHeight: 'normal',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          background: theme.colors.white,
          width: rem(1),
          opacity: 0.2,
        },
      },
    },
  }
}

const BuyExtraHours = ({
  classes,
  sizeset,
  sizedefaultto,
  balance,
  deadline,
  onSubmit
}) => {
  const [extra, setExtra] = useState(null)
  const { price } = extra !== null ? getPrice(deadline, extra, balance) : { price: null, total: null }
  const _onSubmit = () => onSubmit({ size: extra, price })
  return <div className={classes.root}>
    <Block>
      <Title>How many hours do you want to add?</Title>
      <SelectSize
        size={extra}
        items={sizeset}
        sizedefaultto={sizedefaultto}
        onChange={setExtra}
      />
    </Block>
    {price != null &&
      <div className={classes.submit}>
        <Button block size='large' onClick={_onSubmit}>
          <span>Buy {utils.sizeToHumans(extra)}</span>
          <span>{utils.printAmount(price)}</span>
        </Button>
      </div>
    }
  </div>
}

export default DesktopWrapperHOC(injectSheet(styles)(BuyExtraHours))