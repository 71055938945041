import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import { rem } from '../../../theme/base'

const styles = {
  root: {
    marginTop: rem(16),
  },
}

const Submit = ({ 
  classes,
  className,
  children,
  onClick,
  disabled,
}) => 
  <Button
    className={classnames(classes.root, className)}
    size='large'
    block
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
        
export default injectSheet(styles)(Submit)