import React, { useState, useRef, useEffect } from 'react'
import { Button } from '@verdaccio/mt-uikit'
import injectSheet from 'react-jss'
import theme, { desktop, MODAL_WIDTH_DESKTOP, rem } from './../../theme/base'
import { OTHER_CLAIM } from '../../config'

import Info from './Info'
import Header from './Header'
import Rate from './Rate'

const styles = {
  root: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  body: {
    height: `calc(100% - ${rem(89)})`,
    padding: [rem(16), 0],
    overflowY: 'auto',
    position: 'relative',
    paddingBottom: rem(104),
  },
  footer: {
    background: theme.colors.white,
    position: 'absolute',
    padding: [rem(24), rem(16)],
    bottom: 0,
    left: 0,
    right: 0,
  },
}

const TutorRate = ({
  onClose,
  onMore,
  order,
  defaultrate,
  classes,
  onSubmit,
}) => {
  const ref = useRef()
  const [rate, setRate] = useState(defaultrate)
  const [claims, setClaims] = useState([])
  const [other, setOther] = useState('')
  useEffect(() => {
    if (claims.indexOf(OTHER_CLAIM) > -1) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [claims])
  const result = {
    rate,
    claims,
    other,
  }
  const rateProps = {
    ...result,
    onChangeRate: rate => setRate(parseInt(rate)),
    onChangeClaims: claims => setClaims(claims),
    onChangeOther: other => setOther(other),
  }
  return (
    <div className={classes.root}>
      <Header {...order.tutor} onClose={onClose} onMore={onMore} />
      <div className={classes.body} ref={ref}>
        <Info {...order.tutor} />
        <Rate {...rateProps} />
      </div>
      <div className={classes.footer}>
        <Button
          size='large'
          block
          onClick={() => {
            onClose()
            onSubmit({ order, rate: result })
          }}
        >
          Submit Feedback
        </Button>
      </div>
    </div>
  )
}

export default injectSheet(styles)(TutorRate)
