import getRandomProfileAvatar from './utils/getRandomProfileAvatar'
import { utils } from '@verdaccio/mt-uikit'

utils.configUserAvatarUrl(ENV.AVATAR_CDN)

// host: 'ejabberd',
// ws_url: 'ws://localhost/ws',
// service: 'tutorbot@ejabberd',
export const XMPP_CONFIG = {
  host: ENV.XMPP_HOST,
  ws_url: ENV.XMPP_WS_URL,
  service: ENV.XMPP_SERVICE,
}

export const MIN_CHARS_TO_AVAILABLE_SEARCH = 0
export const MIN_CHARS_TO_DEFAULT_SEARCH = 7
export const MAX_TITLE_LENGTH = 36

const TUTORS = [
  {
    id: 0,
    name: 'Donald R.',
    stars: '4.5',
    university: 'Masters at CalTech',
    students: '29',
    image: require('./assets/images/tutors/Donald.jpg'),
  },
  {
    id: 1,
    name: 'Curtis L.',
    stars: '5',
    university: 'Ph.D. at Brown',
    students: '48',
    image: require('./assets/images/tutors/Curtis.jpg'),
  },
  {
    id: 2,
    name: 'Dennis D.',
    stars: '4.5',
    university: 'Masters at Rice',
    students: '89',
    image: require('./assets/images/tutors/Dennis.jpg'),
  },
  {
    id: 3,
    name: 'Jason P.',
    stars: '4.5',
    university: 'Ph.D. at Vanderbilt',
    students: '110',
    image: require('./assets/images/tutors/Jason.jpg'),
  },
  {
    id: 4,
    name: 'Kelsey W.',
    stars: '5',
    university: 'Ph.D. at Emory',
    students: '33',
    image: require('./assets/images/tutors/Kelsey.jpg'),
  },
  {
    id: 5,
    name: 'Margaret A.',
    stars: '5',
    university: 'Masters at UCLA',
    students: '53',
    image: require('./assets/images/tutors/Margaret.jpg'),
  },
  {
    id: 6,
    name: 'Mei L.',
    stars: '4.5',
    university: 'Ph.D. at Tufts',
    students: '94',
    image: require('./assets/images/tutors/Mei.jpg'),
  },
  {
    id: 7,
    name: 'Patricia K.',
    stars: '5',
    university: 'Masters at Brandeis',
    students: '112',
    image: require('./assets/images/tutors/Patricia.jpg'),
  },
  {
    id: 8,
    name: 'Sarah P.',
    stars: '5',
    university: 'Ph.D. at NYU',
    students: '37',
    image: require('./assets/images/tutors/Sarah.jpg'),
  },
  {
    id: 9,
    name: 'Spencer W.',
    stars: '5',
    university: 'Ph.D. at Carnegie Mellon',
    students: '58',
    image: require('./assets/images/tutors/Spencer.jpg'),
  },
  {
    id: 10,
    name: 'Leslie S.',
    stars: '5',
    university: 'Masters at Northwestern',
    students: '99',
    image: require('./assets/images/tutors/Leslie.jpg'),
  },
  {
    id: 11,
    name: 'Manuel R.',
    stars: '4.5',
    university: 'Ph.D. at Dartmouth',
    students: '109',
    image: require('./assets/images/tutors/Manuel.jpg'),
  },
  {
    id: 12,
    name: 'Victoria J.',
    stars: '4.5',
    university: 'Ph.D at Berkeley',
    students: '41',
    image: require('./assets/images/tutors/Victoria.jpg'),
  },
  {
    id: 13,
    name: 'Shan B.',
    stars: '4.5',
    university: 'Masters at Chapel Hill',
    students: '61',
    image: require('./assets/images/tutors/Shan.jpg'),
  },
  {
    id: 14,
    name: 'Niam K.',
    stars: '5',
    university: 'Ph.D. at Duke',
    students: '87',
    image: require('./assets/images/tutors/Niam.jpg'),
  },
  {
    id: 15,
    name: 'Miguel C.',
    stars: '5',
    university: 'Masters at Georgetown',
    students: '107',
    image: require('./assets/images/tutors/Miguel.jpg'),
  },
  {
    id: 16,
    name: 'Renee R.',
    stars: '4.5',
    university: 'Masters at JHU',
    students: '45',
    image: require('./assets/images/tutors/Renee.jpg'),
  },
  {
    id: 17,
    name: 'Oscar J.',
    stars: '5',
    university: 'Masters at Ole Miss',
    students: '67',
    image: require('./assets/images/tutors/Oscar.jpg'),
  },
  {
    id: 18,
    name: 'Luis T.',
    stars: '5',
    university: 'Masters at TAMU',
    students: '59',
    image: require('./assets/images/tutors/Luis.jpg'),
  },
  {
    id: 19,
    name: 'Jorge V.',
    stars: '5',
    university: 'Masters at UChicago',
    students: '101',
    image: require('./assets/images/tutors/Jorge.jpg'),
  },
  {
    id: 20,
    name: 'Gary V.',
    stars: '4.5',
    university: 'Ph.D. at UCSB',
    students: '49',
    image: require('./assets/images/tutors/Gary.jpg'),
  },
  {
    id: 21,
    name: 'April L.',
    stars: '5',
    university: 'Ph.D. at Rice',
    students: '71',
    image: require('./assets/images/tutors/April.jpg'),
  },
  {
    id: 22,
    name: 'Allison A.',
    stars: '5',
    university: 'Ph.D. at UCLA',
    students: '55',
    image: require('./assets/images/tutors/Allison.jpg'),
  },
  {
    id: 23,
    name: 'Johnny C.',
    stars: '4.5',
    university: 'Masters at Brown',
    students: '93',
    image: require('./assets/images/tutors/Johnny.jpg'),
  },
  {
    id: 24,
    name: 'Jamie P.',
    stars: '4.5',
    university: 'Masters at UC Davis',
    students: '31',
    image: require('./assets/images/tutors/Jamie.jpg'),
  },
  {
    id: 25,
    name: 'Mark K.',
    stars: '4.5',
    university: 'Ph.D at UCLA',
    students: '96',
    image: require('./assets/images/tutors/Mark.jpg'),
  },
  {
    id: 26,
    name: 'Maggie D.',
    stars: '5',
    university: 'Masters at SFSU',
    students: '27',
    image: require('./assets/images/tutors/Maggie.jpg'),
  },
  {
    id: 27,
    name: 'Travis S.',
    stars: '4.5',
    university: 'Masters at UPenn',
    students: '59',
    image: require('./assets/images/tutors/Travis.jpg'),
  },
]

export const getRandomTutors = (count = 1) => {
  const result = []
  const randomId = utils.getRandom(0, TUTORS.length - 1, count)
  for (let i = 0; i < randomId.length; i += 1) {
    result.push(TUTORS[randomId[i]])
  }
  return result
}

export const TOP_SUBJECTS = [
  'English',
  'Business and Management',
  'Healthcare and Nursing',
  'History',
  'Sociology',
]

export const SUBJECTS = [
  'Academic Writing',
  'Aerodynamics',
  'Aerospace Engineering',
  'Agriculture, Fisheries and Food',
  'Algebra',
  'Analytics',
  'Anthropology',
  'Applied Mathematics',
  'Archaeology',
  'Art',
  'Art History',
  'Artificial Intelligence',
  'Astronomy',
  'Atmospheric Science',
  'Atomic Physics',
  'Behavioral Psychology',
  'Biology',
  'Biology and Life Sciences',
  // 'Business and Management',
  'Calculus',
  'Chemical Engineering',
  'Chemistry',
  'Civil Engineering',
  'Classical Music',
  'Cognitive Psychology',
  'Communication Studies',
  'Computer Science',
  'Creative Writing',
  'Criminology',
  'Culture',
  'Data Analytics',
  'Descriptive Analytics',
  'Descriptive Statistics',
  'Design and Architecture',
  'Ecology',
  'Economics',
  'Education',
  'Electrical Engineering',
  'Engineering',
  // 'English',
  'Environmental Science',
  'Ethics',
  'Experimental Psychology',
  'Finance, Accounting and Banking',
  'Forestry',
  'Game Theory',
  'Genetics and Heredity',
  'Geography',
  'Geology',
  'Geometry and Topology',
  'Governmental Studies',
  // 'Healthcare and Nursing',
  // 'History',
  'History and Anthropology',
  'History of Literature',
  'HRM',
  'Immunology',
  'Inferential Statistics',
  'Information Theory',
  'International Business',
  'International Economics',
  'International Politics',
  'International Relations',
  'Introduction to Visual Arts',
  'IT',
  'IT Security',
  'Journalism',
  'Labor and Employee Relations',
  'Law and International Law',
  'Life Sciences',
  'Linguistics',
  'Literature',
  'Logic',
  'Managerial Analytics',
  'Macroeconomics',
  'Marketing and PR',
  'Maths',
  'Mechanical Engineering',
  'Microbiology',
  'Microeconomics',
  'Military Studies',
  'Modern Physics',
  'Molecular Biology',
  'Morphology',
  'Music',
  'Music History',
  'Network Engineering',
  'Neuroscience',
  'Nuclear Physics',
  'Number Theory',
  'Nursing Theory',
  'Nutrition',
  'Organization Development',
  'Performance Arts',
  'Philosophy',
  'Phonology',
  'Physics',
  'Physiology',
  'Plant Sciences',
  'Political Behavior',
  'Political Economy',
  'Political Methodology',
  'Political Science',
  'Political Sociology',
  'Probability and Statistics',
  'Programming',
  'Project Management',
  'Psycholinguistics',
  'Psychology',
  'Public Health',
  'Religion',
  'Semantics and Pragmatics',
  // 'Sociology',
  'Sociology of Economy',
  'Software Engineering',
  'Statistics',
  'Syntax',
  'Theology',
  'Theories of Modern Art',
  'Thermodynamics',
  'Visual Arts',
  'Web Development',
]

export const S3 = {
  keyStartsWith: 'nerdytutor/',
  awsaccesskeyid: 'AKIAIB5UGG2E4IWMXAVQ',
  acl: 'public-read',
  policy: 'CnsgImV4cGlyYXRpb24iOiAiMjAzMC0xMi0wMVQxMjowMDowMC4wMDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogIm5lcmR5dHV0b3JpbmciIH0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRrZXkiLCAibmVyZHl0dXRvci8iXSwKICAgIHsiYWNsIjogInB1YmxpYy1yZWFkIiB9CiAgXQp9Cg==',
  signature: '2o/bVaI0ikohVAzv3rDnp4psP+0=',
  host: 'https://nerdytutoring.s3.amazonaws.com/',
  cdn: 'https://d15uuylzi8itni.cloudfront.net',
}

export const REPORT = [
  {
    value: 'dishonesty',
    title: 'Academic dishonesty',
    text: `Tutor does not support requesting or providing pre-written 
      or custom written academic assignment. Or faking the student\'s 
      identity for any purpose including test or exam taking.`,
  },
  {
    value: 'plagiarism',
    title: 'Plagiarism',
    text: `Tutor does not allow using the work of another person without 
      proper attribution.`,
  },
  {
    value: 'nudity',
    title: 'Nudity',
    text: `Tutor sharing or publishing the content depicting nudity or other 
      sexually explicit material.`,
  },
  {
    value: 'illegal_activities',
    title: 'Illegal activities',
    text: `Tutor is engaged in any kind of activities that violate the law or 
      corporate policies. We have zero tolerance policy for facilitating drug 
      use, weapons, bidding or gambling.`,
  },
  {
    value: 'harassment',
    title: 'Illegal Harassment',
    text: `Tutor is sharing or publishing the content that promotes hatred or 
      violence, threatens, harasses or bullies other people.`,
  },
  {
    value: 'private',
    title: 'Private and confidential data',
    text: `Tutor is asking for your confidential data, including passwords, 
      CC details, bank account numbers or similar types of private information.`,
  },
]

export const FAQ = [
  {
    icon: 'contextMenu-support',
    title: 'How it works?',
    content: 'You send a brief description of what you need help with or simple make a photo of the task you are stuck on. Choose how quickly you need help from tutor and how much time a tutor should spend on it. Now you are all set! We will find the best matching tutor for your problem to meet your deadline.',
  },
  {
    icon: 'contextMenu-support',
    title: 'What if time goes out?',
    content: 'If tutor needs more time to help you with your request she will let you know at the very beginning. Otherwise you can assume that the time is sufficient. You can always extend your tutoring time. Also remember that you can chat to you tutor for up to 24 hours after you are done with your request to clarify anything. Its free!',
  },
  {
    icon: 'contextMenu-support',
    title: 'With what type of tasks a tutor can help?',
    content: 'Tutor can help with any type of task, homework or topic as long as it doesn\'t violate college policies. We have tutors in almost every topic, so give it a shot and we are confident that our tutors can help.',
  },
  {
    icon: 'contextMenu-support',
    title: 'How to cancel request?',
    content: 'You can request a cancelation or a refund from our Customer Support. They will be glad to assist.',
  },
  {
    icon: 'contextMenu-support',
    title: 'How much does it cost?',
    content: 'Our tutors charge per hour. The minimum amount of time you can request is 30 minutes. The price depends on how quickly need help with and starts from hist $12.5 for 30 minutes.',
  },
]

export const PAGES = [
  { name: 'Terms of Use', link: '/terms-and-conditions' },
  { name: 'Privacy Policy', link: '/privacy-policy' },
]

export const PROFILE_AVATARS = [
  'animal-avatar-1-1.svg',
  'animal-avatar-1-2.svg',
  'animal-avatar-2-1.svg',
  'animal-avatar-2-2.svg',
  'animal-avatar-3-1.svg',
  'animal-avatar-3-2.svg',
  'animal-avatar-4-1.svg',
  'animal-avatar-4-2.svg',
  'animal-avatar-5-1.svg',
  'animal-avatar-5-2.svg',
  'animal-avatar-6-1.svg',
  'animal-avatar-6-2.svg',
  'animal-avatar-7-1.svg',
  'animal-avatar-7-2.svg',
  'animal-avatar-8-1.svg',
  'animal-avatar-8-2.svg',
  'animal-avatar-9-1.svg',
  'animal-avatar-9-2.svg',
]

export const PROFILE_AVATAR_URL = getRandomProfileAvatar()

export const OTHER_CLAIM = 'Other'
export const CLAIMS = ['Content quality', 'Delivery time', 'Formatting', 'Plagiarism', 'Attentiveness to details', OTHER_CLAIM]