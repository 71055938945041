import React from 'react'
import injectSheet from 'react-jss'
import { TAB_CHAT, TAB_TIMELINE } from './tabs'
import ChatNavbar from 'components/ChatNavbar'
import { rem } from '../../theme/base'

const styles = {
  root: {
    maxHeight: '100%',
    position: 'absolute',
    top: rem(56),
    bottom: 0,
    left: 0,
    right: 0,
  },
  timeline: {
    paddingTop: rem(214),
  }
}

const OrderChatMobile = ({
  classes,
  order,
  onMore,
  onOrderDetails,
  tabs,
  tab,
  renderChat,
  renderTimeline,
}) => <div className={classes.root}>
    <ChatNavbar
      simple
      order={order}
      onMore={onMore}
      onOrderDetails={onOrderDetails}
      tabs={tabs}
      info={tab === TAB_TIMELINE}
    />
    {tab === TAB_CHAT && renderChat()}
    {tab === TAB_TIMELINE && renderTimeline({ className: classes.timeline })}
  </div>

export default injectSheet(styles)(OrderChatMobile)
