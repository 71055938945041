import React from 'react'
import { useSelector } from 'react-redux'
import { getOrderNodeName } from '@verdaccio/mt-uikit'
import { useParams, Redirect } from 'react-router-dom'
import { getPaymentLink } from './../../api'
import { ordersSelector, ORDER } from './../../nerdytutors'

import Failed from './Failed'
import Success from './Success'

const HOC = Component => props => {
  const { orderid, orderState } = useParams()

  const orders = useSelector(ordersSelector)
  const nodeName = getOrderNodeName(orderid)
  const order = orders.find(order => order.id === nodeName)

  const defaultStage = {
    [ORDER.STATE.DRAFT]: ORDER.STAGES.DRAFT.CHECKOUT,
    [ORDER.STATE.EXTEND]: ORDER.STAGES.EXTEND.BUYEXTRAHOURS
  }[orderState]

  const retryPaymentUrl = getPaymentLink(orderid, orderState)
  const backToCheckoutUrl = `/order/${orderState}`
  const successPaymentUrl = `/order/${orderid}/timeline`

  const state = { order, defaultStage, orders }

  const paymentProps = {
    retryPaymentUrl,
    backToCheckoutUrl,
    successPaymentUrl,
    orderid,
    state
  }

  return <Component {...props} {...paymentProps} />
}

const Cancel = ({ backToCheckoutUrl, state }) => <Redirect to={{ pathname: backToCheckoutUrl, state }} />

export default {
  Failed: HOC(Failed),
  Success: HOC(Success),
  Cancel: HOC(Cancel),
}
