import React, { Fragment, useState } from 'react'
import injectSheet from 'react-jss'
import { Button, Textarea, Modal, Uploader } from '@verdaccio/mt-uikit'
import { desktop, MODAL_WIDTH_DESKTOP, rem } from './../../theme/base'
import { S3 } from './../../config'
import { useTrackValues } from './../../lrtracker'

const styles = {
  root: {
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  textarea: {
    marginTop: rem(16),
  },
}

const FormDetails = ({
  classes,
  placeholder,
  header,
  button,
  onSubmit,
  onClose,
  ...form
}) => {
  const [details, setDetails] = useState(form.details || '')
  const [files, setFiles] = useState(form.files || [])
  const [fileUploading, setFileUploading] = useState(false)
  const isValid = details.trim() && !fileUploading

  const { id, toid } = form
  const trackValuesContext = { toid, stage: `checkout` }
  useTrackValues(details, 'details', { context: trackValuesContext, predicat: value => !id && value.length > 0 })
  useTrackValues(files.length, 'files', { context: trackValuesContext, predicat: value => !id && value > 0 })

  return (
    <div className={classes.root}>
      <Modal.Header onClose={onClose}>{header}</Modal.Header>
      <Modal.Content>
        <Textarea
          placeholder={placeholder}
          minRows={4}
          value={details}
          onChange={e => setDetails(e.target.value)}
          className={classes.textarea}
        />
        <Uploader
          config={S3}
          files={files}
          onChange={setFiles}
          onStatusChange={status => setFileUploading(status)}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          disabled={!isValid}
          size='large'
          block
          onClick={() => {
            onSubmit({ details, files })
            onClose()
          }}
        >
          {button}
        </Button>
      </Modal.Footer>
    </div>
  )
}

export default injectSheet(styles)(FormDetails)