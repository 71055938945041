import React from 'react'
import { withRouter } from 'react-router'
import { useActions } from './../../actions'
import Header from './../../components/Header'

export default withRouter(({
  history,
  ...otherProps
}) => {
  const actions = useActions()
  const { pathname } = history.location

  return <Header
    onMenu={() => actions.onMenu(pathname)}
    onSupport={() => actions.onSupport()}
    {...otherProps}
  />
})
