import React, { useRef } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop, MODAL_WIDTH_DESKTOP } from './../../../../theme/base'
import { useOutsideClick } from '@verdaccio/mt-uikit'
import Header from './Header'
import Block from './Block'
import moment from 'moment'

const styles = {
  root: {
    background: theme.colors.white,
    width: '100vw',
    paddingBottom: rem(24),
    borderRadius: rem(16),
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    boxShadow: `
      0 ${rem(8)} ${rem(40)} rgba(0, 53, 136, 0.15),
      0 ${rem(8)} ${rem(12)} rgba(0, 38, 96, 0.06)
    `,
    transform: `translateY(${rem(8)})`,
    whiteSpace: 'normal',
    zIndex: 4,
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
      top: 'auto',
      left: '50%',
      right: 'auto',
      bottom: `calc(10vh - ${rem(28)})`,
      transform: 'translateX(-50%)',
    },
  },
}

const isDayTime = ({ h }) => h >= 8 && h <= 19
const isNightTime = ({ h }) => !isDayTime({ h })

const getHoursRange = (date, minDate) => [
  8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, // day time
  20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7        // night time 
].map(h => {
  const hour = moment(date).startOf('day').add(h, 'h')
  return {
    h,
    value: hour.toDate(),
    label: hour.format('h A'),
    disabled: hour < minDate,
    active: moment(date).hours() === hour.hours()
  }
})

const Timepicker = ({
  classes,
  date,
  minDate,
  onChange,
  onClose,
}) => {
  const outsideClickRef = useRef(null)
  useOutsideClick(outsideClickRef, onClose)
  const hoursRange = getHoursRange(date, minDate)
  return (
    <div className={classes.root} ref={outsideClickRef}>
      <Header
        title='Set time'
        onSet={onClose}
      />
      <Block
        title='Day time'
        icon='sun'
        items={hoursRange.filter(isDayTime)}
        onClick={onChange}
      />
      <Block
        title='Night time'
        icon='moon'
        items={hoursRange.filter(isNightTime)}
        onClick={onChange}
      />
    </div>
  )
}

export default injectSheet(styles)(Timepicker)