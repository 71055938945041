import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router'
import { useSelector } from 'react-redux'
import { utils, selectors } from '@verdaccio/mt-uikit'
import { isEqual } from 'lodash/lang'
import { pick } from 'lodash/object'
import moment from 'moment'
import { useTrackValues } from './../../lrtracker'
import { v4 as uuidv4 } from 'uuid'

import ContentWrapper from './../../components/ContentWrapper'
import FormType from './../../components/Order/FormType'
import FormDetails from './../../components/Order/FormDetails'
import FormCalculate from './../../components/Order/FormCalculate'
import FormCheckout from './../../components/Order/FormCheckout'
import FormBuyExtraHours from './../../components/Order/FormBuyExtraHours'

import { useActions } from './../../actions'
import { getPrice, getMinDeadline, DEADLINE_SET, SIZE_SET, getDeadlineInHours } from './../../nerdytutors'

import { useTutors, useUserProfile, useSmstoolRemider } from './../../hooks'

// const DETAILS_BANNER = {
//   title: `Don't have instructions now?`,
//   text: `No problem! Skip this step and send task details later via tutor's chat.`
// }

const DETAILS_BANNER = {
  title: 'Not sure what details to add?',
  text: 'Our support team will be happy to help you. They are always online and will answer in less than two minutes.'
}
const ESTIMATE_TASK_BANNER = {
  title: `Any doubts estimating your task?`,
  text: 'Our friendly support team will reply in less than 2 minutes to help with any questions you may have.'
}

const HAVE_QUESTION_BANNER = {
  title: `Have any questions?`,
  text: ' Our friendly support team will reply in less than 2 minutes to help with any questions you may have.'
}

const SET_LENGTH_AND_DEADLINE_BANNER = {
  text: 'Select your task’s length and a due date to get the final price. Message us if you have any questions.'
}

const getFormBannerNewDraft = (order, stage) => {
  switch (true) {
    case stage === 1: return DETAILS_BANNER;
    case stage === 2: return order.price ? ESTIMATE_TASK_BANNER : SET_LENGTH_AND_DEADLINE_BANNER;
    case stage === 3: return HAVE_QUESTION_BANNER;
    default: return null
  }
}

const getFormBannerNoBanners = (order, stage) => null

const OrderStagesHOC = ({
  order,
  orderState = 'new',
  defaultStage = 0,
  requestedTutorId,
  onClose,
}) => {
  const actions = useActions()

  const orderStateProps = {
    'new': {
      stages: [FormType, FormDetails, FormCalculate, FormCheckout],
      onSubmit: order => actions.onUpdateOrCreateOrder(order),
      onSupport: order => actions.onSupport(order),
      onCancel: (order, isChanged) => isChanged
        ? actions.onSaveOrder(order)
        : onClose(),
      onClose,
      onSaveOrderForce: order => actions.onSaveOrderForce(order),
      getFormTitle: (order, stage) => stage === 3
        ? '100% Secure checkout'
        : order.title || 'Create new task',
      getFormBanner: getFormBannerNewDraft
    },
    'draft': {
      stages: [FormType, FormDetails, FormCalculate, FormCheckout],
      onSubmit: order => actions.onUpdateOrCreateOrder(order),
      onSupport: order => actions.onSupport(order),
      onCancel: (order, isChanged) => isChanged
        ? actions.onSaveOrder(order)
        : onClose(),
      onClose,
      onSaveOrderForce: order => actions.onSaveOrderForce(order),
      getFormTitle: (order, stage) => stage === 3
        ? '100% Secure checkout'
        : order.title || 'Create new task',
      getFormBanner: getFormBannerNewDraft
    },
    'extend': {
      stages: [FormBuyExtraHours],
      onSubmit: order => actions.onExtendOrder(order),
      onCancel: onClose,
      onClose,
      getFormTitle: () => 'Buy extra hours',
      getFormBanner: getFormBannerNoBanners
    }
  }[orderState]

  useUserProfile()

  const onOpenFullDetails = (order, onSubmit) => actions.onOpenFullDetails({ ...order, onSubmit })

  const defaultOrder = order
    ? { ...order, deadline: moment(order.deadline).toISOString() }
    : {}

  const { profile } = useSelector(selectors.authSelector)

  return React.createElement(Order, {
    actions,
    ...orderStateProps,
    onOpenFullDetails,
    defaultOrder: {
      id: null,
      toid: uuidv4(),
      title: '',
      subject: '',
      details: '',
      size: 1,
      deadline: null,
      files: [],
      requested_tutor_id: requestedTutorId || defaultOrder.requested_tutor_id || null,
      ...defaultOrder
    },
    defaultStage,
    userProfile: profile,
  })
}

const Order = ({
  stages,
  onSubmit,
  onCancel,
  onSupport,
  onSaveOrderForce,
  onOpenFullDetails,
  onClose,
  defaultOrder,
  defaultStage,
  userProfile,
  getFormTitle,
  getFormBanner
}) => {

  const id = useState(defaultOrder.id)[0]
  const toid = useState(defaultOrder.toid)[0]
  const [stage, setStage] = useState(defaultStage)
  const [title, setTitle] = useState(defaultOrder.title)
  const [subject, setSubject] = useState(defaultOrder.subject)
  const [details, setDetails] = useState(defaultOrder.details)
  const [size, setSize] = useState(defaultOrder.size)
  const [deadline, setDeadline] = useState(
    defaultOrder.deadline &&
    getDeadlineInHours(moment(defaultOrder.deadline).toDate())
  )
  const [files, setFiles] = useState(defaultOrder.files)
  const [requestedTutorId, setRequestedTutorId] = useState(defaultOrder.requested_tutor_id)

  const tutors = useTutors()
  const requestedTutor = tutors.find(tutor => tutor.id === requestedTutorId)

  const { balance } = userProfile
  const { price, total } = deadline && size
    ? getPrice(deadline, size, balance)
    : { price: null, total: null }

  const minDeadline = getMinDeadline(size)

  const cleanedOrder = {
    id,
    toid,
    title,
    subject,
    details,
    size,
    deadline: deadline ? utils.deadlineToISOString(deadline) : null,
    files: files.map(({ name, size, url }) => ({ name, size, url })),
    price,
    total,
    balance,
    status: 'draft',
    rate: null,
    tutor: null,
    requested_tutor_id: requestedTutorId,
  }

  const compareFields = ['title', 'subject', 'details', 'size', 'files', 'deadline', 'requested_tutor_id']
  const isChanged = !isEqual(pick(cleanedOrder, compareFields), pick(defaultOrder, compareFields))

  const _onOpenFullDetails = () => onOpenFullDetails(
    cleanedOrder,
    ({ details, files }) => {
      setDetails(details)
      setFiles(files)
    }
  )

  useEffect(() => {
    window.scroll(0, 0)
  }, [stage])

  const [orderGuard, setOrderGuard] = useState(true)

  const _onClose = () => {
    setOrderGuard(false)
    onCancel(cleanedOrder, isChanged)
    onClose()
  }

  const _onBack = stage > 0
    ? () => setStage(stage - 1)
    : null

  const _onSubmit = stage === stages.length - 1
    ? (params) => {
      setOrderGuard(false)
      onSubmit({ ...cleanedOrder, ...params })
    }
    : () => setStage(stage + 1)

  const _onSaveOrderForce = () => {
    onSaveOrderForce(cleanedOrder)
    onClose()
  }

  const trackValuesContext = { toid, stage: `stage${stage}` }
  const trackValuesWithPriceContext = { ...trackValuesContext, balance, total, price }
  useTrackValues(title, 'title', { context: trackValuesContext, predicat: value => !id && value.length > 0 })
  useTrackValues(subject, 'subject', { context: trackValuesContext, predicat: value => !id && value.length > 0 })
  useTrackValues(details, 'details', { context: trackValuesContext, predicat: value => !id && value.length > 0 })
  useTrackValues(size, 'size', { context: trackValuesContext, predicat: _ => !id && true })
  useTrackValues(deadline, 'deadline', { context: trackValuesWithPriceContext, predicat: value => !id && !!value })
  useTrackValues(files.length, 'files', { context: trackValuesContext, predicat: value => !id && value > 0 })
  useTrackValues(requestedTutorId, 'requestedTutorId', { context: trackValuesContext, predicat: value => !id && !!value })
  useTrackValues(`stage${stage}`, 'stage', { context: trackValuesContext, predicat: _ => !id && stage !== 3 })
  useTrackValues(`stage${stage}`, 'stage', { context: trackValuesWithPriceContext, predicat: _ => !id && stage === 3 })

  useSmstoolRemider(
    `STAGE: ${stage}\n\n`
    + `title: ${title}\n`
    + `subject: ${subject}\n`
    + `details: ${details}\n`
    + `size: ${utils.sizeToHumans(size)}\n`
    + `deadline: ${deadline}\n`
    + `files: ${files.length}\n`
    + `requested tutor id: ${requestedTutorId}`
  )

  const banner = getFormBanner(cleanedOrder, stage)

  return <ContentWrapper
    title={getFormTitle(cleanedOrder, stage)}
    banner={banner && { ...banner, id: toid }}
    onClose={_onClose}
    onBack={_onBack}
    onSupport={onSupport}
    context={trackValuesContext}
  >
    {React.createElement(
      stages[stage],
      {
        title,
        subject,
        details,
        size,
        deadline,
        minDeadline,
        files,
        price,
        total,
        balance,
        tutors,
        requestedTutor,
        requestedTutorId,
        sizeset: SIZE_SET,
        deadlineset: DEADLINE_SET,
        banner,
        onChangeTitle: setTitle,
        onChangeSubject: setSubject,
        onChangeDetails: setDetails,
        onChangeDeadline: setDeadline,
        onChangeSize: setSize,
        onChangeFiles: setFiles,
        onChangeRequestedTutorId: setRequestedTutorId,
        onSubmit: _onSubmit,
        onOpenFullDetails: _onOpenFullDetails,
        onSaveOrderForce: _onSaveOrderForce,
        onSupport
      })}
    <Prompt
      when={orderGuard && isChanged}
      message='Are you sure you want to leave? You can save the information by pressing Close icon in the upper right.'
    />
  </ContentWrapper>
}

export default OrderStagesHOC