import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Icon, utils } from '@verdaccio/mt-uikit'
import theme, { rem } from '../../theme/base'
import Title from './Title'
import Subtitle from './Subtitle'
import { Fragment } from 'react'

const styles = {
  root: {
    background: theme.colors.white,
    height: rem(56),
    paddingTop: rem(6),
    paddingBottom: rem(6),
    paddingLeft: rem(80),
    position: 'relative',
    transition: 'background 300ms ease',
    '&.iconName': {
      cursor: 'pointer',
    },
    '&.requestedTutor': {
      paddingLeft: rem(40),
    },
    '&.withHover': {
      '&:hover': {
        background: theme.colors.lightBlueGrey50,
      },
    },
  },
  items: {
    width: rem(72),
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  item: {
    background: theme.colors.white,
    width: rem(32),
    height: rem(32),
    marginLeft: rem(-12),
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '&:nth-child(1)': {
      marginLeft: 0,
      zIndex: 3,
    },
    '&:nth-child(2)': {
      zIndex: 2,
    },
    '&:nth-child(3)': {
      zIndex: 1,
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      display: 'block',
      border: [rem(1), 'solid', theme.colors.white],
    },
  },
  text: {
    '& div': {
      '&:only-child': {
        lineHeight: rem(44),
      },
    },
  },
  arrow: {
    width: rem(16),
    height: rem(17),
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%) rotate(180deg)',
    transition: 'transform 300ms ease',
    '&.showList': {
      transform: 'translateY(-50%) rotate(0)',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  requestedTutorAvatar: {
    width: rem(32),
    height: rem(32),
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'block',
  },
}

const RandomTutors = ({
  classes,
  className,
  items,
  tutors,
  requestedTutor,
  title,
  subtitle,
  iconName,
  showList,
  withHover,
  onClick,
}) =>
  <div
    className={classnames(classes.root, className, {
      iconName,
      withHover,
      requestedTutor,
    })} 
    onClick={onClick}
  >
    {requestedTutor
      ? <img
          className={classes.requestedTutorAvatar}
          src={utils.getUserAvatarUrl(requestedTutor.id)}
        />
      : <div className={classes.items}>
          {items.map(({ name, image }) =>
            <div className={classes.item} key={name}>
              <img src={image} />
            </div>
          )}
        </div>
    }
    <div className={classes.text}>
      {tutors && tutors.length > 0
        ? <Fragment>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </Fragment>
        : <Fragment>
            {title && 
              <Subtitle>
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Subtitle>
            }
          </Fragment>
      }
    </div>
    {iconName &&
      <Icon className={classnames(classes.arrow, { showList })} iconName={iconName} />
    }
  </div>

export default injectSheet(styles)(RandomTutors)