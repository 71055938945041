import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import analytics from './analytics'
import lrtracker from './lrtracker'
import TutorProfilePage from './pages/TutorProfilePage'
import OrderCreatePage from './pages/OrderCreatePage'
import SupportChat from './pages/SupportChat'
import SettingsPage from './pages/SettingsPage'
import FormDetails from './components/FormDetails'
import ViewDetails from './components/ViewDetails'
import Menu from './components/Menu'
import { TutorRate } from './components/Tutor'
import {
  AlertReportSent,
  AlertGoodRate,
  AlertBadRate,
  AlertRevisionRequestSent,
} from './components/Alerts'
import FullDetails from './components/FormDetails'

import { FAQ } from './config'

import { TutorContextMenu, OrderContextMenu } from './containers/ContextMenus'
import ViewOrderDetails from './containers/ViewOrderDetails'
import Prompts from './containers/Prompts'
import { ORDER } from './nerdytutors'

import {
  Loader,
  modals,
  xmppActions,
  Faq,
  selectors,
} from '@verdaccio/mt-uikit'

import {
  updateOrCreateOrder,
  extendOrder,
  getPaymentLink,
  makePaymentFromBalance,
  createUser,
  factorAuthPassword,
  factorAuthGmail,
  restorePassword,
  updateUser,
} from './api'

export function useActions() {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(selectors.authSelector)

  const sendCommand = (command) => {
    dispatch(xmppActions.publishJsonCommand({
      ...command,
      headers: {
        'X-Token': auth.xToken,
      },
    }))
  }

  const commands = {
    rejectTutor(order) { sendCommand({ command: 'reject_tutor', order }) },
    blockTutor(tutor) { sendCommand({ command: 'block_tutor', tutor }) },
    reportTutor(tutor, report) { sendCommand({ command: 'report_tutor', tutor, report }) },
    favTutor(tutor, fav) { sendCommand({ command: 'fav_tutor', tutor, fav }) },
    acceptOrder(order) { sendCommand({ command: 'accept_order', order }) },
    rateOrder(order, rate) { sendCommand({ command: 'rate_order', order, rate }) },
    requestRevision(order, request) { sendCommand({ command: 'request_revision', order, request }) },
    requestRefund(order, request) { sendCommand({ command: 'request_refund', order, request }) },
    deleteOrder(order) { sendCommand({ command: 'delete_order', order }) },
  }

  const calls = {
    _headers(auth) {
      return {
        'X-Token': auth.xToken,
        cid: auth.cid
      }
    },
    updateOrCreateOrder(order) {
      return updateOrCreateOrder({
        order,
        headers: calls._headers(auth)
      })
    },
    extendOrder(order) {
      return extendOrder({
        order,
        headers: calls._headers(auth)
      })
    },
    makePaymentFromBalance(orderId, value) {
      return makePaymentFromBalance({
        order_id: orderId,
        value,
        headers: calls._headers(auth)
      })
    },
    updateUser(profile) {
      return updateUser(auth.xToken)(profile)
    }
  }

  const addLoader = (type = 'overlay-white') => {
    const action = modals.actions.add({ type, component: Loader })
    dispatch(action)
    return () => dispatch(modals.actions.remove(action.payload.uuid))
  }

  const actions = {
    onMenu: prevUrl => dispatch(modals.actions.add({
      type: 'overlay',
      component: Menu,
      prevUrl,
      onFaq: actions.onFaq,
      onSupport: actions.onSupport,
      onLogout: actions.onLogout,
    })),
    onFaq: () => dispatch(modals.actions.add({
      type: 'modal',
      component: Faq,
      faq: FAQ,
    })),
    onSupport: () => dispatch(modals.actions.add({
      type: 'modal',
      component: SupportChat,
      node: auth.supportNode,
    })),
    onUpdateOrCreateOrder: order => {
      const removeLoader = addLoader()
      calls.updateOrCreateOrder({ ...order, details: order.details || "Please check order's chat for more details" })
        .then(orderId => {
          if (order.total == 0) {
            calls.makePaymentFromBalance(orderId, order.price).then(() => {
              dispatch({
                type: 'PROFILE_UPDATE',
                payload: {
                  ...auth.profile,
                  balance: auth.profile.balance - order.price,
                },
              })
              setTimeout(() => {
                window.location = `/payment-success/${orderId}`
                removeLoader()
              }, 1500)
            })
          } else {
            setTimeout(() => {
              window.location = getPaymentLink(orderId, ORDER.STATE.DRAFT)
              removeLoader()
            }, 1500)
          }

          if (!order.id) {
            lrtracker.track('trackValues', { toid: order.toid, _values: { orderId } })
          }
        })
    },
    onDraftSaved: () => dispatch(modals.actions.add({
      type: 'overlay',
      component: Prompts.DraftSaved,
      onAccept: actions.onSupport,
    })),
    onTaskDeleted: () => dispatch(modals.actions.add({
      type: 'overlay',
      component: Prompts.TaskDeleted,
      onAccept: actions.onSupport,
    })),
    onSaveOrder: order => {
      const action = modals.actions.add({
        type: 'overlay',
        component: order.id
          ? Prompts.CloseAndUpdateDraft
          : Prompts.CloseAndCreateDraft,
        onAccept: () => {
          calls.updateOrCreateOrder(order)
          dispatch(modals.actions.remove(action.payload.uuid))
          actions.onDraftSaved()
        },
        onCancel: () => {
          dispatch(modals.actions.remove(action.payload.uuid))
          if (!order.id) {
            actions.onTaskDeleted()
          }
        },
      })
      return dispatch(action)
    },
    onSaveOrderForce: order => {
      calls.updateOrCreateOrder(order)
      actions.onDraftSaved()
    },
    onExtendOrder: order => {
      dispatch(modals.actions.add({
        type: 'overlay-white',
        component: Loader,
      }))
      calls.extendOrder(order)
        .then(orderId => {
          window.location = getPaymentLink(orderId, ORDER.STATE.EXTEND)
        })
    },
    onAcceptOrder: order => commands.acceptOrder(order),
    onPromptBuyExtraHours: order => order.tutor
      ? dispatch(modals.actions.add({
        type: 'overlay',
        component: Prompts.BuyExtraHours,
        orderId: order.id,
        onAccept: () => actions.onNewTask({ order, orderState: 'extend' }),
        onOrderChat: () => history.push(`/${order.id}/chat`)
      }))
      : actions.onNewTask({ order, orderState: 'extend' })
    ,
    onPromptDeleteOrder: orderId => dispatch(modals.actions.add({
      type: 'overlay',
      component: Prompts.DeleteOrder,
      orderId,
      onAccept: order => {
        commands.deleteOrder(order)
        actions.onTaskDeleted()
      }
    })),
    onRevisionOrder: order => dispatch(modals.actions.add({
      type: 'modal',
      component: FormDetails,
      header: 'Request revision',
      placeholder: 'Please enter details for corrections you need in your task',
      button: 'Continue',
      onSubmit: ({ details, files }) => {
        commands.requestRevision(order, { details, files })
        dispatch(modals.actions.add({
          type: 'overlay',
          component: AlertRevisionRequestSent
        }))
      },
    })),
    onRefundOrder: () => actions.onSupport(),
    onRevisionDetails: request => dispatch(modals.actions.add({
      type: 'modal',
      component: ViewDetails,
      header: 'Revision request',
      details: request.details,
      files: request.files,
    })),
    onOrderDetails: orderId => dispatch(modals.actions.add({
      type: 'modal',
      component: ViewOrderDetails,
      orderId,
      header: 'Your task details',
      onPromptBuyExtraHours: actions.onPromptBuyExtraHours
    })),
    onOrderContext: orderId => dispatch(modals.actions.add({
      type: 'overlay',
      component: OrderContextMenu,
      orderId,
    })),
    onRateOrder: (order, rate) => dispatch(modals.actions.add({
      type: 'modal',
      component: TutorRate,
      order,
      defaultrate: rate,
      onSubmit: ({ order, rate }) => {
        commands.rateOrder(order, rate)
        dispatch(modals.actions.add({
          type: 'overlay',
          component: rate.rate > 3
            ? AlertGoodRate
            : AlertBadRate
        }))
      }
    })),
    onTutorProfile: (tutorId, orderId) => dispatch(modals.actions.add({
      type: 'modal',
      component: TutorProfilePage,
      tutorId,
      orderId,
      onMore: () => actions.onTutorContext(tutorId, orderId),
      onTutorFav: actions.onTutorFav,
      onPromptTutorReject: () => actions.onPromptTutorReject(orderId),
      onRequestTutor: () => actions.onRequestTutor(tutorId)
    })),
    onTutorContext: (tutorId, orderId) => dispatch(modals.actions.add({
      type: 'overlay',
      component: TutorContextMenu,
      tutorId,
      orderId,
    })),
    onPromptTutorReject: orderId => dispatch(modals.actions.add({
      type: 'overlay',
      component: Prompts.RejectTutor,
      orderId,
      onAccept: order => commands.rejectTutor(order)
    })),
    onTutorFav: (tutor, fav) => {
      dispatch({ type: fav ? 'ADD_PRESENCE' : 'REMOVE_PRESENCE', payload: { status: 'favorite', cid: tutor.id } })
      commands.favTutor(tutor, fav)
    },
    onPromptTutorReport: tutorId => dispatch(modals.actions.add({
      type: 'modal',
      component: Prompts.ReportTutor,
      tutorId,
      onSubmit: (report, tutor) => {
        commands.reportTutor(tutor, report)
        dispatch(modals.actions.add({
          type: 'overlay',
          component: AlertReportSent
        }))
      }
    })),
    onPromptTutorBlock: tutorId => dispatch(modals.actions.add({
      type: 'overlay',
      component: Prompts.BlockTutor,
      tutorId,
      onAccept: tutor => {
        dispatch({ type: 'REMOVE_PRESENCE', payload: { status: 'favorite', cid: tutor.id } })
        commands.favTutor(tutor, false)
        dispatch({ type: 'ADD_PRESENCE', payload: { status: 'block', cid: tutor.id } })
        commands.blockTutor(tutor)
      }
    })),
    onUpdateProfile: profile => {
      return calls.updateUser(profile)
        .then(user => {
          dispatch({ type: 'PROFILE_UPDATE', payload: user.profile })
        })
    },
    onLogout: () => dispatch({ type: 'DISCONNECT' }),
    onLogin: auth => dispatch({ type: 'CONNECT', payload: auth }),
    addModal: modal => dispatch(modals.actions.add(modal)),
    onOpenFullDetails: ({ id, toid, details, files, onSubmit }) => dispatch(modals.actions.add({
      type: 'modal',
      component: FullDetails,
      id,
      toid,
      details,
      files,
      onSubmit,
      placeholder: 'Please describe your problem and what you want Nerdy Tutor to help you with. You can also just take a photo of the task you need help with.',
      header: 'Your request details',
      button: 'Done',
    })),
    onCreateUserByEmailPassword: (email, password, { context = null }) => {
      const removeLoader = addLoader()
      return createUser({ email, password })
        .then(result => {
          analytics.leadCreated(result._id)
          lrtracker.track('userRegistered', { ...(context || {}), _payload: { type: 'email' } })
          return factorAuthPassword(email, password)
        })
        .finally(() => removeLoader())
    },
    onCreateUserByGmail: (email, name, googleId, { context = null }) => {
      const removeLoader = addLoader()
      return createUser({ email, name, googleId })
        .then(result => {
          analytics.leadCreated(result._id)
          lrtracker.track('userRegistered', { ...(context || {}), _payload: { type: 'google' } })
          return factorAuthGmail(email, googleId)
        })
        .finally(() => removeLoader())
    },
    onLoginUserByEmailPassword: (email, password) => {
      const removeLoader = addLoader()
      return factorAuthPassword(email, password)
        .finally(() => removeLoader())
    },
    onLoginUserWithGmail: (email, googleId) => {
      const removeLoader = addLoader()
      return factorAuthGmail(email, googleId)
        .finally(() => removeLoader())
    },
    onRestoreUserPassword: email => {
      const removeLoader = addLoader()
      return restorePassword(email)
        .finally(() => removeLoader())
    },
    onRequestTutor: tutorId => {
      dispatch(modals.actions.removeAll())
      actions.onNewTask({ requestedTutorId: tutorId })
    },
    onNewTask: (props) => dispatch(modals.actions.add({
      type: 'modal',
      component: OrderCreatePage,
      ...props,
    })),
    onSettings: () => dispatch(modals.actions.add({
      type: 'modal',
      component: SettingsPage,
    })),
  }

  return actions
}
