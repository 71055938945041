import axios from 'axios'

function getGoogleClientId() {
  let p = ('; ' + document.cookie).split('; ' + '_ga=')
  if (p.length === 2) {
    const _ga = p.pop().split(';').shift()
    return _ga.split('.').slice(2, 4).join('.')
  }
}

const convertPrice = price => (price / 100).toFixed(2)

const orderPaid = (cid, orderId, orderPrice) => axios.post(`https://www.google-analytics.com/collect?t=event&tid=${ENV.GA_COLLECT_TRACKER_ID}&cid=${getGoogleClientId()}&ec=cabinet&ea=purchase&el=paid&cd1=${cid}&cd3=${getGoogleClientId()}&cd4=${orderId}&v=${convertPrice(orderPrice)}`)

const firstOrderPaid = (cid, orderId, orderPrice) => axios.post(`t=event&tid=${ENV.GA_COLLECT_TRACKER_ID}&cid=${getGoogleClientId()}&ec=cabinet&ea=purchase&el=first_order_paid&cd1=${cid}&cd3=${getGoogleClientId()}&cd4=${orderId}&v=${convertPrice(orderPrice)}`)

const leadCreated = cid => axios.post(`https://www.google-analytics.com/collect?v=1&t=event&tid=${ENV.GA_COLLECT_TRACKER_ID}&cid=${getGoogleClientId()}&ec=cabinet&ea=stage_lead&el=lead_created&cd1=${cid}&cd3=${getGoogleClientId()}`)

export default {
  orderPaid: ENV.GA_COLLECT_TRACKER_ID ? orderPaid : (cid, orderId, orderPrice) => { console.log('orderPaid', { cid, orderId, orderPrice: convertPrice(orderPrice) }) },
  firstOrderPaid: ENV.GA_COLLECT_TRACKER_ID ? firstOrderPaid : (cid, orderId, orderPrice) => { console.log('firstOrderPaid', { cid, orderId, orderPrice: convertPrice(orderPrice) }) },
  leadCreated: ENV.GA_COLLECT_TRACKER_ID ? leadCreated : (cid) => { console.log('leadCreated', { cid }) }
}