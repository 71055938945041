import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { utils, useOutsideClick } from '@verdaccio/mt-uikit'
import { getRandomTutors } from '../../config'
import theme, { rem } from '../../theme/base'
import { SlideUpDropDownAnimation } from './../Animation'
import List from './List'
import RandomTutors from './RandomTutors'

const styles = {
  root: {
    padding: [rem(8), rem(24)],
    borderTop: [rem(1), 'solid', theme.colors.paleGrey],
    position: 'relative',
    '&.hasTutors': {
      cursor: 'pointer',
    },
  },
  content: {
    position: 'relative',
  },
  container: {
    overflowY: 'visible !important',
  },
}

const TutorsList = ({
  classes,
  subject,
  tutors,
  requestedTutor,
  onChangeRequestedTutorId,
}) => {
  const outsideClickRef = useRef(null)

  const [randomTutors, setRandomTutors] = useState([])
  const [randomTutorsCount, setRandomTutorsCount] = useState([])
  const [showList, setShowList] = useState(false)

  const hasTutors = tutors && tutors.length > 0

  const onMakeShow = () => hasTutors && setShowList(!showList)
  const onHide = () => hasTutors && setShowList(false)

  useOutsideClick(outsideClickRef, onHide)

  useEffect(() => {
    const randomTutors = getRandomTutors(3)
    setRandomTutors(randomTutors)
  }, [])

  useEffect(() => {
    const randomTutorsCount = utils.getRandom(75, 85, 1)
    setRandomTutorsCount(randomTutorsCount)
  }, [])

  const getHelpedText = (subjects, helped) => {
    const subjectByTutor = subject && subjects.find(item => item === subject)
    return subjectByTutor
      ? <span style={{ color: '#366CE6' }}>{subjectByTutor}</span>
      : helped === 1
        ? 'Helped once'
        : `Helped ${helped} times`
  }

  const title = hasTutors
    ? requestedTutor
      ? `Assign ${requestedTutor.name}`
      : `Assign best matching tutor`
    : `<b>${randomTutorsCount} tutors</b> are online and ready to help`

  const subtitle = hasTutors
    ? requestedTutor
      ? getHelpedText(requestedTutor.subjects, requestedTutor.meta.helped)
      : `${randomTutorsCount} tutors are online`
    : null

  return (
    <div className={classnames(classes.root, { hasTutors })} ref={outsideClickRef}>
      <div className={classes.container}>
        <div className={classes.content}>
          <RandomTutors
            items={randomTutors}
            tutors={tutors}
            requestedTutor={requestedTutor}
            title={title}
            subtitle={subtitle}
            showList={showList}
            iconName={hasTutors ? 'arrow' : null}
            onClick={onMakeShow}
          />
        </div>
      </div>
      {hasTutors && showList &&
        <SlideUpDropDownAnimation>
          <List
            randomTutors={randomTutors}
            tutors={tutors}
            subject={subject}
            onChangeRequestedTutorId={onChangeRequestedTutorId}
            onHide={onHide}
            getHelpedText={getHelpedText}
          />
        </SlideUpDropDownAnimation>
      }
    </div>
  )
}

export default injectSheet(styles)(TutorsList)