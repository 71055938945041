import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import { Link } from 'react-router-dom'
import theme, { rem, content, desktop } from './../../../theme/base'
import DesktopWrapperHOC2 from './../../../components/DesktopWrapperHOC2'
import analytics from './../../../analytics'

let isAnalyticsFetched = false

const iconsPath = require.context('./')

const styles = {
  root: {
    background: theme.colors.white,
    paddingBottom: rem(24),
    position: 'relative',
    [desktop]: {
      borderRadius: rem(16),
    },
  },
  wrapper: {
    ...content,
    maxWidth: rem(375),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: rem(24),
    paddingRight: rem(24),
  },
  icon: {
    backgroundImage: `url(${iconsPath(`./payment-succsess-icon.svg`)})`,
    backgroundRepeat: 'no-repeat',
    //backgroundSize: 'cover',
    backgroundPosition: ['center', rem(72)],
    width: '100%',
    height: rem(250),
    paddingTop: rem(72),
  },
  title: {
    marginTop: rem(24),
    fontSize: rem(22),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
    textAlign: 'center',
  },
  text: {
    marginTop: rem(16),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGreyTwo,
    letterSpacing: rem(0.09),
    textAlign: 'center',
  },
  button: {
    ...content,
    marginTop: rem(24),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    '&:hover': {
      color: theme.colors.white,
    },
  },
}

const PaymentSuccess = ({
  classes,
  successPaymentUrl,
  state
}) => {

  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.white
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  const { order, orders } = state
  const orderid = order && order.id && order.id.split('/')[1]
  const countPaidOrders = orders.length > 0 && orders.filter(order => order.status !== 'draft')

  useEffect(() => {
    if (orderid && countPaidOrders.length > 0 && order.client && isAnalyticsFetched === false) {
      isAnalyticsFetched = true
      analytics.orderPaid(order.client, orderid, order.price)
      if (countPaidOrders.length === 1) {
        analytics.firstOrderPaid(order.client, orderid, order.price)
      }
    }
  }, [orderid, order.client, countPaidOrders.length])

  return (
    <div className={classes.root}>
      <div className={classes.icon} />
      <div className={classes.wrapper}>
        <div className={classes.title}>We received your payment!</div>
        <div className={classes.text}>
          We're already looking for the best tutor for your task. You'll receive a text message once the tutor is found.
        </div>
      </div>
      <div className={classes.button}>
        <Button type='primary' size='large' container={<Link to={successPaymentUrl} />} block>Continue</Button>
      </div>
    </div>
  )
}

export default DesktopWrapperHOC2(injectSheet(styles)(PaymentSuccess))