import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from './../../theme/base'

const styles = {
  background: {
    backgroundImage: `url(${require('./empty.svg')})`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.colors.paleGrey,
    height: rem(270),
    paddingTop: rem(90),
    position: 'relative',
    overflowX: 'hidden',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      right: `calc(50vw + ${rem(85)})`,
      bottom: 0,
      // TODO: add '#f6f7fb' to theme.colors
      background: '#f6f7fb',
      width: `calc(50vw - ${rem(85)})`,
      height: rem(79),
    },
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: `calc(50vw + ${rem(85)})`,
      bottom: 0,
      // TODO: add '#f6f7fb' to theme.colors
      background: '#f6f7fb',
      width: `calc(50vw - ${rem(85)})`,
      height: rem(79),
    },
  },
  title: {
    marginTop: rem(16),
    fontSize: rem(22),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
    textAlign: 'center',
  },
  subtitle: {
    marginTop: rem(8),
    fontSize: rem(15),
    lineHeight: rem(22),
    color: theme.colors.SlateGreyTwo,
    letterSpacing: rem(0.09),
    textAlign: 'center',
  },
  button: {
    width: `calc(100% - ${rem(64)})`,
    marginTop: rem(24),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 0,
  },
}

const Empty = ({
  classes
}) =>
  <Fragment>
    <div className={classes.background} />
    <div className={classes.title}>Nobody's here...</div>
    <div className={classes.subtitle}>
        Here you’ll be able to find tutors you already<br/>
        worked with.
    </div>
  </Fragment>

export default injectSheet(styles)(Empty)