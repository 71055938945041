import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem, adaptive } from './../../../../theme/base'

const styles = {
  root: {
    background: theme.colors.white,
    padding: [rem(16), 0],
    borderTop: [1, 'solid', theme.colors.paleGrey],
    textAlign: 'center',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    position: 'absolute',
    [adaptive]: {
      position: 'fixed',
    },
    '& span': {
      // TODO: add '#3D77FF' to theme.colors
      color: '#3D77FF',
      fontSize: rem(15),
      lineHeight: rem(20),
      fontWeight: 600,
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
      cursor: 'pointer',
    },
  },
}

const SaveAndClose = ({
  classes,
  className,
  onClick,
}) =>
  <div className={classnames(classes.root, className)}>
    <span onClick={onClick}>Save as draft and close</span>
  </div>

export default injectSheet(styles)(SaveAndClose)