import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(15),
    lineHeight: rem(22),
    color: theme.colors.black,
    textAlign: 'center',
  },
}

const Email = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Email)