import theme, { rem, desktop, adaptive, MODAL_WIDTH_DESKTOP } from './../../../theme/base'

export default {
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'start',
    minHeight: '100vh',
    [desktop]: {
      minHeight: 0,
    },
  },
  root: {
    paddingTop: 0,
    position: 'relative',
    width: '100%',
    [desktop]: {
      width: MODAL_WIDTH_DESKTOP,
    },
  },
  header: {
    borderBottom: [rem(1), 'solid', theme.colors.paleGrey],
    borderRadius: 'inherit',
    position: 'relative',
  },
  wrapper: {
    position: 'absolute',
    top: rem(59),
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'auto',
    [desktop]: {
      maxHeight: `${rem(529)} !important`,
      top: 'auto',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      position: 'relative',
    },
  },
  content: {
    width: '100%',
    position: 'relative',
    padding: 0,
    overflowY: 'hidden',
    minHeight: 'inherit',
    maxHeight: 'inherit',
    background: theme.colors.white,
    borderRadius: [rem(16), rem(16), 0, 0],
    overflowY: 'auto',
    [desktop]: {
      maxHeight: 'inherit',
      borderRadius: [0, 0, rem(16), rem(16)],
    },
  }
}