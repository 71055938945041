import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from '@verdaccio/mt-uikit'
import theme, { rem } from './../../theme/base'

const styles = {
  root: {
    width: '100%',
    paddingLeft: rem(24),
    paddingRight: rem(24),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& .item': {
      display: 'inline-block',
      verticalAlign: 'top',
      '& + .item': {
        marginLeft: rem(16),
      },
    },
    '& .value': {
      color: theme.colors.slateGrey,
      fontSize: rem(15),
      lineHeight: rem(20),
      fontWeight: 600,
    },
    '& .icon': {
      // TODO: add '#f7b500' to theme.colors
      fill: '#f7b500',
      width: rem(16),
      height: rem(16),
      marginTop: rem(3),
      marginLeft: rem(6),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& .name': {
      marginTop: rem(4),
      color: theme.colors.blueyGrey,
      fontSize: rem(13),
      lineHeight: rem(16),
    }
  },
}

const Info = ({
  rate,
  helped,
  experience,
  classes,
}) =>
  <div className={classes.root}>
    <div className='item'>
      <div className='value'>
        {rate}
        <Icon className='icon' iconName='star' />
      </div>
      <div className='name'>average score</div>
    </div>
    <div className='item'>
      <div className='value'>{helped}</div>
      <div className='name'>requests completed</div>
    </div>
    <div className='item'>
      <div className='value'>{experience}</div>
      <div className='name'>on Nerdy Tutors</div>
    </div>
  </div>

export default injectSheet(styles)(Info)
