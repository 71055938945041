import { intersection } from 'lodash/array'
import findLast from 'lodash/findLast'
import moment from 'moment'
import {
  utils,
  selectors,
  getOrdersNodeName,
  getOrderLogNodeName,
  getSupportNodeName
} from '@verdaccio/mt-uikit'

const PRICES = [4000, 3500, 3000, 2500, 2500]
const DEADLINE_SET = [12, 24, 48, 72, 96]
const DEADLINE_PRESETS = ['In 12 hrs', 'In 1 day', 'In 2 days', 'In 3 days', 'In 4 days']

// const RESTRICTIONS = [6, 6, 6, 6, 6, 7, 8, 9, 12, 15, 19, 21, 24, 26, 29, 33, 37, 40, 44, 48]
const RESTRICTIONS = [12, 12, 12, 12, 12, 12, 12, 12, 12, 15, 19, 21, 24, 26, 29, 33, 37, 40, 44, 48]
export const getRestriction = size => Math.min(Math.ceil(size), RESTRICTIONS.length - 1)
export const checkRestriction = (size, deadline) => deadline < RESTRICTIONS[getRestriction(size)] ? RESTRICTIONS[getRestriction(size)] : null
export const getMinDeadline = size => RESTRICTIONS[getRestriction(size)]

const SIZE_SET = []
for (let i = 0; i <= 50; i++) {
  const size = i === 0 ? 0.5 : i
  SIZE_SET.push({
    key: i,
    value: size,
    text: utils.sizeToHumans(size),
  })
}

const AVAILABLE_REJECT_TUTOR_IN_TIME = 3600

const getDeadlineInHours = (datetime) => {
  const now = moment().startOf('hour').add(1, 'h')
  return moment(datetime).diff(now, 'hours')
}

const getTutorContext = (tutor, order, presence, actions) => {
  const result = []

  const favorite = presence && presence.favorite && presence.favorite.includes(tutor.id)
  const block = presence && presence.block && presence.block.includes(tutor.id)

  if (!block) {
    result.push({
      icon: 'contextMenu-add',
      text: 'Request new task',
      disabled: false,
      onClick: () => actions.onRequestTutor(tutor.id),
      divider: true,
    })
  }

  if (order) {
    result.push({
      icon: 'contextMenu-reject',
      text: 'Reject Tutor',
      description: order.meta.availableRejectTutorInTime > 0
        ? `Avaliable ${utils.secToMin(order.meta.availableRejectTutorInTime)} min`
        : 'Unavailable, because tutor already in process',
      disabled: order.meta.availableRejectTutorInTime <= 0,
      onClick: () => actions.onPromptTutorReject(order.id),
    })
  }

  if (!block) {
    result.push({
      icon: 'contextMenu-heart',
      text: favorite ? 'Remove from favorites' : 'Add to favorites',
      disabled: false,
      onClick: () => actions.onTutorFav(tutor, !favorite),
      divider: false,
    })
  }

  result.push({
    icon: 'contextMenu-report',
    text: 'Report Tutor',
    disabled: false,
    onClick: () => actions.onPromptTutorReport(tutor.id),
  })

  result.push({
    icon: 'contextMenu-block',
    text: 'Block Tutor',
    description: block ? 'Unavailable, because tutor already blocked' : null,
    disabled: block,
    onClick: () => actions.onPromptTutorBlock(tutor.id),
  })

  return result
}

const getOrderContext = (order, actions) => {

  const menu = []

  if (order.meta.availableBuyExtraHours) {
    menu.push({
      icon: 'time',
      text: 'Buy extra hours',
      onClick: () => actions.onPromptBuyExtraHours(order),
      divider: true,
    })
  }

  menu.push({
    icon: 'contextMenu-copy',
    text: 'Copy task number',
    description: `#${order.number}`,
    copyByClick: `#${order.number}`,
  })

  if (order.tutor) {
    menu.push({
      icon: 'contextMenu-profile',
      text: 'View tutor profile',
      onClick: () => actions.onTutorProfile(order.tutor.id, order.id)
    })
  }

  menu.push({
    icon: 'contextMenu-support',
    text: 'Contact support',
    onClick: actions.onSupport,
  })

  if (order.meta.availableRevisionRequest) {
    menu.push({
      icon: 'contextMenu-revision',
      text: 'Request revision',
      description: !order.meta.orderReady ? 'Available only after completion' : null,
      disabled: !order.meta.orderReady,
      onClick: () => actions.onRevisionOrder(order),
    })
  }

  if (order.meta.availableRefundRequest) {
    menu.push({
      icon: 'contextMenu-refund',
      text: 'Request refund',
      onClick: () => actions.onRefundOrder(order),
    })
  }

  return menu
}

const getPrice = (deadline, size, balance) => {
  const deadlineIndex = DEADLINE_SET.reduce((result, item) => item < deadline ? result + 1 : result, 0)
  const price = PRICES[Math.min(deadlineIndex, DEADLINE_SET.length - 1)] * (size === 0 ? 0.5 : size)
  const total = balance > price ? 0 : price - balance
  return { total, price }
}

const orderWithLogMapper = (order, log) => {
  const events = log.map(l => l.event)
  const orderPaid = events.indexOf('order_paid') > -1
  const orderReady = events.indexOf('order_ready') > -1
  const orderAccepted = events.indexOf('accept_order') > -1
  const revisionRequested = intersection(events, ['request_revision', 'revision_inprogress', 'revision_ready']).length > 0
  const refundRequested = intersection(events, ['request_refund', 'refund_completed']).length > 0
  const tutorFound = findLast(log, ({ event }) => event === 'tutor_found')
  const tutorInWorkTime = tutorFound ? moment().unix() - tutorFound.time : 0
  const deadlineLeft = moment().toDate() > moment(order.deadline).toDate()
  const availableRefundRequest = orderPaid && !refundRequested && ["completed", "refunded"].indexOf(order.status) === -1
  const availableRevisionRequest = orderPaid && !orderAccepted && !revisionRequested && !refundRequested
  const availableRejectTutorInTime = order.tutor ? AVAILABLE_REJECT_TUTOR_IN_TIME - tutorInWorkTime : -1
  const availableBuyExtraHours = orderPaid && !isOrderClosed(order) && !deadlineLeft

  const tutor = order.tutor
    ? {
      ...order.tutor,
      meta: {
        subject: order.subject
      }
    }
    : null

  return {
    ...order,
    tutor,
    meta: {
      availableRefundRequest,
      availableRevisionRequest,
      orderReady,
      availableRejectTutorInTime,
      availableBuyExtraHours,
    },
  }
}

const ordersSelector = state =>
  selectors.createOrdersSelector(orderWithLogMapper)(state)
    .filter(order => order.size >= 0.5)


const tutorsSelector = selectors.createOrdersAssigneSelector(ordersSelector, 'tutor', 'id')

const inWorkItemsFilter = order => !isOrderClosed(order) && order.status !== 'draft'

const onXmppConnect = connection => {
  const supportNode = getSupportNodeName(connection.authcid)
  const ordersNode = getOrdersNodeName(connection.authcid)
  return connection.chat.getItems(supportNode)
    .then(() => connection.chat.getStatusItems(supportNode))
    .then(() => connection.chat.getActiveItems(
      ordersNode,
      inWorkItemsFilter,
      [getOrderLogNodeName]
    ))
    .catch(err => console.log(err))
}

// sync logic with tutor account
const CHAT_AVAILABLE_AFTER_ACCEPT = 24 * 3600
const isOrderClosed = order => order.status === "refunded" || (order.status === "accepted" && moment().unix() - order.time > CHAT_AVAILABLE_AFTER_ACCEPT)


const ORDER = {
  STATE: {
    NEW: 'new',
    DRAFT: 'draft',
    EXTEND: 'extend',
  },
  STAGES: {
    NEW: {
      TYPE: 0,
      DETAILS: 1,
      CALCULATE: 2,
      CHECKOUT: 3
    },
    DRAFT: {
      TYPE: 0,
      DETAILS: 1,
      CALCULATE: 2,
      CHECKOUT: 3
    },
    EXTEND: {
      BUYEXTRAHOURS: 0,
    }
  }
}

export {
  onXmppConnect,
  getPrice,
  getTutorContext,
  getOrderContext,
  getDeadlineInHours,
  ordersSelector,
  tutorsSelector,
  isOrderClosed,
  PRICES,
  DEADLINE_SET,
  DEADLINE_PRESETS,
  SIZE_SET,
  ORDER
}
