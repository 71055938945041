import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import theme, { rem, content, ui, adaptive, desktop } from '../../theme/base'

const styles = {
  root: {
    background: theme.colors.paleGrey,
    padding: [rem(64), 0],
    position: 'relative',
    overflowX: 'hidden',
    [adaptive]: {
      paddingLeft: rem(8),
      paddingRight: rem(8),
      paddingBottom: rem(236),
    },
    '& button': {
      marginTop: rem(20),
      borderRadius: rem(16),
    },
  },
  content,
  title: {
    ...ui.title,
    textAlign: 'left',
    [adaptive]: {
      fontSize: rem(24),
    },
  },
  left: {
    width: '90vw',
    maxWidth: rem(360),
    marginRight: rem(177),
    marginTop: rem(24),
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
  },
  right: {
    width: '90vw',
    maxWidth: rem(360),
    marginTop: rem(90),
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      marginTop: rem(16),
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
  },
  icons: {
    [adaptive]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  item: {
    width: '42.5vw',
    maxWidth: rem(172),
    marginTop: rem(16),
    marginRight: rem(16),
    display: 'inline-block',
    verticalAlign: 'top',
    '&:nth-child(2)': {
      marginRight: 0,
    },
    [adaptive]: {
      marginRight: rem(16),
    },
  },
  value: {
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(40),
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: rem(0.24),
    color: theme.colors.charcoalGrey,
    '& > img': {
      width: rem(28),
      height: rem(28),
      marginLeft: rem(4),
      display: 'inline-block',
      verticalAlign: 'medium',
    },
    '& > span': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      display: 'inline-block',
      verticalAlign: 'medium',
    },
  },
  text: {
    ...ui.text,
    marginTop: rem(8),
  },
  try: {
    marginTop: rem(64),
    [adaptive]: {
      position: 'absolute',
      left: 0,
      right: 0,
      paddingLeft: rem(8),
      paddingRight: rem(8),
      bottom: rem(-172),
      marginTop: 0,
    },
  },
  title_s: {
    ...ui.title,
    paddingRight: rem(20),
    fontSize: rem(24),
    textAlign: 'left',
    '& span': {
      display: 'inline',
    },
    '& img': {
      width: rem(24),
      height: rem(24),
      marginLeft: rem(4),
      marginTop: rem(4),
      display: 'inline-block',
      verticalAlign: 'top',
    },
    [adaptive]: {
      textAlign: 'center',
    },
  },
  card: {
    background: theme.colors.white,
    width: '42.5vw',
    height: '42.5vw',
    maxWidth: rem(172),
    maxHeight: rem(172),
    marginTop: rem(16),
    marginRight: rem(16),
    paddingTop: rem(16),
    paddingBottom: rem(16),
    borderRadius: rem(16),
    boxShadow: `0 ${rem(4)} ${rem(8)} 0 rgba(86, 92, 107, 0.1)`,
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive]: {
      paddingTop: rem(8),
      paddingBottom: rem(8),
    },
    '&:nth-child(2), &:nth-child(4), &:nth-child(6)': {
      marginTop: rem(-122),
      marginRight: 0,
      [adaptive]: {
        marginTop: rem(-80),
      },
    },
  },
  image: {
    width: '17.1vw',
    height: '17.1vw',
    maxWidth: rem(72),
    maxHeight: rem(72),
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.online': {
      '&:after': {
        content: '""',
        background: theme.colors.online,
        width: rem(10),
        height: rem(10),
        borderRadius: '50%',
        display: 'block',
        position: 'absolute',
        top: rem(5),
        right: rem(4),
        [desktop]: {
          top: rem(8),
        },
      },
    },
    '& > img': {
      width: 'inherit',
      height: 'inherit',
      maxWidth: 'inherit',
      maxHeight: 'inherit',
      borderRadius: '50%',
      display: 'block',
    },
  },
  rate: {
    backgroundColor: theme.colors.white,
    width: rem(44),
    height: rem(22),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: rem(-12),
    borderRadius: rem(12),
    boxShadow: `0 ${rem(8)} ${rem(16)} 0 rgba(186, 207, 255, 0.23), 0 ${rem(4)} ${rem(8)} 0 rgba(86, 93, 107, 0.1)`,
    fontSize: rem(13),
    lineHeight: rem(24),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
    textAlign: 'center',
    '& > img': {
      width: rem(10),
      height: rem(10),
      marginLeft: rem(4),
      display: 'inline-block',
      verticalAlign: 'medium',
    },
    '& > span': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      display: 'inline-block',
      verticalAlign: 'medium',
    },
  },
  name: {
    marginTop: rem(24),
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(17),
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    color: theme.colors.charcoalGrey,
  },
  subject: {
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(15),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    color: theme.colors.slateGrey,
  }
}

const VALUES = [
  { value: '5k', icon: 'happy', text: 'Happy users' },
  { value: '4.9', icon: 'star', text: 'Average tutor <br/> score' },
  { value: '2.5x', icon: 'energy', text: 'Quicker tutor matching' },
]

const TUTORS = [
  { rate: '5.0', name: 'Adelaide H.', subject: 'Academic writing', online: false },
  { rate: '5.0', name: 'Anne M.', subject: 'English', online: true },
  { rate: '4.9', name: 'Tom P.', subject: 'Sociology', online: true },
  { rate: '4.9', name: 'Gabriel B.', subject: 'Healthcare',  online: false },
  { rate: '4.8', name: 'Bryce K.', subject: 'Nursing',  online: false },
  { rate: '5.0', name: 'Agnes A.', subject: 'Algebra',  online: false },
]

const Superheroes = ({
  submitTitle,
  onScrollToForm,
  classes,
}) =>
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={classes.title}>Why our tutors are superheroes?</div>
      <div className={classes.left}>
        <div className={classes.icons}>
          {VALUES.map(({ value, icon, text }, index) =>
            <div className={classes.item} key={index}>
              <div className={classes.value}>
                <span>{value}</span>
                <img src={require(`./ic-${icon}.svg`)} alt='' />
              </div>
              <div className={classes.text} dangerouslySetInnerHTML={{__html: text}} />
            </div>
          )}
        </div>
        <div className={classes.try}>
          <div className={classes.title_s}>
            <span>Try it once and love it forever</span>
            <img
              src={require('./cat-in-love@1x.png')}
              srcSet={`
                ${require('./cat-in-love@3x.png')} 3x, 
                ${require('./cat-in-love@2x.png')} 2x, 
                ${require('./cat-in-love@1x.png')} 1x
              `}
              alt=''
            />
          </div>
          <Button
            className={classes.button}
            type='primary'
            size='large'
            block
            onClick={onScrollToForm}
          >
            {submitTitle}
          </Button>
        </div>
      </div>
      <div className={classes.right}>
        {TUTORS.map(({ rate, name, subject, online }, index) =>
          <div className={classes.card} key={index}>
            <div className={classnames(classes.image, { online })}>
              <img src={require(`./avatar-0${index + 1}@3x.png`)} alt='' />
              <div className={classes.rate}>
                <span>{rate}</span>
                <img src={require('./ic-star-mini.svg')} alt='' />
              </div>
            </div>
            <div className={classes.name}>{name}</div>
            <div className={classes.subject}>{subject}</div>
          </div>
        )}
      </div>
    </div>
  </div>

export default injectSheet(styles)(Superheroes)
