import React from 'react'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import { selectors } from '@verdaccio/mt-uikit'
import Header from './../../sections/Header'
import Footer from './../../sections/Footer'
import { Title, Subtitle, Paragraph, List, Item } from './../../layout'
import theme, { rem, content, adaptive } from '../../theme/base'

const styles = {
	root: {
		background: theme.colors.white,
  },
  wrapper: {
    paddingTop: rem(136),
    paddingBottom: rem(80),
    [adaptive]: {
      paddingTop: rem(120),
      paddingBottom: rem(64),
    },
  },
  content: {
    ...content,
    width: rem(624),
    paddingLeft: 0,
    paddingRight: 0,
    [adaptive]: {
      width: '100%',
      paddingLeft: rem(16),
      paddingRight: rem(16),
    },
  },
}

const PrivacyPolicy = ({ classes }) => {
  const auth = useSelector(selectors.authSelector)
  const isAuth = auth && auth.cid
  return (
    <div className={classes.root}>
      <Header showLogin={!isAuth} hasFixedState />
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Title>Privacy Policy</Title>
          <Subtitle>What information does Collaborative-Tech.co collect if I decide to join the Collaborative-Tech.co social community?</Subtitle>
          <Paragraph>Collaborative-Tech.co is a meeting place for students, graduates and coworkers, and we provide that service by operating a social network - oriented site and mobile application offering users the opportunity to connect to our global community of users and to their respective alma mater.</Paragraph>
          <Paragraph>To join the Collaborative-Tech.co network you will have to complete our online registration form or log in straight into the app, where we ask you to provide us with information about you such as your name, your email address, your gender, your date of birth, your location details and any other data that is aggregated from your existing social networks (e.g. Facebook, Linkedin and Twitter), with your consent when you connect them to Collaborative-Tech.co. You also have the opportunity to provide other details about yourself. Because you control your user profile, these details are available to you at any time by accessing your “Profile” page and, in the case of connected data from social media accounts, through your existing social network accounts, which gives you the chance to correct or update (other than gender and email address) your information at any time.</Paragraph>
          <Paragraph>Registration information: When you sign up for Collaborative-Tech.co, you are required to provide your name, email address, birthday, and gender.</Paragraph>
          <Paragraph>Other information: Your information also includes the information you choose to share on Collaborative-Tech.co, such as when you post a status update, upload a photo, or comment on a friend’s story, seek an introduction and build yearbooks</Paragraph>
          <Paragraph>It also may includes the information you choose to share when you take an action, such as when you add a friend, add a place to your story, find friends using our contact importers, or indicate you are in a relationship, ask for an introduction, add alumni to favourites or accept intro requests.</Paragraph>
          <Paragraph>Your name, profile pictures, cover photos, gender, networks, friends list, public profiles of friends, likes interests,username and User ID are treated just like information you choose to make public.</Paragraph>
          <Paragraph>Your birthday allows us to do things like show you age-appropriate content and advertisements.</Paragraph>
          <Subtitle>Does Collaborative-Tech.co collect my personal information if I am not a member and just visit the site?</Subtitle>
          <Paragraph>No. We don’t collect any personal information about visitors to our site. If you do visit us without becoming a member, we might place session ID cookies on your computer with your consent</Paragraph>
          <Paragraph>Other information we receive about you</Paragraph>
          <Paragraph>We also receive other types of information about you:</Paragraph>
          <Paragraph>Sometimes we get data from our advertising partners, customers and other third parties that helps us (or them) deliver ads, understand online activity, and generally make Collaborative-Tech.co better. For example, an advertiser may tell us information about you (like how you responded to an ad on Collaborative-Tech.co e or on another site) in order to measure the effectiveness of - and improve the quality of - ads.</Paragraph>
          <List>
            <Item>We receive data about you whenever you interact with Collaborative-Tech.co, such as when you look at another person’s timeline, send or receive a message, search for a friend/alumni or a Page, click on, view or otherwise interact with things, use a Collaborative-Tech.co mobile app, or purchase Collaborative-Tech.co Credits or make other purchases through Collaborative-Tech.co.</Item>
            <Item>When you post things like photos or videos on Collaborative-Tech.co, we may receive additional related data (or metadata), such as the time, date, and place you took the photo or video.</Item>
            <Item>We receive data from the computer, mobile phone or other device you use to access Collaborative-Tech.co, including when multiple users log in from the same device. This may include your IP address and other information about things like your internet service, location, the type (including identifiers) of browser you use, or the pages you visit. For example, we may get your GPS or other location information so we can tell you if any of your friends are nearby.</Item>
            <Item>We receive data whenever you visit a game, application, or website that uses Collaborative-Tech.co Platform or visit a site with a Collaborative-Tech.co feature (such as a social plugin), sometimes through cookies. This may include the date and time you visit the site; the web address, or URL, you’re on; technical information about the IP address, browser and the operating system you use; and, if you are logged in to Collaborative-Tech.co, your User ID.</Item>
          </List>
          <Paragraph>When we get your GPS location, we put it together with other location information we have about you (like your current city). But we only keep it until it is no longer useful to provide you services, like keeping your last GPS coordinates to send you relevant notifications.</Paragraph>
          <Paragraph>We only provide data to our advertising partners or customers after we have removed your name or any other personally identifying information from it, or have combined it with other people’s data in a way that it is no longer associated with you.</Paragraph>
          <Subtitle>What does Collaborative-Tech.co do with the information it receives?</Subtitle>
          <Paragraph>We use the information we receive about you in connection with the services and features we provide to you and other users like your friends, our partners, your alma mater institutions, and the developers that build the games, applications, and websites you use. For example, we may use the information we receive about you:</Paragraph>
          <List>
            <Item>as part of our efforts to keep Collaborative-Tech.co products, services and integrations safe and secure;</Item>
            <Item>to protect Collaborative-Tech.co or others’ rights or property;</Item>
            <Item>to provide you with location features and services, like telling you and your friends when something is going on nearby;</Item>
            <Item>to provide a communication channel for your alma mater to reach you, e.g. your school or University</Item>
            <Item>to make suggestions to you and other users on Collaborative-Tech.co, such as: suggesting that your friend use our contact importer because you found friends using it, suggesting that another user add you as a friend because the user imported the same email address as you did, or suggesting that your friend tag you in a picture they have uploaded with you in it;</Item>
            <Item>and for internal operations, including troubleshooting, data analysis, testing, research and service improvement. Granting us this permission not only allows us to provide Collaborative-Tech.co as it exists today, but it also allows us to provide you with innovative features and services we develop in the future that use the information we receive about you in new ways.</Item>
          </List>
          <Subtitle>Cookies, Pixels, other technologies we use</Subtitle>
          <Paragraph>Cookies are small pieces of data that are stored on your computer, mobile phone or other device. Pixels are small blocks of code on webpages that do things like allow another server to measure viewing of a webpage and often are used in connection with cookies. We use technologies like cookies, pixels, and local storage (like on your browser or device, which is similar to a cookie but holds more information) to provide and understand a range of products and services</Paragraph>
          <Paragraph>We use these technologies to do things like:</Paragraph>
          <List>
            <Item>make Collaborative-Tech.co easier or faster to use;</Item>
            <Item>enable features and store information about you (including on your device or in your browser cache) and your use of Collaborative-Tech.co;</Item>
            <Item>deliver, understand and improve advertising;</Item>
            <Item>monitor and understand the use of our products and services;</Item>
            <Item>and to protect you, others and Collaborative-Tech.co.</Item>
          </List>
          <Paragraph>For example: we may use them to know you are logged in to Collaborative-Tech.co, to help you use social plugins and share buttons, or to know when you are interacting with our advertising or Platform partners.</Paragraph>
          <Paragraph>We may ask advertisers or other partners to serve ads or services to computers, mobile phones or other devices, which may use a cookie, pixel or other similar technology placed by Collaborative-Tech.co or the third party (although we would not share any other information that identifies you with an advertiser).</Paragraph>
          <Subtitle>Does Collaborative-Tech.co disclose my information to other parties?</Subtitle>
          <Paragraph>We may share aggregated information that includes your personal information (but which doesn’t identify you directly), together with other information including log data with third parties for industry analysis and demographic profiling and to deliver targeted advertising about other products and services.</Paragraph>
          <Paragraph>In particular, in relation to targeted advertising, we use third-party advertising companies to serve ads when you visit our Website. These companies may use information about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.</Paragraph>
          <Paragraph>Collaborative-Tech.co does provide your public information to your alma mater institutions, that you have stated in your Facebook or Linkedin profile, which you give consent to do when signing into the app. So it is very important to keep your information about your alma mater truthful on these social networks as well as Collaborative-Tech.co</Paragraph>
          <Paragraph>Collaborative-Tech.co also wishes to maintain a healthy community, and we will cooperate with all law enforcement inquiries and with all third parties to enforce their intellectual property or other rights. We may also disclose your personal information to government or law enforcement agencies, or private parties, as required by law when/or, in our sole discretion, we believe that disclosure is necessary to protect our legal rights, or those of third parties and/or to comply with a judicial proceeding, court order, or legal process served on us.</Paragraph>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default injectSheet(styles)(PrivacyPolicy)