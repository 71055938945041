import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, desktop } from './../../../../theme/base'

const styles = {
  root: {
    background: `url(${require('./time-empty.svg')}) no-repeat`,
    backgroundPosition: 'top center',
    width: '72vw',
    margin: [rem(24), 'auto', 0],
    paddingTop: rem(72),
    color: theme.colors.slateGrey,
    fontSize: rem(13),
    lineHeight: rem(20),
    textAlign: 'center',
    [desktop]: {
      width: '100%',
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      zIndex: 1,
    },
  },
}


const Static = ({ classes }) => 
  <div className={classes.root}>
    Set a task length and a due date to get the final price
  </div>

export default injectSheet(styles)(Static)