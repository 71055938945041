import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content } from './../../theme/base'
import { useEvents, useTutor } from './../../hooks'
import { utils } from '@verdaccio/mt-uikit'
import WaitTextBlock from './WaitTextBlock'

const styles = {
  root: {
    paddingLeft: rem(8),
    paddingRight: rem(8),
    textAlign: 'center',
    position: 'relative',
  },
  content,
  time: {
    marginTop: rem(24),
    fontSize: rem(11),
    lineHeight: rem(16),
    color: theme.colors.blueyGrey,
  },
  avatar: {
    backgroundImage: `url(${require('./../../assets/images/loader-tutor.svg')})`,
    backgroundSize: [rem(64), rem(64)],
    backgroundRepeat: 'no-repeat',
    width: rem(64),
    height: rem(64),
    margin: [rem(12), 'auto', 0],
    position: 'relative',
    '& img': {
      width: rem(40),
      height: rem(40),
      borderRadius: '50%',
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    marginTop: rem(24),
    color: theme.colors.charcoalGrey,
    fontSize: rem(21),
    lineHeight: rem(28),
    fontWeight: 600,
    textAlign: 'center',
  },
}

const WaitRequestedTutor = ({
  classes,
  order,
}) => {
  const eventOrderPaid = useEvents(order.id).find(({ event }) => event === 'order_paid')
  const requestedTutor = useTutor(order.requested_tutor_id)
  const items = [
    {
      icon: 'notification',
      type: 'img',
      text: () => <>If requested tutor cannot accept the task, we will find another relevant tutor.</>,
    },
    { 
      icon: 'flag',
      text: () => <>Meanwhile, you can send additional instructions and files via the chat.</>, 
    },
  ]
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {eventOrderPaid &&
          <div className={classes.time}>{utils.printDateTime(eventOrderPaid.time)}</div>
        }
        <div className={classes.avatar}>
          <img src={utils.getUserAvatarUrl(requestedTutor.id)} alt='' />
        </div>
        <div className={classes.title}>Waiting for {requestedTutor.name}</div>
        <WaitTextBlock items={items} />
      </div>
    </div>
  )
}

export default injectSheet(styles)(WaitRequestedTutor)