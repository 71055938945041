import React from 'react'
import { Prompt } from '@verdaccio/mt-uikit'

const OtherPhoneNumber = ({
  onCancel,
  onClose,
  onAccept,
  ...otherProps
}) => {
  const _onAccept = () => { console.log('OtherPhoneNumber'); onClose() }
  const _onClose = () => { onCancel && onCancel(); onClose() }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='phone' />
      <Prompt.Title>Have other phone number?</Prompt.Title>
      <Prompt.Details details={[
        'Because of technical reasons it’s not be able to change phone number manually. Please, contact customer support to setup another phone number.'
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='primary' onClick={_onAccept}>Support</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default OtherPhoneNumber