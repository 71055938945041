import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors, utils } from '@verdaccio/mt-uikit'
import { media } from './../../theme/base'
import Popup from './../Popup'
import Bubble from './layout/Bubble'
import Banner from './layout/Banner'
import SupportIcon from './layout/SupportIcon'
import { useTrackClicks, useTrackValues } from './../../lrtracker'

let CLOSED_POPUP = []

export default ({
  bubbleLayout,
  bannerLayout,
  banner,
  context,
  ...otherProps
}) => {
  const { nmessages, meta } = useSelector(selectors.notificationSelectors.support)
  const modals = useSelector(state => state.modals)
  const [showPopup, setShowPopup] = useState(CLOSED_POPUP)
  const isDesktop = !utils.useMobile(media.desktop)

  const hidePopup = popupId => {
    CLOSED_POPUP = [...CLOSED_POPUP, popupId]
    setShowPopup(CLOSED_POPUP)
  }

  const unreadMessage = nmessages ? meta.lastItem : null

  const popupAvailable = (
    isDesktop
    && showPopup.indexOf(banner && banner.id) === -1
    && showPopup.indexOf(unreadMessage && unreadMessage.id) === -1
    && (bubbleLayout.layout !== 'bottom' || modals.length === 0)
  )

  const unreadMessageAvailable = unreadMessage && popupAvailable
  const bannerAvailable = banner && popupAvailable

  let content = null
  let layout = null
  switch (true) {
    case unreadMessageAvailable:
      layout = bubbleLayout
      content = <Bubble
        className={bubbleLayout.layout}
        onClick={useTrackClicks(otherProps.onClick, 'SupportIcon.UnreadMessage', { context })}
        onClose={() => hidePopup(unreadMessage.id)}>
        {unreadMessage.message}
      </Bubble>
      break;
    case bannerAvailable:
      layout = bannerLayout
      content = <Banner
        {...banner}
        className={bannerLayout.layout}
        onClick={useTrackClicks(otherProps.onClick, 'SupportIcon.Banner', { context: { ...context, title: banner.title } })}
        onClose={() => hidePopup(banner.id)}
      />
      break;
    default:
    // 
  }

  useTrackValues(
    unreadMessageAvailable,
    'SupportIcon.unreadMessageAvailable',
    { context, predicat: _ => unreadMessageAvailable }
  )

  return <Popup
    trigger={
      <SupportIcon
        nmessages={nmessages}
        {...otherProps}
        onClick={useTrackClicks(otherProps.onClick, 'SupportIcon', { context: { ...context, nmessages: nmessages > 0 } })}
      />
    }
    content={content}
    {...layout}
  />
}
