import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import theme, { rem, desktop, adaptive, media, ANDROID_HELPER_ID } from './../../../theme/base'
import {
  // Button,
  Textarea,
  Uploader,
  utils,
} from '@verdaccio/mt-uikit'
import { S3 } from './../../../config'
import DesktopWrapperHOC from './.././../DesktopWrapperHOC'
import { Banner, SaveAndClose, Submit } from '../Layout'
import {
  // isMobile,
  isAndroid,
} from './../../../utils'

const PADDING_BOTTOM = 290

const styles = {
  root: {
    paddingTop: rem(24),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    [desktop]: {
      minHeight: rem(529),
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    [adaptive]: {
      paddingBottom: rem(PADDING_BOTTOM),
    },
  },
  submit: {
    background: theme.colors.white,
    padding: [rem(24), 0],
    marginBottom: 'auto',
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    [desktop]: {
      background: 'transparent',
    },
    [adaptive]: {
      marginTop: 0,
      padding: rem(24),
      position: 'fixed',
    },
  },
  textarea: {
    height: rem(130),
    marginTop: rem(16),
    padding: rem(12),
    border: `${rem(1)} solid #D6E3FF !important`,
    borderRadius: rem(8),
    '&::placeholder': {
      color: theme.colors.blueyGrey,
    },
  },
  skip: {
    textAlign: 'center',
    '& span': {
      fontFamily: theme.fontFamilies.SFProRoundedRegular,
      color: theme.colors.blue,
      fontSize: rem(20),
      lineHeight: rem(24),
      fontWeight: 600,
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  uploader: {
    [desktop]: {
      flexGrow: 1,
    },
  },
  saveAndClose: {
    [desktop]: {
      position: 'sticky',
      marginLeft: rem(-24),
      marginRight: rem(-24),
    },
  },
}

const placeholder = 'Please describe your problem and what you want Nerdy Tutors to help you with.'

const OrderDetails = ({
  banner,
  details,
  files,
  onChangeDetails,
  onChangeFiles,
  classes,
  onSubmit,
  onSaveOrderForce,
}) => {
  const uploadRef = useRef(null)
  const submitRef = useRef(null)

  const [fileUploading, setFileUploading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const detailsError = details.trim().length === 0
  const isValid = [detailsError].some(error => error === false)
  const disabled = submitted && (!isValid || fileUploading)

  const _onSubmit = isValid
    ? onSubmit
    : () => setSubmitted(true)

  const uploadHeight = uploadRef && uploadRef.current ? uploadRef.current.clientHeight : 0
  const submitHeight = submitRef && submitRef.current ? submitRef.current.clientHeight : 0

  const isMobile = utils.useMobile(media.desktop)

  const onFocus = () => {
    const wrapper = document.getElementById(ANDROID_HELPER_ID)
    if (isMobile && isAndroid) {
      wrapper.scrollTop = wrapper.scrollHeight - PADDING_BOTTOM - uploadHeight - submitHeight
    }
  }

  return (
    <div className={classes.root}>
      {isMobile &&
        <Banner
          title={banner.title}
          description={banner.text}
          image={require('./banner.svg')}
        />
      }
      <Textarea
        className={classnames(classes.textarea, { error: submitted && detailsError })}
        placeholder={placeholder}
        minRows={4}
        value={details}
        onChange={e => onChangeDetails(e.target.value)}
        onFocus={onFocus}
      />
      <div className={classes.uploader} ref={uploadRef}>
        <Uploader
          config={S3}
          files={files}
          onChange={onChangeFiles}
          onStatusChange={status => setFileUploading(status)}
        />
      </div>
      {/* <div className={classes.submit} ref={submitRef}>
        {details.trim() || files.length > 0 || fileUploading
          ? <Button
              block
              size='large'
              onClick={onNext}
              disabled={fileUploading}
            >
              Continue
            </Button>
          : <div className={classes.skip} onClick={onNext}>
              <span>Skip this step</span>
            </div>
        }
      </div> */}
      <Submit
        ref={submitRef}
        text='Continue'
        disabled={disabled}
        onSubmit={_onSubmit}
      />
      <SaveAndClose className={classes.saveAndClose} onClick={onSaveOrderForce} />
    </div>
  )
}
export default DesktopWrapperHOC(injectSheet(styles)(OrderDetails))