import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import theme, { rem } from './../../../theme/base'
import CloseIcon from './CloseIcon'

const styles = {
  root: {
    padding: [rem(8), rem(16 + 12 + 8), rem(8), rem(12)],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: [rem(20), rem(20), rem(20), rem(2)],
    background: theme.colors.white,
    maxHeight: rem(70),
    maxWidth: rem(256),
    minWidth: rem(100),
    width: 'fit-content',
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(17),
    lineHeight: rem(20),
    position: 'relative',
    '&:hover': {
      background: '#C8D9FF',
    },
    '&.right': {
      borderRadius: [rem(20), rem(20), rem(20), rem(2)],
    },
    '&.bottom': {
      borderRadius: [rem(20), rem(2), rem(20), rem(20)],
      boxShadow: `0px 4px 8px rgba(86, 93, 107, 0.1)`
    },
  },
  text: {
    cursor: 'pointer',
  }
}

const Bubble = ({
  classes,
  className,
  children,
  onClick,
  onClose,
  innnerRef
}) =>
  <div
    className={classnames(classes.root, className)}
    ref={innnerRef}
  >
    <CloseIcon onClick={onClose} />
    <div className={classes.text} onClick={onClick}>
      {children}
    </div>
  </div>

export default injectSheet(styles)(Bubble)
