import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
    fontSize: rem(22),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const Name = ({ classes, children }) => 
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Name)