import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(24),
    position: 'relative',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: rem(-24),
      right: rem(-24),
      top: 0,
      height: rem(1),
      background: theme.colors.paleGrey,
    },
  },
}

const Wrapper = ({ classes, children }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Wrapper)