import axios from 'axios'
import queryString from 'query-string'

function getGoogleClientId() {
  let p = ('; ' + document.cookie).split('; ' + '_ga=')
  if (p.length === 2) {
    const _ga = p.pop().split(';').shift()
    return _ga.split('.').slice(2, 4).join('.')
  }
}

const getUTM = () => {
  const { url, query } = queryString.parseUrl((localStorage.getItem('_mt-location') || "").replace(/\+/g, '%2B'))
  return {
    landing: url.replace(/(https?:\/\/)?/, ""),
    query: { ...query, ga_client_id: getGoogleClientId() }
  }
}

export const checkIfExists = phone => axios.post(`${ENV.A5_URL}/api/v1/number/is_exists`, {
  phone,
  host: ENV.SITE_ID,
}).then(result => result.data.result)

export const createUser = ({ email, password, phone, name, googleId }) => {
  const { landing, query } = getUTM()
  return axios.post(`${ENV.A5_URL}/api/v3/users/client/new`, {
    email,
    password,
    name,
    phone,
    gmail_id: googleId,
    host: ENV.SITE_ID,
    timezone_str: Intl.DateTimeFormat().resolvedOptions().timeZone,
    profile_landing: landing,
    profile_landing_query: query,
    register_bonus_value: 1000,
    register_bonus_comment: 'Marketing incentive: Sign up now and get up to 4 tutoring sessions for FREE'
  })
    .then(result => result.data)
    .catch(err => { throw createError(err) })
}

export const factorAuthPassword = (email, password) => axios.post(`${ENV.A5_URL}/api/v3/factor_auth`, {
  code: password,
  value: email,
  factor: 'login',
  host: ENV.SITE_ID,
})
  .catch(err => { throw createError(err) })
  .then(result => result.data.session)
  .then(xToken => getUser(xToken).then(user => ({ xToken, ...user })))

export const factorAuthGmail = (email, googleId) => axios.post(`${ENV.A5_URL}/api/v3/factor_auth`, {
  code: googleId,
  value: email,
  factor: 'gmail',
  host: ENV.SITE_ID,
})
  .catch(err => { throw createError(err) })
  .then(result => result.data.session)
  .then(xToken => getUser(xToken).then(user => ({ xToken, ...user })))

export const factorAuthPhone = (phone, code = undefined) => axios.post(`${ENV.A5_URL}/api/v3/factor_auth`, {
  code,
  value: phone,
  factor: 'phone',
  host: ENV.SITE_ID,
})

export const restorePassword = email => axios.post(`${ENV.A5_URL}/api/v3/users/client/restore`, {
  email,
  host: ENV.SITE_ID,
})
  .catch(err => { throw createError(err) })

export const getUser = token => axios.get(`${ENV.A5_URL}/api/v3/users/client/me`, { headers: { 'X-Token': token } })
  .catch(err => { throw createError(err) })
  .then(result => ({
    cid: result.data['_id'],
    key: 'password',
    profile: {
      name: result.data.name,
      email: result.data.email,
      phone: result.data.phone,
      balance: result.data.available_balance * 100,
      favTutors: result.data.fave_writers,
      blockTutors: result.data.writer_block,
    }
  }))

export const getAnyUserInfo = token => userId => axios.get(`${ENV.A5_URL}/api/v3/users/none/${userId}`, { headers: { 'X-Token': token } })
  .catch(err => { throw createError(err) })
  .then(result => result.data)

export const updateUser = token => ({ email, name, phone }) => axios.patch(`${ENV.A5_URL}/api/v3/profile`, { email, name, phone }, { headers: { 'X-Token': token } })
  .then(result => ({
    cid: result.data['_id'],
    key: 'password',
    profile: {
      name: result.data.name,
      email: result.data.email,
      phone: result.data.phone,
      balance: result.data.available_balance * 100,
      favTutors: result.data.fave_writers,
      blockTutors: result.data.writer_block,
    }
  }))
  .catch(err => { throw createError(err) })

export const getTutors = token => axios.get(`${ENV.A5_URL}/api/v3/profile/related_writers`, {
  headers: { 'X-Token': token }
})

export const requestCode = (phone) => checkIfExists(phone)
  .then(result => result === true
    ? factorAuthPhone(phone)
    : createUser({ phone })
      .then(result => factorAuthPhone(result.data.phone))
  )

export const applyCode = (phone, code) =>
  factorAuthPhone(phone, code)
    .then(result => result.data.session)

export const getUserInfo = token =>
  Promise.all([getUser(token), getTutors(token)])
    .then(([user, tutors]) => ({
      user: user.data,
      tutors: tutors.data
    }))

export const updateOrCreateOrder = payload => axios
  .post(`${ENV.NT_URL}/update_or_create_order`, payload)
  .then(response => response.data.id)

export const makePaymentFromBalance = payload => axios
  .post(`${ENV.NT_URL}/make_payment_from_balance`, payload)
  .then(response => response.data.id)

export const extendOrder = payload => axios
  .post(`${ENV.NT_URL}/extend_order`, payload)
  .then(response => response.data.id)

export const getPaymentLink = (orderId, state) =>
  `${ENV.PTGW_URL}/p/i${orderId}`
  + `?success_url=${ENV.ME}/payment-success/${orderId}`
  + `&cancel_url=${ENV.ME}/payment-cancel/${orderId}/${state}`
  + `&error_url=${ENV.ME}/payment-failed/${orderId}/${state}`
  + `&expired_url=${ENV.ME}/payment-cancel/${orderId}/${state}`

export const createSmstoolReminder = (cid, body) => axios.post(`${ENV.A5_URL}/smstool/send_message_new/`, {
  smstool_name: ENV.SMSTOOL_NAME,
  silently: true,
  _no_lock_: true,
  crm_id: cid,
  body,
})

const ERROR_MAP = {
  'User wasn\'t find': 'User not found. Please Sign up',
  'Already exists email': 'User exists, please try to Log in',
  'Cant find code': 'Incorrect password',
  'User not found': 'User not found. Please Sign up',
  'Already exists phone': 'This phone number is already in use'
}

export const createError = err => {
  const message = ERROR_MAP[err.response.data.error_message || err.response.data.detail] || 'Something went wrong'
  const error = new Error(message)
  error.details = message
  return error
}
