import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../../../../theme/base'

const styles = {
  root: {
    marginTop: rem(8),
    color: theme.colors.blue,
    fontSize: rem(15),
    lineHeight: rem(20),
    fontWeight: 600,
    textAlign: 'center',
    '& span': {
      cursor: 'pointer',
    },
  },
}

const Details = ({
  classes,
  onClick,
}) => 
  <div className={classes.root}>
    <span onClick={onClick}>View full details</span>
  </div>

export default injectSheet(styles)(Details)