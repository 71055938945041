import React from 'react'
import { Alert } from '@verdaccio/mt-uikit'

export const AlertReportSent = (props) => <Alert
  icon={require('./complete-icon.svg')}
  title='Report sent'
  details={[
    'Thanks for letting us know about the problem. Our customer support will contact you soon.',
  ]}
  label='OK'
  {...props}
/>

export const AlertRequestSent = (props) => <Alert
  icon={require('./complete-icon.svg')}
  title='Thank you!'
  details={[
    'Your revision request was sent.  We will get in touch with you shortly.',
  ]}
  label='Got it!'
  {...props}
/>

export const AlertGoodRate = (props) => <Alert
  icon={require('./rate-icon.svg')}
  title='Thank you for sharing your feedback!'
  details={[
    'Our tutors are looking forward to helping you in the future!',
  ]}
  label='Got it!'
  {...props}
/>

export const AlertBadRate = (props) => <Alert
  icon={require('./sad-icon.svg')}
  title="We are really sorry you've had a negative experience"
  details={[
    'Our team will review your  feedback and get back to you ASAP.',
  ]}
  label='Got it!'
  {...props}
/>

export const AlertRevisionRequestSent = (props) => <Alert
  icon={require('./complete-icon.svg')}
  title="Thank you!"
  details={[
    'Your revision request was sent.  We will get in touch with you shortly.',
  ]}
  label='Got it!'
  {...props}
/>