import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useActions } from './../../actions'
import { useApi } from './../../hooks'
import {
  createTimeline,
  createChat,
  getOrderNodeName,
  getOrderLogNodeName,
  selectors,
  utils,
  UserInfo,
} from '@verdaccio/mt-uikit'
import { isOrderClosed } from './../../nerdytutors'
import { TAB_CHAT, TAB_TIMELINE } from './tabs'
import MobileView from './MobileView'
import DesktopView from './DesktopView'
import WaitTutor from './WaitTutor'
import WaitRequestedTutor from './WaitRequestedTutor'
import { media } from '../../theme/base'
import { useOrder, useTutor, useUserProfile } from './../../hooks'

const useOnHoverUser = item => {
  const getAnyUserInfo = useApi('getAnyUserInfo')
  const [data, setData] = useState(null)
  const onOpen = data === null
    ? () => getAnyUserInfo(item.from)
      .then(data => setData({
        name: data.visible_name,
        timezone: data.timezone_str
      }))
    : null
  const content = data ? <UserInfo {...data} /> : null
  return { onOpen, content }
}

const Chat = createChat(null, { useActions, useSelector, useDispatch })
const Timeline = createTimeline(null, { useActions, useSelector, useDispatch })

const OrderChatPage = ({ match }) => {
  const { tab, orderid } = match.params

  const nodeName = getOrderNodeName(orderid)
  const nodeLogName = getOrderLogNodeName(nodeName)

  const actions = useActions()
  const chatsNotifications = useSelector(selectors.notificationSelectors.chats)
  const logsNotifications = useSelector(selectors.notificationSelectors.logs)
  const history = useHistory()
  const tabs = [
    {
      name: 'Chat',
      key: TAB_CHAT,
      active: tab === TAB_CHAT,
      notify: chatsNotifications[nodeName].nmessages > 0,
      onClick: () => history.push(`/order/${orderid}/${TAB_CHAT}`),
    },
    {
      name: 'Timeline',
      key: TAB_TIMELINE,
      active: tab === TAB_TIMELINE,
      notify: logsNotifications[nodeLogName].nmessages > 0,
      onClick: () => history.push(`/order/${orderid}/${TAB_TIMELINE}`),
    },
  ]

  useUserProfile()

  const order = useOrder(nodeName)
  const requestedTutor = order && useTutor(order.requested_tutor_id)

  const onMore = () => actions.onOrderContext(order.id)
  const onOrderDetails = () => actions.onOrderDetails(order.id)
  const closed = isOrderClosed(order)
  const isMobile = utils.useMobile(media.desktop)

  const onUserClick = tutorId => {
    if (order.tutor && tutorId === order.tutor.id) {
      actions.onTutorProfile(order.tutor.id, order.id)
    }
  }

  const defaultScreen = !order.tutor
    ? order.requested_tutor_id
      ? <WaitRequestedTutor order={order} />
      : <WaitTutor order={order} />
    : null

  const isBadDeadline = (deadline) => new Date().getTime() > new Date(deadline).getTime()

  return React.createElement(
    isMobile ? MobileView : DesktopView,
    {
      order,
      onMore,
      onOrderDetails,
      tabs,
      tab,
      nodeName,
      renderChat: props => <Chat
        node={nodeName}
        order={order}
        {...props}
        closed={closed}
        defaultScreen={defaultScreen}
        requestedTutor={requestedTutor}
        onRequestTutor={order.tutor ? () => actions.onRequestTutor(order.tutor.id) : null}
        onNewTask={() => actions.onNewTask()}
        onUserClick={onUserClick}
        useOnHoverUser={!isMobile ? useOnHoverUser : null}
        addons={isBadDeadline(order.deadline)
          ? []
          : [{
              iconName: 'chat-form-add-hours-icon',
              onClick: () => actions.onPromptBuyExtraHours(order),
            }]
        }
      />,
      renderTimeline: props => <Timeline
        node={nodeName}
        order={order}
        requestedTutor={requestedTutor}
        {...props}
      />
    })
}
export default OrderChatPage