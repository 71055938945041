import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import theme, { rem, content, desktop } from './../../theme/base'
import { Button } from '@verdaccio/mt-uikit'
import { useActions } from './../../actions'

const styles = {
  // background: {
  //   backgroundImage: 'linear-gradient(to bottom, #ebeef5, #e5e9ef)',
  //   height: rem(252),
  //   paddingTop: rem(74),
  // },
  background: {
    backgroundImage: `url(${require('./empty.svg')})`,
    backgroundPosition: `center ${rem(16)}`,
    backgroundRepeat: 'no-repeat',
    // TODO: add '#e7ebf1' to theme.colors
    // backgroundColor: '#e7ebf1',
    height: rem(200),
    position: 'relative',
    overflowX: 'hidden',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      right: `calc(50vw + ${rem(185)})`,
      bottom: 0,
      // TODO: add '#f6f7fb' to theme.colors
      background: '#f6f7fb',
      width: `calc(50vw - ${rem(185)})`,
      height: rem(66),
    },
    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: `calc(50vw + ${rem(185)})`,
      bottom: 0,
      // TODO: add '#f6f7fb' to theme.colors
      background: '#f6f7fb',
      width: `calc(50vw - ${rem(185)})`,
      height: rem(66),
    },
    [desktop]: {
      marginTop: rem(32),
    },
  },
  content: {
    ...content,
    paddingBottom: rem(32),
  },
  wrapper1: {
    paddingLeft: rem(8),
    paddingRight: rem(8),
  },
  wrapper2: {
    paddingLeft: rem(16),
    paddingRight: rem(16),
  },
  title: {
    marginTop: rem(16),
    fontSize: rem(22),
    lineHeight: rem(28),
    color: theme.colors.charcoalGrey,
    fontWeight: 600,
    textAlign: 'center',
  },
  subtitle: {
    marginTop: rem(8),
    fontSize: rem(15),
    lineHeight: rem(22),
    color: theme.colors.slateGreyTwo,
    letterSpacing: rem(0.09),
    textAlign: 'center',
  },
  button: {
    marginTop: rem(24),
    padding: 0,
    '&:hover': {
      color: theme.colors.white,
    },
  },
}

const Empty = ({ classes }) => {
  const actions = useActions()
  return (
    <Fragment>
      <div className={classes.background} />
      <div className={classes.content}>
        <div className={classes.wrapper1}>
          <div className={classes.title}>Create your first task</div>
          <div className={classes.subtitle}>Describe your problem and our AI algorithm will find the best tutor to help you</div>
        </div>
        <div className={classes.wrapper2}>
          <Button
            className={classes.button}
            size='large'
            block
            onClick={() => actions.onNewTask()}
          >
            Create First Task
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

export default injectSheet(styles)(Empty)