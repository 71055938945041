import React from 'react'
import injectSheet from 'react-jss'
import { Prompt, theme } from '@verdaccio/mt-uikit'

const styles = {
  link: {
    color: theme.colors.blue,
    fontWeight: 600,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
}

const BuyExtraHours = ({
  classes,
  orderId,
  onCancel,
  onClose,
  onAccept,
  onOrderChat,
  ...otherProps
}) => {
  const _onAccept = () => { onAccept(); onClose() }
  const _onClose = () => { onCancel && onCancel(); onClose() }
  const _onOrderChat = () => { onOrderChat(); onClose() }
  return (
    <Prompt onClose={onClose} {...otherProps}>
      <Prompt.Icon name='add' />
      <Prompt.Title>Buy extra hours</Prompt.Title>
      <Prompt.Details details={[
        'Please make sure that your tutor agrees with the task scope increase. Otherwise, they may not be able to fulfill your request.',
        () => <div className={classes.link} onClick={_onOrderChat}>Chat with my tutor</div>,
      ]}/>
      <Prompt.Actions>
        <Prompt.Button type='outline' onClick={_onClose}>Cancel</Prompt.Button>
        <Prompt.Button type='primary' onClick={_onAccept}>Continue</Prompt.Button>
      </Prompt.Actions>
    </Prompt>
  )
}

export default injectSheet(styles)(BuyExtraHours)