import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import regexps from './../../utils/regexps'
import injectSheet from 'react-jss'
import { compact } from 'lodash/array'
import { useActions } from './../../actions'
import theme, { rem, desktop } from '../../theme/base'
import { Icon } from '@verdaccio/mt-uikit'
import DesktopWrapperHOC2 from './../DesktopWrapperHOC2'
import { Wrapper, Error, Form, Field, Submit } from './Layout'
import StateLink from './Layout/StateLink'

const styles = {
  root: {
    minHeight: '100vh',
    paddingTop: 0,
    paddingBottom: rem(100),
    borderRadius: 0,
    [desktop]: {
      minHeight: 0,
      borderRadius: rem(28),
    },
  },
  header: {
    padding: [rem(12), 0, rem(10)],
    fontSize: rem(17),
    lineHeight: rem(22),
    fontFamily: theme.fontFamilies.SFProRoundedBlack,
    textAlign: 'center',
    position: 'relative',
  },
  back: {
    width: rem(28),
    height: rem(28),
    position: 'absolute',
    left: rem(-8),
    top: rem(8),
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      pointerEvents: 'none',
      opacity: 0.7,
    },
  },
  icon: {
    width: '100%',
    maxWidth: rem(327),
    margin: [rem(24), 'auto', 0],
    display: 'block',
  },
  text: {
    marginTop: rem(24),
    fontSize: rem(15),
    lineHeight: rem(20),
    color: theme.colors.slateGrey,
    textAlign: 'center',
  },
  submit: {
    marginTop: rem(24),
    padding: 0,
    border: 'none !important',
    '& a': {
      color: 'inherit',
      lineHeight: rem(56),
      display: 'block',
    },
  },
  helper: {
    paddingTop: rem(16),
  },
  bottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: rem(70),
    textAlign: 'center',
    [desktop]: {
      bottom: rem(24),
    },
    '& a': {
      color: theme.colors.blue,
    },
  },
}

const Restore = ({ classes }) => {
  const location = useLocation()

  const { onRestoreUserPassword } = useActions()

  const [email, setEmail] = useState(location.state && location.state.email || '')
  const [submit, setSubmit] = useState(false)
  const [submitError, setSubmitError] = useState()
  const [restored, setRestored] = useState(false)

  const cleanedEmail = email.trim().toLowerCase()

  const validEmail = regexps.email(cleanedEmail)
  const valid = validEmail

  const errorEmail = submit && !validEmail && 'Oops, check your email format.'

  const disabled = submit && !valid

  const errors = []
  errors.push(submitError)
  errors.push(errorEmail)
  const error = compact(errors).length > 0 ? compact(errors)[0] : false

  const onSubmit = () => {
    setSubmit(true)
    if (valid) {
      onRestoreUserPassword(cleanedEmail)
        .then(() => {
          setRestored(true)
        })
        .catch(err => setSubmitError(err.details))
    }
  }

  return (
    <Wrapper className={classes.root}>
      <div className={classes.header}>
        <StateLink className={classes.back} to='/login' state={{ email }}>
          <Icon iconName='arrow-back' />
        </StateLink>
        Restore password
      </div>
      {restored &&
        <Fragment>
          <img className={classes.icon} src={require('./restored.svg')} alt='' />
          <div className={classes.text}>
            New password was sent.
            <br />
            Please check your email
          </div>
          <Submit className={classes.submit}>
            <StateLink to='/login' state={{ email }}>Back to Login page</StateLink>
          </Submit>
        </Fragment>
      }
      {!restored &&
        <div className={classes.helper}>
          {error &&
            <Error>{error}</Error>
          }
          <Form>
            <Field
              type='email'
              placeholder='Enter your email'
              value={cleanedEmail}
              onChange={value => setEmail(value)}
              error={errorEmail}
            />
            <Submit onClick={onSubmit} disabled={disabled}>Restore</Submit>
          </Form>
          <div className={classes.bottom}>
            <StateLink to='/login' state={{ email }}>Back to Login page</StateLink>
          </div>
        </div>
      }
    </Wrapper>
  )
}

export default DesktopWrapperHOC2(injectSheet(styles)(Restore))