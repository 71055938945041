import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Button } from '@verdaccio/mt-uikit'
import theme, { rem, content, ui, adaptive } from './../../theme/base'

const styles = {
  root: {
    background: theme.colors.blue,
    padding: [rem(80), 0],
    textAlign: 'center',
    position: 'relative',
    overflowX: 'hidden',
    [adaptive]: {
      padding: [rem(64), rem(16)],
    },
    '& button': {
      background: theme.colors.white,
      width: rem(327),
      marginTop: rem(24),
      marginLeft: 'auto',
      marginRight: 'auto',
      color: theme.colors.blue,
      fontFamily: theme.fontFamilies.SFProRoundedSemibold,
      borderRadius: rem(16),
      '&:hover': {
        // TODO: add '#e6edff' to theme.colors
        background: '#e6edff',
        // TODO: add '#366ce6' to theme.colors
        color: '#366ce6',
      },
      '&:active': {
        // TODO: add '#d6e3ff' to theme.colors
        background: '#d6e3ff',
        color: theme.colors.mediumBlue,
      },
      [adaptive]: {
        width: '100%',
      },
    },
  },
  content,
  title: {
    ...ui.title,
    color: theme.colors.white,
    position: 'relative',
    zIndex: 1,
  },
  text: {
    ...ui.text,
    marginTop: rem(16),
    color: theme.colors.white,
  },
  random: {
    width: rem(88),
    marginTop: rem(40),
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    [adaptive]: {
      marginTop: rem(32),
    },
    '& > div': {
      width: rem(40),
      height: rem(40),
      display: 'inline-block',
      verticalAlign: 'top',
      '&:nth-child(1)': {
        width: rem(32),
        height: rem(32),
        position: 'absolute',
        left: 0,
        bottom: rem(3),
        zIndex: 0,
      },
      '&:nth-child(2)': {
        position: 'relative',
        zIndex: 1,
        '&.offline': {
          '&:after': {
            display: 'none',
          },
        },
        '&:after': {
          content: '""',
          width: rem(12),
          height: rem(12),
          border: [rem(1), 'solid', theme.colors.white],
          borderRadius: '50%',
          background: '#6dd400',
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
      '&:nth-child(3)': {
        width: rem(32),
        height: rem(32),
        position: 'absolute',
        right: 0,
        bottom: rem(3),
        zIndex: 0,
      },
    },
    '& img': {
      width: 'inherit',
      height: 'inherit',
      borderRadius: '50%',
      display: 'block',
    },
  },
  icon1: {
    width: rem(64),
    height: rem(47),
    position: 'absolute',
    top: rem(152),
    left: '50%',
    transform: `translateX(${rem(-240)})`,
    zIndex: 1,
    [adaptive]: {
      transform: `translateX(${rem(-170)})`,
    },
  },
  icon2: {
    width: rem(81),
    height: rem(60),
    position: 'absolute',
    top: rem(81),
    left: '50%',
    transform: `translateX(${rem(250)})`,
    zIndex: 1,
    [adaptive]: {
      transform: `translateX(${rem(100)})`,
    },
  },
  icon3: {
    width: rem(48),
    height: rem(35),
    position: 'absolute',
    top: rem(306),
    left: '50%',
    transform: `translateX(${rem(202)})`,
    zIndex: 1,
    [adaptive]: {
      transform: `translateX(${rem(130)})`,
      top: 'auto',
      bottom: rem(20),
    },
  },
}

const Great = ({
  text,
  submitTitle,
  offline,
  onScrollToForm,
  classes, 
}) =>
  <div className={classes.root}>
    <img className={classes.icon1} src={require('./base-1.svg')} alt='' />  
    <img className={classes.icon2} src={require('./base-2.svg')} alt='' />  
    <img className={classes.icon3} src={require('./base-3.svg')} alt='' />  
    <div className={classes.content}>
      <div className={classes.title}>Let's make tutoring great again!</div>
      <div className={classes.random}>
        <div>
          <img src={require('./user-1@3x.png')} alt='' />  
        </div>
        <div className={classnames({ offline })}>
          <img src={require('./user-2@3x.png')} alt='' />  
        </div>
        <div>
          <img src={require('./user-3@3x.png')} alt='' />  
        </div>
      </div>
      <div className={classes.text} dangerouslySetInnerHTML={{__html: text}} />
      <Button 
        type='primary'
        size='large'
        block  
        onClick={onScrollToForm}
        className={classes.button}
      >
        {submitTitle}
      </Button>
    </div>
  </div>

export default injectSheet(styles)(Great)