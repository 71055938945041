import React from 'react'
import injectSheet from 'react-jss'
import theme, { rem } from '../../theme/base'

const styles = {
  root: {
    fontSize: rem(17),
    lineHeight: rem(24),
    fontFamily: theme.fontFamilies.SFProRoundedSemibold,
  },
}

const Title = ({
  classes,
  children,
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Title)