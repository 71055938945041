import React, { Fragment, useRef } from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'
import ScrollbarWrap from './../../ScrollbarWrap'
import theme, { rem } from './../../../theme/base'
import { useOutsideClick } from '@verdaccio/mt-uikit'

const styles = {
  list: {
    width: '100%',
    height: rem(300),
    fontSize: rem(17),
    lineHeight: rem(22),
    color: theme.colors.blue,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: 0
  },
  item: {
    height: rem(40),
    padding: `${rem(12)} ${rem(16)}`,
    cursor: 'pointer',
    color: theme.colors.charcoalGrey,
    fontFamily: theme.fontFamilies.SFProRoundedRegular,
    fontSize: rem(17),
    lineHeight: rem(22),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    '&:hover': {
      // TODO: add '#D6E3FF' to theme.colors
      background: '#D6E3FF',
    },
    '&.active': {
      background: theme.colors.blue,
      color: theme.colors.white,
    }
  }
}

const scrollbarWrapProps = {
  className: 'scrollbar',
  style: {
    background: theme.colors.white,
    width: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    top: rem(48),
    borderRadius: rem(8),
    boxShadow: `
      0 ${rem(8)} ${rem(40)} 0 rgba(0, 53, 136, 0.15),
      0 ${rem(8)} ${rem(12)} 0 rgba(0, 38, 96, 0.06)
    `,
    zIndex: 4,
  }
}

const DesktopDropdown = ({
  onChange,
  onBlur,
  items,
  defaultvalue,
  classes
}) => {
  const outsideClickRef = useRef(null)
  useOutsideClick(outsideClickRef, onBlur)
  return <Fragment>
    <ScrollbarWrap {...scrollbarWrapProps}>
      <ul
        ref={outsideClickRef}
        className={classnames(classes.list)}>
        {items.map(({ key, value, text }) =>
          <li
            key={key}
            className={classnames(classes.item, { 'active': defaultvalue === value })}
            onClick={() => onChange(value)}
          >{text}</li>
        )}
      </ul>
    </ScrollbarWrap>
  </Fragment>
}

export default injectSheet(styles)(DesktopDropdown)
